import { throwError, of, combineLatest, concat, ReplaySubject } from 'rxjs';
import { catchError, switchMap, shareReplay, map, first, tap, distinctUntilChanged, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DashboardHttpService } from '../../../core-services/dashboard-http-service/dashboard-http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { DashboardApiService } from './dashboard-api.service';
import { DashboardFixtures } from '../../../dashboard/dashboard-fixtures/dashboard-fixtures';
import { DashboardConfig } from '../../widgets';
import { SingleAccountTile } from '../../../header/header';
import { Dashboard } from '../../../snapshots/snapshot/snapshot';
import {AvailableCurrency,
  ChangePasswordRequest,
  CurrentUserAccount,
  UserSetting,
  UpdateUserRequest,
  MerchantAdActiveAffiliate,
  ZendeskTicketData
} from '../../dtos/api';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsApiService {

  constructor(
    private dashboardHttpService: DashboardHttpService,
    private dashboardApiService: DashboardApiService
  ) {}

  private readonly updateDefaultCurrency$ = new ReplaySubject<UserSetting<string>[]>(1);

  private readonly defaultCurrencySavedSetting$ = concat(
    this.dashboardApiService.currentAccount$.pipe(
      first(),
      switchMap(currentAccount => this.getDefaultCurrencySetting(currentAccount.user_id))
    ),
    this.updateDefaultCurrency$
  ).pipe(shareReplay(1));

  readonly defaultCurrency$ = combineLatest([this.defaultCurrencySavedSetting$, this.dashboardApiService.currentAccount$]).pipe(
    map(([defaultCurrencySavedSetting, currentAccount]) => this.defaultCurrency(defaultCurrencySavedSetting, currentAccount)),
    distinctUntilChanged(),
    catchError((errorResponse: HttpErrorResponse) => throwError(() => errorResponse.error.error)),
    shareReplay(1)
  );

  public putUser(user_id: string, usersUpdateInfo: UpdateUserRequest) {
    return this.dashboardHttpService.put<UpdateUserRequest, {message: string}>(environment.apiUrl + '/users/' + user_id, usersUpdateInfo)
      .pipe(tap(x => this.dashboardApiService.refreshCurrentUserInfo$.next(undefined)));
  }

  public postChangePassword(user_id: string, passwords: ChangePasswordRequest) {
    return this.dashboardHttpService.post<ChangePasswordRequest, {message: string}>(environment.apiUrl + '/users/' + user_id + '/change-password', passwords);
  }

  public deleteUserDevices(user_id: string) {
    return this.dashboardHttpService.delete<{message: string}>(environment.apiUrl + '/users/' + user_id + '/devices');
  }

  public putNetworkAnnouncementSetting(user_id: string, settings: {key_value: boolean}) {
    let settingsRequest = {key_value: settings.key_value};

    return this.dashboardHttpService.put<{key_value: boolean}, UserSetting<boolean>>(
      environment.apiUrl + '/settings/' + user_id + '/users/NetworkAnnouncement', settingsRequest);
  }

  public readonly getNetworkAnnouncementSetting$ = this.dashboardApiService.currentUserId$.pipe(
    switchMap(userId => this.getNetworkAnnouncementSetting(userId)), share());

  private getNetworkAnnouncementSetting(user_id: string) {
    return this.dashboardHttpService.get<UserSetting<boolean>[]>(environment.apiUrl + '/settings/' + user_id + '/users/NetworkAnnouncement').pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 404) {
          return of(<UserSetting<boolean>[]> null);
        } else {
          return throwError(() => response);
        }
    }));
  }

  public putDefaultCurrencySetting(user_id: string, settings: {key_value: string}) {
    let keyValue = settings.key_value != null ? settings.key_value : '';
    let settingsRequest = {key_value: keyValue};

    return this.dashboardHttpService.put<{key_value: string}, UserSetting<string>>(environment.apiUrl + '/settings/' + user_id + '/users/DefaultCurrency',
      settingsRequest).pipe(
        tap(x => this.updateDefaultCurrency$.next([x])));
  }

  public getDefaultCurrencySetting(user_id: string) {
    return this.dashboardHttpService.get<UserSetting<string>[]>(environment.apiUrl + '/settings/' + user_id + '/users/DefaultCurrency').pipe(
      map(settingResponse => settingResponse
        .map(singleResponse => ({...singleResponse, key_value: singleResponse.key_value !== '' ? singleResponse.key_value : null}))),
      catchError((response: HttpErrorResponse) => {
        if (response.status === 404) {
          return of(<UserSetting<string>[]> null);
        } else {
          return throwError(() => response);
        }
      }));
  }

  public getDefaultCurrency(currentAccount: CurrentUserAccount) {
    return this.getDefaultCurrencySetting(currentAccount.user_id).pipe(
      map(x => this.defaultCurrency(x, currentAccount)),
      catchError((errorResponse: HttpErrorResponse) => throwError(() => errorResponse.error.error)));
  }

  public defaultCurrency(defaultCurrencySavedSetting: UserSetting<string>[], currentAccount: CurrentUserAccount): AvailableCurrency {
    let availableCurrencies = DashboardFixtures.getAvailableCurrencies();

    return defaultCurrencySavedSetting && defaultCurrencySavedSetting[0].key_value && availableCurrencies[defaultCurrencySavedSetting[0].key_value].name ||
      currentAccount.currency.currency_name;
  }

  public putAdToolFavoritesSetting(user_id: string, settings: string[]) {
    let settingsRequest = {key_value: settings};

    return this.dashboardHttpService.put<{key_value: string[]}, UserSetting<string[]>>(environment.apiUrl + '/settings/' + user_id + '/users/AdToolFavorites',
      settingsRequest);
  }


  public getAdToolFavoritesSetting$ = this.dashboardApiService.currentUserId$.pipe(
    switchMap(userId => {
      let url = `${environment.apiUrl}/settings/${userId}/users/AdToolFavorites`;
      return this.dashboardHttpService.get<UserSetting<string[]>[]>(url).pipe(
        map(x => x[0]?.key_value ?? <string[]> []),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 404) {
            return of<string[]>([]);
          }
          return throwError(() => errorResponse);
        })
      );
    })
  );

  public putAccountFavoritesSetting(user_id: string, settings: SingleAccountTile[]) {
    let settingsRequest = {key_value: settings};

    return this.dashboardHttpService.put<{key_value: SingleAccountTile[]}, UserSetting<any[]>>(
      environment.apiUrl + '/settings/' + user_id + '/users/AccountFavorites', settingsRequest);
  }

  public readonly getAccountFavoritesSetting$ = this.dashboardApiService.currentUserId$.pipe(
    switchMap(userId => this.getAccountFavoritesSetting(userId)), share());

  private getAccountFavoritesSetting(user_id: string) {
    return this.dashboardHttpService.get<UserSetting<any[]>[]>(environment.apiUrl + '/settings/' + user_id + '/users/AccountFavorites').pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 404) {
          return of(<UserSetting<any[]>[]> null);
        } else {
          return throwError(() => response);
        }
      }));
  }

  public putAdToolViewIconSetting(userId: string, settingValue: string) {
    let settingsRequest = {key_value: settingValue};

    return this.dashboardHttpService.put<{key_value: string}, UserSetting<string>>(`${environment.apiUrl}/settings/${userId}/users/AdToolsViewOption`,
      settingsRequest);
  }

  public getAdToolViewIconSetting(userId: string) {
    return this.dashboardHttpService.get<UserSetting<string>[]>(`${environment.apiUrl}/settings/${userId}/users/AdToolsViewOption`).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.status === 404) {
          return of(<UserSetting<string>[]> null);
        } else {
          return throwError(() => response);
        }
      }));
  }

  public getAvantMetricsPermissionSetting(entity_ids: string[]) {
    return this.dashboardHttpService.get<UserSetting<boolean>[]>
      (environment.apiUrl + '/settings/' + entity_ids.join() + '/merchants/AvantmetricsPermission').pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 404) {
            return of(<UserSetting<boolean>[]> null);
          } else {
            return throwError(() => response);
          }
        }));
  }

  public getMerchantAutoPay(merchantEntityId: string) {
    return this.dashboardHttpService.get<UserSetting<boolean>[]>(`${environment.apiUrl}/settings/${merchantEntityId}/merchants/AutoPay`).pipe(
        map(x => x[0].key_value),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 404) {
            return of(false);
          }
          return throwError(() => errorResponse);
        })
      );
  }

  public getInquiryCount$ = this.dashboardApiService.currentAccount$.pipe(
    switchMap(currentAccount => {
      if (currentAccount.entity_group_name !== 'merchants') {
        return of(0);
      }

      return this.dashboardHttpService.get<UserSetting<number>[]>(`${environment.apiUrl}/settings/${currentAccount.entity_id}/merchants/InquiryCount`).pipe(
        map(x => x[0]?.key_value),
        catchError((errorResponse: HttpErrorResponse) => {
          if (errorResponse.status === 404) {
            return of(0);
          }
          return throwError(() => errorResponse);
        })
      );
    })
  );

  public getUserConfig<T>(userId: string, keyName: string) {
    return this.dashboardHttpService.get<UserSetting<T>[]>(`${environment.apiUrl}/settings/${userId}/users/${keyName}`).pipe(
      map(x => x[0]?.key_value ?? <T> null),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 404) {
          return of(<T> null);
        }
        return throwError(() => errorResponse);
      })
    );
  }

  public putUserConfig<T>(userId: string, keyName: string, keyValue: T) {
    let settingsRequest = { key_value: keyValue };

    return this.dashboardHttpService.put<{key_value: T}, UserSetting<T>>(`${environment.apiUrl}/settings/${userId}/users/${keyName}`, settingsRequest);
  }

  public getUserDashboardConfig(dashboardType: Dashboard, configKeyName: string) {
    return this.dashboardHttpService.get<DashboardConfig>(`${environment.apiUrl}/settings/${dashboardType}/${configKeyName}/dashboard`);
  }

  public putUserDashboardConfig(dashboardType: Dashboard, configKeyName: string, dashboardConfig: DashboardConfig) {
    let settingsRequest = { key_value: dashboardConfig };
    return this.dashboardHttpService.put<{key_value: DashboardConfig}, DashboardConfig>
      (`${environment.apiUrl}/settings/${dashboardType}/${configKeyName}/dashboard`, settingsRequest);
  }

  public resetUserDashboardConfig(dashboardType: Dashboard, configKeyName: string) {
    let settingsRequest = {};
    return this.dashboardHttpService.put<Record<string, never>, DashboardConfig>(
      `${environment.apiUrl}/settings/${dashboardType}/${configKeyName}/dashboard/reset`, settingsRequest);
  }

  public getMerchantAdActiveAffiliates(merchantAdId: string) {
    return this.dashboardHttpService.getWithOptions<UserSetting<MerchantAdActiveAffiliate[]>>(
      `${environment.apiUrl}/settings/${merchantAdId}/merchant_ads/ActiveAffiliatesWebsites`).pipe(
      map(x => <MerchantAdActiveAffiliate[]> JSON.parse(x[0]?.key_value ?? <MerchantAdActiveAffiliate[]> [])),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 404) {
          return of(<MerchantAdActiveAffiliate[]> []);
        }
        return throwError(errorResponse);
      })
    );
  }

  public putZendeskTicketData(merchantEntityId: string, keyValue: ZendeskTicketData) {
    let settingValue = { key_value: keyValue };

    return this.dashboardHttpService.put(`${environment.apiUrl}/settings/${merchantEntityId}/merchants/ZendeskTicketData`, settingValue);
  }

  public getZendeskTicketData(merchantEntityId: string) {
    return this.dashboardHttpService.get<UserSetting<ZendeskTicketData>>(`${environment.apiUrl}/settings/${merchantEntityId}/merchants/ZendeskTicketData`).pipe(
      map(x => <UserSetting<ZendeskTicketData>> x[0] ?? <UserSetting<ZendeskTicketData>>{}),
      catchError((errorResponse: HttpErrorResponse) => {
        if (errorResponse.status === 404) {
          return of (<UserSetting<ZendeskTicketData>>{});
        }
        return throwError(errorResponse);
      })
    );
  }
}
