<div class="title-group">
  <div matDialogTitle>
    {{title}}
  </div>
</div>
<div class="horizontal-rule">
</div>
<div mat-dialog-content style="padding-top:0;">
  <div class="body-text mat-body-2">
    {{content}}
  </div>
  <div *ngIf="checkbox_text" class="checkbox-text">
    <mat-checkbox (change)="changeCheckbox()">{{checkbox_text}}</mat-checkbox>
  </div>
  <div class="button-group">
    <button (click)="dialog.close(false)" [color]="negative_color" class="small negative" type="button" mat-raised-button>
      {{negative_action_text}}
    </button>
    <button cdkFocusInitial (click)="dialog.close(true)" [color]="positive_color" class="small positive" type="button" mat-raised-button>
      {{positive_action_text}}
    </button>
  </div>
</div>
