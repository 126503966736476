import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';
import { environment as env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterIconService {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {

    this.matIconRegistry.addSvgIcon('sort-icon', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/sort-icon.svg'));
    this.matIconRegistry.addSvgIcon('sort-down', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/sort-down.svg'));
    this.matIconRegistry.addSvgIcon('sort-up', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/sort-up.svg'));
    // These icons are listed in materials default icons, but not available as an angular material default icon.
    this.matIconRegistry.addSvgIcon('arrow-right', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/arrow-right.svg'));
    this.matIconRegistry.addSvgIcon('ballot', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/ballot-24px.svg'));

    // networks
    this.matIconRegistry.addSvgIcon('AU', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/icons/network-flag/au.svg'));
    this.matIconRegistry.addSvgIcon('CA', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/icons/network-flag/ca.svg'));
    this.matIconRegistry.addSvgIcon('US', this.domSanitizer.bypassSecurityTrustResourceUrl(env.dashboardUrl + '/assets/images/icons/network-flag/us.svg'));

    // entity groups
    this.matIconRegistry.addSvgIcon('affiliates', this.domSanitizer.bypassSecurityTrustResourceUrl(
      env.dashboardUrl + '/assets/images/icons/entity-group/affiliate.svg'));
    this.matIconRegistry.addSvgIcon('merchant_groups', this.domSanitizer.bypassSecurityTrustResourceUrl(
      env.dashboardUrl + '/assets/images/icons/entity-group/merchant-group.svg'));
    this.matIconRegistry.addSvgIcon('merchants', this.domSanitizer.bypassSecurityTrustResourceUrl(
      env.dashboardUrl + '/assets/images/icons/entity-group/merchant.svg'));
    this.matIconRegistry.addSvgIcon('root', this.domSanitizer.bypassSecurityTrustResourceUrl(
      env.dashboardUrl + '/assets/images/icons/entity-group/root.svg'));
    this.matIconRegistry.addSvgIcon('any', this.domSanitizer.bypassSecurityTrustResourceUrl(
      env.dashboardUrl + '/assets/images/avantlink-icon-fullcolor-350x350.svg'));

  }
}
