import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthyApiService } from '../../api-services/authy-api/authy-api.module';
import { LoginStateService } from '../login-state-service/login-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatButton } from '@angular/material/button';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-register-device',
  templateUrl: './register-device.component.html',
  styleUrls: ['./register-device.component.scss']
})
export class RegisterDeviceComponent implements AfterViewInit {
  private registerDeviceSubscription: Subscription;
  @ViewChild('submitButton', { static: true }) private submitButton: MatButton;

  constructor(
    private authyService: AuthyApiService,
    private router: Router,
    public loginStateService: LoginStateService,
    private snackBar: SnackBarService,
  ) { }

  ngAfterViewInit() {
    this.submitButton.focus();
  }

  public sendCode() {
    if (!this.registerDeviceSubscription) {
      this.registerDeviceSubscription = this.authyService.registerDevice({
        send_method: this.loginStateService.sendMethod
      }).subscribe({
      next: () => {
        this.router.navigate(['/login/verify-device'], {queryParamsHandling: 'preserve'});
      },
      error: (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error)
    });
      this.registerDeviceSubscription?.add(() => this.registerDeviceSubscription = null);
    }
  }
}
