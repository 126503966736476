
export class JSONUtility {

  public static decodeJSONArray<T>(input: Array<string>): Array<T> {
    return input.map((x) => JSON.parse(x));
  }

  public static encodeJSONArray<T>(input: Array<T>): Array<string> {
    return input.map((x) => JSON.stringify(x));
  }

}
