import { NgModule } from '@angular/core';
import { HasNumberValidatorDirective } from './has-number-validator.directive';
import { EqualValidatorDirective } from './equal-validator.directive';
import { EmailValidatorDirective } from './email-validator.directive';
import { PhoneNumberValidatorDirective } from './phone-number-validator.directive';
import { HasUppercaseValidatorDirective } from './has-uppercase-validator.directive';
import { HasSymbolValidatorDirective } from './has-symbol-validator.directive';


@NgModule({
  imports: [
  ],
  declarations: [
    HasNumberValidatorDirective,
    EqualValidatorDirective,
    PhoneNumberValidatorDirective,
    EmailValidatorDirective,
    HasUppercaseValidatorDirective,
    HasSymbolValidatorDirective
  ],
  providers: [
  ],
  exports: [
    HasNumberValidatorDirective,
    EqualValidatorDirective,
    PhoneNumberValidatorDirective,
    EmailValidatorDirective,
    HasUppercaseValidatorDirective,
    HasSymbolValidatorDirective

  ]
})

export class ValidatorsModule {
}
