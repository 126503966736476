import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestTrackerService  {
  affiliateSnapshotRequestTracker$ = new BehaviorSubject<number>(0);
  merchantSnapshotRequestTracker$ = new BehaviorSubject<number>(0);
  merchantRelationshipRequestTracker$ = new BehaviorSubject<number>(0);
}
