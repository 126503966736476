import { Injectable } from '@angular/core';

import { CountryCode, CountryCodeService } from '../../api-services/authy-api/authy-api.module';
import {SendMethod} from '../../core/dtos/api';

@Injectable()
export class LoginStateService {
  public emailAddress: string;
  public sendMethod: SendMethod;
  public phoneNumber: string;
  public countryCode: CountryCode;
  public initialPasswordSetup: boolean;

  constructor(countryCodeService: CountryCodeService) {
    this.sendMethod = 'sms';
    this.countryCode = countryCodeService.countryCodes[0];
    this.initialPasswordSetup = false;
  }
}
