import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';

import { ApiService } from '../../api/api.module';

@Injectable()
export class DeviceService {
  private userDeviceIdBacking: string;

  constructor(
    private cookieService: CookieService,
    private apiService: ApiService
    ) {
    this.userDeviceIdBacking = cookieService.get(this.apiService.deviceHeaderName);
  }

  set userDeviceId(val: string) {
    this.userDeviceIdBacking = val;
    this.setDeviceCookie(val);
  }

  get userDeviceId() {
    return this.userDeviceIdBacking;
  }

  private setDeviceCookie(userDeviceId: string) {
    this.cookieService.put(this.apiService.deviceHeaderName, userDeviceId);
  }
}
