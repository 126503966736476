import { NgModule } from '@angular/core';

import { UserPermissionsApiService } from './user-permissions-api.service';

@NgModule({
  providers: [
    UserPermissionsApiService
  ]
})
export class UserPermissionsApiModule { }
