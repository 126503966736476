import { NgModule } from '@angular/core';

import { AccountType } from './account-type-pipe/account-type.pipe';
import { TitleCase } from './title-case-pipe/title-case.pipe';
import { DisplayEntityType } from './display-entity-type-pipe/display-entity-type.pipe';
import { GetMinimumNumber } from './get-minimum-number/get-minimum-number.pipe';
import { CapNumberPipe } from './cap-number-pipe/cap-number.pipe';
import {FormatDatePipe} from './format-date-pipe/format-date.pipe';

@NgModule({
  exports: [
    AccountType,
    TitleCase,
    DisplayEntityType,
    GetMinimumNumber,
    FormatDatePipe,
    CapNumberPipe,
  ],
  declarations: [
    AccountType,
    TitleCase,
    DisplayEntityType,
    GetMinimumNumber,
    FormatDatePipe,
    CapNumberPipe,
  ]
})
export class PipeModule { }
export { AccountType } from './account-type-pipe/account-type.pipe';
export { TitleCase } from './title-case-pipe/title-case.pipe';
export { DisplayEntityType } from './display-entity-type-pipe/display-entity-type.pipe';
export { GetMinimumNumber } from './get-minimum-number/get-minimum-number.pipe';
