<div class="unsubscribe">
    <a href="https://www.avantlink.com/">
        <img [src]="'/assets/images/avantlink-fullcolor.svg' | absUrl">
    </a>
    <div class="message">
        <div *ngIf="unsubscribeSuccessful !== undefined; else spinner">
            <div *ngIf="unsubscribeSuccessful; else failureBlock">
                Success! You have unsubscribed.
            </div>
            <ng-template #failureBlock>
                Whoops. Something went wrong. Please <a href="http://support.avantlink.com/hc/en-us">contact support</a>
                to unsubscribe.
            </ng-template>
        </div>
        <ng-template #spinner>
            <mat-spinner color="accent" class="spinner"></mat-spinner>
        </ng-template>
    </div>
    <div class="btn-container">
        <button color="primary" mat-raised-button (click)="routeToLogin()">
            Login
        </button>
    </div>
</div>
