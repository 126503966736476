import { Injectable } from '@angular/core';
import { SingleAccountTile } from '../header';

@Injectable()
export class HeaderStateService {
  public rootNetworkAccounts: SingleAccountTile[];
  public rootActiveAccount: SingleAccountTile;
  public nonRootAvailableAccounts: SingleAccountTile[];
  public displayBetaImage = false;
}
