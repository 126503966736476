import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appValidatePhoneNumber][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhoneNumberValidatorDirective),
      multi: true
    }
  ]
})

export class PhoneNumberValidatorDirective implements Validator {
  // eslint-disable-next-line max-len
  private phoneNumberRegularExpression = new RegExp('^([-\\s\\.])?((\\(\\d{1,4}\\))|\\d{1,4})(([-\\s\\.])?[0-9]{1,12}){1,2}$');

  constructor( @Attribute('appValidatePhoneNumber') public validatePhoneNumber: string) {
  }

  validate(controlElement: AbstractControl): { [key: string]: any } {
    return !this.phoneNumberRegularExpression.test(controlElement.value) ? { validatePhoneNumber: false } : null;
  }
}
