import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appHasNumber][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HasNumberValidatorDirective),
      multi: true
    }
  ]
})

export class HasNumberValidatorDirective implements Validator {

  private regularExpression = new RegExp('[0-9]');

  constructor( @Attribute('appHasNumber') public hasNumber: string) {
  }

  validate(controlElement: AbstractControl): { [key: string]: any } {
    if (!controlElement.value || controlElement.value === '') {
      return null;
    }

    return !this.regularExpression.test(controlElement.value) ? { hasNumber: true } : null;
  }
}
