import { NgModule } from '@angular/core';

import { DashboardService } from './dashboard-service/dashboard.service';
import { DashboardStateService } from './dashboard-state-service/dashboard-state.service';
import { FileDownloadService } from './file-download-service/file-download.service';
import { DashboardAllRoutesService } from './dashboard-all-routes-service/dashboard-all-routes.service';
import { getWindow, windowToken } from './dashboard-helpers/dashboard-helper';
import { DashboardPermissionsService } from './dashboard-permissions-service/dashboard-permissions.service';
import { UserPermissionsApiModule } from '../api-services/user-permissions-api/user-permissions-api.module';

@NgModule({
  imports: [
    UserPermissionsApiModule
  ],
  providers: [
    DashboardService,
    DashboardStateService,
    FileDownloadService,
    DashboardAllRoutesService,
    DashboardPermissionsService,
    {
      provide: windowToken, useFactory: getWindow
    }
  ]
})
export class DashboardModule {}
export { DashboardService } from './dashboard-service/dashboard.service';
export { DashboardStateService } from './dashboard-state-service/dashboard-state.service';
export { FileDownloadService } from './file-download-service/file-download.service';
export { DashboardAllRoutesService } from './dashboard-all-routes-service/dashboard-all-routes.service';
export { DashboardPermissionsService } from './dashboard-permissions-service/dashboard-permissions.service';
