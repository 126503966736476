import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import {AvailableCurrency, CurrentUserAccount, EntityGroupName, NetworkSearch} from '../../core/dtos/api';

@Injectable()
export class DashboardStateService {
  public entityGroupName: EntityGroupName;
  public entityId: string;
  public entityName: string;
  public entityNetwork: NetworkSearch;
  public classicId: number;
  public userIsAdmin: boolean;
  public userId: string;
  public userInfo: {
    userId: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
  };

  public userCurrencies: AvailableCurrency[];
  public userDefaultCurrency: AvailableCurrency;
  public accountSwitchStream: Subject<CurrentUserAccount> = new Subject<CurrentUserAccount>();
  public readonly rootPermissions = '00000000-0000-4000-a000-000000000000';
  public readonly disruptoBotUserId = 'e9ea360b-ba55-4489-8480-f24a25c8b91e';

  constructor() {
    this.userCurrencies = [];
  }
}
