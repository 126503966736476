import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthyApiService, CountryCodeService } from '../../api-services/authy-api/authy-api.module';
import { LoginStateService } from '../login-state-service/login-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';

@Component({
  selector: 'app-setup-phone',
  templateUrl: './setup-phone.component.html',
  styleUrls: ['./setup-phone.component.scss']
})
export class SetupPhoneComponent implements OnInit {
  private setupPhoneSubscription: Subscription;

  constructor(
    private authyService: AuthyApiService,
    private router: Router,
    public loginStateService: LoginStateService,
    public countryCodeService: CountryCodeService,
    private snackBar: SnackBarService,
  ) { }

  public ngOnInit() {
    this.loginStateService.countryCode = this.countryCodeService.countryCodes[0];
  }

  public setupPhone() {
    if (!this.setupPhoneSubscription) {
      this.setupPhoneSubscription = this.authyService.setup({
        phone_number: this.loginStateService.phoneNumber,
        country_code: this.loginStateService.countryCode.value,
        send_method: this.loginStateService.sendMethod
      }).subscribe(
        () => {
          let phoneNumber = this.loginStateService.phoneNumber;
          this.snackBar.openSnackBar('A verification code was sent to: ' + phoneNumber);
          this.router.navigate(['/login/verify-phone'], {queryParamsHandling: 'preserve'});
        },
        (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error)
      );
      this.setupPhoneSubscription?.add(() => this.setupPhoneSubscription = null);
    }
  }
}
