import { NgModule } from '@angular/core';
import { UnsubscribeReportComponent } from './unsubscribe-report/unsubscribe-report.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    UnsubscribeReportComponent
  ],
  declarations: [
    UnsubscribeReportComponent
  ]
})
export class UnsubscribeReportModule {}

export { UnsubscribeReportComponent } from './unsubscribe-report/unsubscribe-report.component';
