import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appHasSymbol][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HasSymbolValidatorDirective),
      multi: true
    }
  ]
})
export class HasSymbolValidatorDirective implements Validator {

  private regularExpression = new RegExp('[_+-.,!@#$%^&*();\\/|<>"\']');

  constructor( @Attribute('appHasSymbol') public hasSymbol: string) {
  }

  validate(controlElement: AbstractControl): { [key: string]: any } {
    if (!controlElement.value || controlElement.value === '') {
      return null;
    }

    return !this.regularExpression.test(controlElement.value) ? { hasSymbol: true } : null;
  }
}
