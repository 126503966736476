import { Injectable } from '@angular/core';

import { Greeting } from './greeting';
import {isAfter, isBefore} from 'date-fns';

@Injectable()
export class GreetingService {
  private greetings: Greeting[];

  constructor() {
    this.greetings = [];
    this.greetings.push(new Greeting(0, 4, 'Up late?'));
    this.greetings.push(new Greeting(5, 11, 'Good morning!'));
    this.greetings.push(new Greeting(12, 16, 'Good afternoon!'));
    this.greetings.push(new Greeting(17, 22, 'Good evening!'));
    this.greetings.push(new Greeting(23, 23, 'Up late?'));
  }

  public getGreeting(localTime: Date = new Date()): string {
    let activeGreeting = this.greetings.find((greeting) => (!isBefore(localTime, greeting.start)) && !isAfter(localTime, greeting.end));
    return activeGreeting.message;
  }
}
