import { Injectable, Optional, Inject } from '@angular/core';


import { DashboardStateService } from '../../dashboard/dashboard-state-service/dashboard-state.service';
import { DashboardAllRoutesService } from '../../dashboard/dashboard-all-routes-service/dashboard-all-routes.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { windowToken } from '../../dashboard/dashboard-helpers/dashboard-helper';
import { DashboardApiService } from './api/dashboard-api.service';
import { first, map, shareReplay } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { EntityGroupName } from '../dtos/api';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  // the window is being injected so we can more easily mock it for tests.
  private window: Window;
  public homepage: string;

  constructor(
    private dashboardStateService: DashboardStateService,
    private dashboardAllRoutesService: DashboardAllRoutesService,
    private dashboardApiService: DashboardApiService,
    @Optional() private router: Router,
    @Inject(windowToken) window: Window
  ) {
    this.window = window;
    this.homepage = environment.classicUrl + '/admin/';
  }

  classicRoute$ = this.dashboardApiService.currentAccount$.pipe(
    map(x => this.classicRoute(x.entity_group_name)),
    shareReplay({bufferSize: 1, refCount: true}));

  public directToRoute(routeKey: string): void {
    if (this.dashboardAllRoutesService.ContainsKey(routeKey)) {
      let theRouteValue = this.dashboardAllRoutesService.Value(routeKey);
      if (theRouteValue.isRouteDashboard) {
        if (environment.isClassic) {
          this.routeToUrl(environment.dashboardUrl + theRouteValue.routeUrl);
        } else {
          this.router.navigate([theRouteValue.routeUrl]);
        }
      } else {
        this.routeToUrl(this.classicRoute(this.dashboardStateService.entityGroupName) + theRouteValue.routeUrl);
      }
    } else {
      throw new Error ('Key ' + routeKey + ' does not exist in DashboardAllRoutesService Dictionary');
    }
  }

  public routeToUrl(url: string, isNewWindow = false): void {
    if (isNewWindow) {
      this.window.open(url);
    } else {
      this.window.location.href = url;
    }
  }

  public classicRoute(entityGroupName: EntityGroupName) {
    switch (entityGroupName) {
      case 'root':
        return environment.classicUrl + '/admin';
      case 'merchants':
      case 'merchant_groups':
        return environment.classicUrl + '/merchant_all';
      case 'affiliates':
        return environment.classicUrl + '/affiliate';
      default:
        throw new Error (`${this.dashboardStateService.entityGroupName} isn't a valid entityGroupName`);
    }
  }

  public goToClassic(): void {
    this.window.location.href = this.homepage;
  }

  /**
   * This directs users to their home dashboard depending on whether they are on classic and their current user and account.
   */
  public directToDashboard(): void {
    combineLatest([
      this.dashboardApiService.currentUserInfo$.pipe(first()),
      this.dashboardApiService.availableAccounts$,
      this.dashboardApiService.currentAccount$]).pipe(
      first()
    ).subscribe(([user, userAccounts, currentAccount]) => {
      let adminUserPermission = user.user_permissions.find((userPermission) => userPermission.entity_group_name === 'root'
          && userPermission.entity_id === '00000000-0000-4000-a000-000000000000'
          && userPermission.deleted_at === null);
      if (environment.isClassic) {
        if (adminUserPermission) {
          switch (currentAccount.entity_group_name) {
            case 'root': {
              window.location.href = `${environment.dashboardUrl}/dashboard/admin`;
              break;
            }
            case 'affiliates': {
              window.location.href = `${environment.dashboardUrl}/dashboard/affiliate`;
              break;
            }
            case 'merchant_groups': {
              window.location.href = `${environment.dashboardUrl}/dashboard/agency`;
              break;
            }
            case 'merchants': {
              window.location.href = `${environment.dashboardUrl}/dashboard/merchant`;
              break;
            }
            default: {
              throw Error('No such entity group exists.');
            }
          }
        } else {
          if (currentAccount.entity_group_name === 'affiliates') {
            window.location.href = `${environment.dashboardUrl}/dashboard/affiliate`;
          } else {
            let filteredMerchants = userAccounts.filter(singleAccount => singleAccount.entity_group_name === 'merchants');
            let filteredMerchantGroups = userAccounts.filter(singleAccount => singleAccount.entity_group_name === 'merchant_groups');
            if (filteredMerchantGroups.length === 0 && filteredMerchants.length === 1) {
              window.location.href = `${environment.dashboardUrl}/dashboard/merchant`;
            } else {
              window.location.href = `${environment.dashboardUrl}/dashboard/agency`;
            }
          }
        }
      } else {
        if (adminUserPermission) {
          switch (currentAccount.entity_group_name) {
            case 'root': {
              this.router.navigate(['dashboard/admin']);
              break;
            }
            case 'affiliates': {
              this.router.navigate(['dashboard/affiliate']);
              break;
            }
            case 'merchant_groups': {
              this.router.navigate(['dashboard/agency']);
              break;
            }
            case 'merchants': {
              this.router.navigate(['dashboard/merchant']);
              break;
            }
            default: {
              throw Error('No such entity group exists.');
            }
          }
        } else {
          if (currentAccount.entity_group_name === 'affiliates') {
            this.router.navigate(['dashboard/affiliate']);
          } else {
            let filteredMerchants = userAccounts.filter(singleAccount => singleAccount.entity_group_name === 'merchants');
            let filteredMerchantGroups = userAccounts.filter(singleAccount => singleAccount.entity_group_name === 'merchant_groups');
            if (filteredMerchantGroups.length === 0 && filteredMerchants.length === 1) {
              this.router.navigate(['dashboard/merchant']);
            } else {
              this.router.navigate(['dashboard/agency']);
            }
          }
        }
      }
    });
  }

  public redirectOnLogin(redirectUrl: string, isRedirectClassicUrl: boolean) {
    if (isRedirectClassicUrl) {
      window.location.href = `${environment.classicUrl}${redirectUrl}`;
    } else {
      this.router.navigate([redirectUrl]);
    }
  }
}
