import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsUrlPipe } from './pipes/abs-url.pipe';
import { CreateStringFromObjectsPipe } from './pipes/create-string-from-objects.pipe';
import { DefaultWebsiteUrlPipe } from './pipes/default-website-url.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IncludesPipe } from './pipes/includes.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {MatOptionModule, provideNativeDateAdapter} from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {EditorModule} from '@tinymce/tinymce-angular';
import { NotificationAnnouncementsComponent } from './notification-announcements/notification-announcements.component';
import { QuillModule } from 'ngx-quill';
import { ReportChipsModalEdw3Component } from './report-chips-modal-edw3/report-chips-modal-edw3.component';
import { ValidationMessagePipe } from './pipes/validation-message.pipe';
import {DataTableDisplayComponent} from './data-table-display/data-table-display/data-table-display.component';
import {DataTableDisplayEdw3Component} from './data-table-display/data-table-display/data-table-display-edw3.component';
import {DataTableDisplayWrapperComponent} from './data-table-display/data-table-display-wrapper/data-table-display-wrapper.component';
import {DataTableDisplayWrapperEdw3Component} from './data-table-display/data-table-display-wrapper/data-table-display-wrapper-edw3.component';
import {DataTableCellComponent} from './data-table-display/data-table-cell/data-table-cell.component';
import {DataTableDisplayPipeModule} from './data-table-display/data-table-display-pipe/data-table-display-pipe.module';
import {PipeModule} from '../pipe/pipe.module';
import { DateIntlPipe } from './pipes/date-intl.pipe';
import { NonSupportedGroupsComponent } from './non-supported-groups/non-supported-groups.component';
import { StripUrlPipe } from './pipes/strip-url.pipe';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import {EditEmailTemplatesDialogComponent} from './edit-email-templates-dialog/edit-email-templates-dialog.component';
import {InsertImageDialogComponent} from './insert-image-dailog/insert-image-dialog.component';
import {PreviewEmailDialogComponent} from './preview-email-dialog/preview-email-dialog.component';
import {CreateEmailDialogComponent} from './email-partners/create-email-dialog/create-email-dialog.component';
import {EmailPartnersComponent} from './email-partners/email-partners.component';
import {ProgressSpinnerComponent} from './progress-spinner/progress-spinner/progress-spinner.component';
import {ReadonlyViewEmailDialogComponent} from './email-partners/readonly-view-email-dialog/readonly-view-email-dialog.component';
import {MatTreeModule} from '@angular/material/tree';

@NgModule({
  declarations: [
    AbsUrlPipe,
    CreateStringFromObjectsPipe,
    DefaultWebsiteUrlPipe,
    IncludesPipe,
    NotificationAnnouncementsComponent,
    ReportChipsModalEdw3Component,
    ValidationMessagePipe,
    DataTableDisplayComponent,
    DataTableDisplayEdw3Component,
    DataTableDisplayWrapperComponent,
    DataTableDisplayWrapperEdw3Component,
    DataTableCellComponent,
    DateIntlPipe,
    StripUrlPipe,
    NonSupportedGroupsComponent,
    MaintenanceComponent,
    EditEmailTemplatesDialogComponent,
    InsertImageDialogComponent,
    PreviewEmailDialogComponent,
    CreateEmailDialogComponent,
    ReadonlyViewEmailDialogComponent,
    EmailPartnersComponent,
    ProgressSpinnerComponent
  ],
  imports: [
    DataTableDisplayPipeModule,
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    QuillModule,
    ReactiveFormsModule,
    PipeModule,
    EditorModule,
    MatTreeModule
  ],
  providers:[
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
    provideNativeDateAdapter()
  ],
  exports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
    MatTreeModule,
    QuillModule,
    ReactiveFormsModule,

    AbsUrlPipe,
    CreateStringFromObjectsPipe,
    DefaultWebsiteUrlPipe,
    IncludesPipe,
    NotificationAnnouncementsComponent,
    ReportChipsModalEdw3Component,
    ValidationMessagePipe,
    DataTableDisplayComponent,
    DataTableDisplayEdw3Component,
    DataTableDisplayWrapperComponent,
    DataTableDisplayWrapperEdw3Component,
    DateIntlPipe,
    StripUrlPipe,
    NonSupportedGroupsComponent,
    MaintenanceComponent,
    EditEmailTemplatesDialogComponent,
    InsertImageDialogComponent,
    PreviewEmailDialogComponent,
    EmailPartnersComponent,
    ProgressSpinnerComponent
  ]
})
export class SharedModule { }
