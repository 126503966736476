import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { DashboardStateService, DashboardPermissionsService } from '../../dashboard/dashboard.module';
import { DashboardHttpService } from '../../core-services/dashboard-http-service/dashboard-http.service';
import { DashboardApiService } from '../../core/services/api/dashboard-api.service';
import { RoutingService } from '../../core/services/routing.service';
import {CurrentUserAccount, SwitchAccountRequest} from '../../core/dtos/api';

@Injectable()
export class AccountSwitcherService {

  private path = '/login';
  private readonly possiblePermissions: {[key: string]: string} = {
    report: 'entity_reports',
    'user-settings': 'entity_account_settings',
    'manage-users': 'business_settings',
    'dashboard/affiliate': '',
    'dashboard/merchant': '',
    'dashboard/agency': '',
    'access-denied': ''
  };

  constructor(
    private http: DashboardHttpService,
    private dashboardApiService: DashboardApiService,
    private dashboardStateService: DashboardStateService,
    private dashboardPermissionsService: DashboardPermissionsService,
    private dashboardRoutingService: RoutingService,
  ) {}

  public switchCurrentAccount(newAccount: SwitchAccountRequest, currentRouteKey: string): Observable<CurrentUserAccount> {
    return this.http.put<SwitchAccountRequest, CurrentUserAccount>
    (environment.apiUrl + this.path + '/switch-account', newAccount).pipe(
      tap((res) => {
        // api actually returns a string for classic_entity_id. Change to number to match other classic ids and to be compatible with existing typings.
        res.classic_entity_id = parseInt(<string> <any> res.classic_entity_id, 10) || null;

        this.dashboardApiService.switchAccount$.next(res);
        // Only check permissions for non-classic pages, as permissions are already checked on page load.
        if (!environment.isClassic) {
          // this is done here as we need the account switch response to check permissions.
          this.checkCurrentPagePermission(currentRouteKey, res)
            .subscribe((hasPermissions) => {
              if (!hasPermissions) {
                this.dashboardRoutingService.directToRoute('Access Denied');
              } else {
                // broadcast the result to all subscribers.
                this.dashboardStateService.accountSwitchStream.next(res);
              }
            });
        } else {
          // broadcast the result to all subscribers.
          this.dashboardStateService.accountSwitchStream.next(res);
        }
      })
    );
  }

  private checkCurrentPagePermission(currentRoute: string, switchAccountResponse: CurrentUserAccount): Observable<boolean> {
    // Since some of the currentRoutes include sub-routes we have to do a check to see if the key is found within the currentRoute.
    let routesKey = Object.keys(this.possiblePermissions).find((singleKey) => currentRoute.indexOf(singleKey) !== -1);
    return new Observable((observer) => {
      if (routesKey) {
        let routeValue = this.possiblePermissions[routesKey];
        this.dashboardPermissionsService.checkPermissions(routeValue, switchAccountResponse)
          .subscribe((hasPermissions) => {
            // only do not grant page access if a false is returned.
            if (hasPermissions === false) {
              observer.next(false);
            } else {
              observer.next(true);
            }
          });
      } else {
        observer.next(true);
      }
    });
  }

}
