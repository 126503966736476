import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, of, Observable, AsyncSubject, combineLatest } from 'rxjs';
import { switchMap, shareReplay, catchError, concatMap, map, startWith, filter } from 'rxjs/operators';
import { DashboardFixtures } from '../dashboard-fixtures/dashboard-fixtures';
import { DashboardStateService } from '../dashboard-state-service/dashboard-state.service';
import { UserPermissionsApiService } from '../../api-services/user-permissions-api/user-permissions-api.service';
import { DashboardApiService } from '../../core/services/api/dashboard-api.service';
import { UserSettingsApiService } from '../../core/services/api/user-settings-api.service';
import { MerchantGroupApiService } from '../../core/services/api/merchant-group-api.service';
import {AccountPermission, CurrentUserAccount, EntityGroupName} from '../../core/dtos/api';
import { RoutingService } from '../../core/services/routing.service';

@Injectable()
export class DashboardPermissionsService implements OnDestroy {
  private permissionSubject: Subject<{ [key: string]: boolean }>;
  private userInfoSubject: Subject<CurrentUserAccount>;
  private allPermissionSubject: Subject<{ [key: string]: boolean }>;
  private returnedPermissions: Subject<boolean>;
  private savedPermissions: { [key: string]: boolean };

  // get permisssions everytime account changes and return both
  readonly accountWithPermissions$ = <Observable<[CurrentUserAccount, AccountPermission[]]>> this.dashboardApi.currentAccount$.pipe(
    concatMap(currentAccount => this.getPermissions(currentAccount).pipe(
      map(permissions => <const> [currentAccount, permissions]),
      startWith('loading'))),
    shareReplay(1),
    filter(x => x !== 'loading'));

  constructor(
    private dashboardApi: DashboardApiService,
    private dashboardStateService: DashboardStateService,
    private dashboardRoutingService: RoutingService,
    private userPermissionsApi: UserPermissionsApiService,
    private userSettingsApi: UserSettingsApiService,
    private merchantGroupApi: MerchantGroupApiService,
  ) {
  }

  private getPermissions(accountSwitchResponse: CurrentUserAccount): Observable<AccountPermission[]> {
    let permissionsToCheck = DashboardFixtures.getPermissionsQueryObjects();

    for (let permission of permissionsToCheck) {
      // This check is here to determine whether we need to pass the 'entity_id' or the 'user_id'.
      if (permission.entity_id === 'entity_id') {
        permission.entity_id = accountSwitchResponse.entity_id;
      } else if (permission.entity_id === 'user_id') {
        permission.entity_id = accountSwitchResponse.user_id;
      }
      // We need to pass the current account entity_group_name in some cases.
      if (permission.entity_type === 'dynamic_entity_type') {
        permission.entity_type = accountSwitchResponse.entity_group_name;
      }
    }

    return this.userPermissionsApi.getPermissionsCheck(accountSwitchResponse.user_id, permissionsToCheck).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.handleErrorFromRequest(errorResponse);
        return of(null);
      }));
  }

  public getAllPermissions(): Observable<{ [key: string]: boolean }> | null {
    this.allPermissionSubject = new AsyncSubject<{ [key: string]: boolean }>();
    if (this.savedPermissions) {
      this.allPermissionSubject.next(this.savedPermissions);
      this.allPermissionSubject.complete();
    } else {
      this.allPermissionSubject = new Subject<{ [key: string]: boolean }>();
      this.getPermissionsFromEndPoint()
        .subscribe((response) => {
            this.savedPermissions = response;
            this.allPermissionSubject.next(response);
            this.allPermissionSubject.complete();
          },
          (errorResponse: HttpErrorResponse) => {
            this.allPermissionSubject.next(null);
            this.allPermissionSubject.complete();
            this.handleErrorFromRequest(errorResponse);
          });
    }
    return this.allPermissionSubject;
  }

  public checkPermissions(checkedUserPermission: string, accountSwitchResponse?: CurrentUserAccount): Observable<boolean> {
    // If this is an account switch we need to hit the permissions end-point, even if we already have permissions back previously.
    this.returnedPermissions = new AsyncSubject<boolean>();
    if (this.savedPermissions && !accountSwitchResponse) {
      if (checkedUserPermission === 'business_settings') {
        checkedUserPermission = this.getPermissionsForBusinessSettings(this.dashboardStateService.entityGroupName);
      }
      this.returnedPermissions.next(this.savedPermissions[checkedUserPermission]);
      this.returnedPermissions.complete();
      if (!this.savedPermissions[checkedUserPermission]) {
        this.handleNoPermission();
      }
    } else {
      this.getPermissionsFromEndPoint(accountSwitchResponse)
        .subscribe((response) => {
          if (!response) {
            this.returnedPermissions.next(false);
            this.returnedPermissions.complete();
          } else {
            if (checkedUserPermission === 'business_settings') {
              checkedUserPermission = this.getPermissionsForBusinessSettings(this.dashboardStateService.entityGroupName);
            }
            this.savedPermissions = response;
            this.returnedPermissions.next(response[checkedUserPermission]);
            this.returnedPermissions.complete();
            if (!response[checkedUserPermission] && !accountSwitchResponse) {
              this.handleNoPermission();
            }
          }
        });
    }
    return this.returnedPermissions;
  }

  public checkAvantmetricsPermission = <Observable<[boolean, CurrentUserAccount]>>
    combineLatest([this.dashboardApi.currentAccount$, this.dashboardApi.availableAccounts$]).pipe(
      switchMap(([currentAccount, availableAccounts]) => {
        if (currentAccount.entity_group_name === 'affiliates') {
          return of(<const> [false, currentAccount]);
        }

        if (currentAccount.entity_group_name === 'root') {
          return of(<const> [true, currentAccount]);
        }

        if (currentAccount.entity_group_name === 'merchants') {
          let isRoot = availableAccounts.some(x => x.entity_group_name === 'root');
          let availableMerchantIds = availableAccounts.filter(x => x.entity_group_name === 'merchants').map(x => x.entity_id);

          return this.userSettingsApi.getAvantMetricsPermissionSetting(isRoot ? [currentAccount.entity_id] : availableMerchantIds).pipe(switchMap((settings) => {
            let hasPermission = false;
            settings = settings || [];
            settings.forEach((setting) => {
              if (setting.key_value === true) {
                hasPermission = true;
              }
            });
            return of(<const> [hasPermission, currentAccount]);
          }));
        }

        if (currentAccount.entity_group_name === 'merchant_groups') {
          return this.merchantGroupApi.getMerchantGroupInfo([currentAccount.entity_id]).pipe(switchMap((merchant_groups) => {
            let entity_ids = merchant_groups[0].merchants.map(x => x.merchant_id);

            return this.userSettingsApi.getAvantMetricsPermissionSetting(entity_ids).pipe(switchMap((settings) => {
              let hasPermission = false;
              settings = settings || [];
              settings.forEach((setting) => {
                if (setting.key_value === true) {
                  hasPermission = true;
                }
              });
              return of(<const> [hasPermission, currentAccount]);
            }));
          }));
        }
        return of(<const> [false, currentAccount]);
      }));

  private getPermissionsFromEndPoint(accountSwitchResponse?: CurrentUserAccount): Observable<{ [key: string]: boolean }> {
    let permissionsToCheck = DashboardFixtures.getPermissionsQueryObjects();
    this.permissionSubject = new Subject<{ [key: string]: boolean }>();
    this.getUserInfo(accountSwitchResponse)
      .subscribe((currentUserInfo) => {
        if (!currentUserInfo) {
          this.permissionSubject.next(null);
        } else {
          for (const permission of permissionsToCheck) {
            // This check is here to determine whether we need to pass the 'entity_id' or the 'user_id'.
            if (permission.entity_id === 'entity_id') {
              permission.entity_id = currentUserInfo.entity_id;
            } else if (permission.entity_id === 'user_id') {
              permission.entity_id = currentUserInfo.user_id;
            }
            // We need to pass the current account entity_group_name in some cases.
            if (permission.entity_type === 'dynamic_entity_type') {
              permission.entity_type = currentUserInfo.entity_group_name;
            }
          }
          this.userPermissionsApi.getPermissionsCheck(currentUserInfo.user_id, permissionsToCheck)
            .subscribe(permissionsResponse => {
                this.permissionSubject.next(this.createPermissionLookUpArray(permissionsResponse));
              },
              (errorResponse: HttpErrorResponse) => {
                this.permissionSubject.next(null);
                this.handleErrorFromRequest(errorResponse);
              });
        }
      });
    return this.permissionSubject;
  }

  private getUserInfo(accountSwitchResponse?: CurrentUserAccount): Observable<CurrentUserAccount> {
    this.userInfoSubject = new AsyncSubject<CurrentUserAccount>();
    if (accountSwitchResponse) {
      this.dashboardStateService.entityGroupName = accountSwitchResponse.entity_group_name;
      this.userInfoSubject.next(accountSwitchResponse);
      this.userInfoSubject.complete();
    } else {
      this.dashboardApi.currentAccount$
        .subscribe(currentAccountResponse => {
            this.dashboardStateService.entityGroupName = currentAccountResponse.entity_group_name;
            this.userInfoSubject.next(currentAccountResponse);
            this.userInfoSubject.complete();
          },
          (errorResponse: HttpErrorResponse) => {
            this.userInfoSubject.next(null);
            this.userInfoSubject.complete();
            this.handleErrorFromRequest(errorResponse);
          });
    }
    return this.userInfoSubject;
  }

  public handleNoPermission() {
    this.dashboardRoutingService.directToRoute('Access Denied');
  }

  public createPermissionLookUpArray(rawPermissionArray: AccountPermission[]): { [key: string]: boolean } {
    let permissionsLookUpArray: { [key: string]: boolean } = {};
    for (let rawPermission of rawPermissionArray) {
      permissionsLookUpArray[rawPermission.permission_name] = rawPermission.has_permission;
    }
    return permissionsLookUpArray;
  }

  // For the BusinessSettings we have to change the checkedPermission based on what entityGroup the user is.
  public getPermissionsForBusinessSettings(groupName: EntityGroupName): string {
    let returnedCheckedPermission: string;
    if (groupName === 'root') {
      returnedCheckedPermission = 'root';
    } else {
      returnedCheckedPermission = 'create_users';
    }
    return returnedCheckedPermission;
  }

  public ngOnDestroy() {
    this.permissionSubject.unsubscribe();
    this.userInfoSubject.unsubscribe();
    this.allPermissionSubject.unsubscribe();
  }

  private handleErrorFromRequest(errorResponse: HttpErrorResponse): void {
    if (errorResponse.status > 0) {
      this.dashboardRoutingService.directToRoute('Login');
      throw new Error(errorResponse.error.error);
    }
  }
}
