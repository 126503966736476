import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderStateService } from '../header-state-service/header.state.service';
import { RoutingService } from '../../core/services/routing.service';
import { DashboardApiService } from '../../core/services/api/dashboard-api.service';
import { map, withLatestFrom, catchError } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-account-balance',
  templateUrl: './account-balance.component.html',
  styleUrls: ['./account-balance.component.scss']
})
export class AccountBalanceComponent implements OnInit, OnDestroy {
  constructor(
    public headerStateService: HeaderStateService,
    private dashhboardRoutingService: RoutingService,
    private dashboardApiService: DashboardApiService,
    private snackBar: SnackBarService,
  ) { }

  environment = environment;
  accountBalance$ = this.dashboardApiService.currentAccountBalance$.pipe(
    catchError((errorResponse: HttpErrorResponse) => {
      this.snackBar.openErrorSnackBar(errorResponse.error.error);
      return of(0);
    }));

  buttonName$ = this.dashboardApiService.currentAccount$.pipe(
    map(x => x.entity_group_name === 'affiliates' ? 'Withdraw' : 'View'));

  currencyType$ = this.dashboardApiService.currentAccount$.pipe(
    map(x => <any> x.entity_network === 'any' ? 'USD' : x.entity_network + 'D'));

  dialogTitle$ = this.dashboardApiService.currentAccount$.pipe(
    map(x => x.entity_group_name  === 'affiliates' ? 'Available Balance' : 'Balance Due'));

  buttonAction$ = new Subject();
  buttonAction$Sub = this.buttonAction$.pipe(withLatestFrom(this.dashboardApiService.currentAccount$))
    .subscribe(([, currentAccount]) => {
      if (currentAccount.entity_group_name === 'affiliates') {
        this.dashhboardRoutingService.directToRoute('WithdrawNow');
      } else {
        this.dashhboardRoutingService.directToRoute('ViewInvoiceList');
      }
    });

  ngOnInit() {
  }

  ngOnDestroy() {
    this.buttonAction$Sub.unsubscribe();
  }
}
