import { Injectable } from '@angular/core';

import { DashboardStateService } from '../dashboard-state-service/dashboard-state.service';
import { UserSettingsApiService } from '../../core/services/api/user-settings-api.service';
import { User, CurrentUserAccount } from '../../core/dtos/api';
import { environment } from '../../../environments/environment';
import { DashboardFixtures } from '../dashboard-fixtures/dashboard-fixtures';

@Injectable()
export class DashboardService {
  public homepage: string;

  constructor(
    private userSettingsApi: UserSettingsApiService,
    private dashboardStateService: DashboardStateService,
  ) {
    this.homepage = environment.classicUrl + '/admin/';
  }

  // TODO: We need a universal way to be sure that the global user state has finished being setup when an account switch or other state-altering event happens.
  public setupDashboardStateService(
    currentAccountResponse?: CurrentUserAccount,
    currentUserResponse?: User
  ): void {
    let userId: string;
    this.populateAccountCurrencies();
    if (currentAccountResponse) {
      userId = currentAccountResponse.user_id;
      this.loadCurrentAccountInformation(currentAccountResponse);
    }

    if (currentUserResponse) {
      userId = currentUserResponse.user_id;
      this.loadUserInformation(currentUserResponse);
    }

    if (userId) {
      this.userSettingsApi.defaultCurrency$.subscribe((currency) => {
        this.dashboardStateService.userDefaultCurrency = currency;
      });
    }
  }


  private populateAccountCurrencies() {
    let availableCurrencies = DashboardFixtures.getAvailableCurrencies();
    this.dashboardStateService.userCurrencies = [];
    Object.keys(availableCurrencies).forEach((key) => {
      this.dashboardStateService.userCurrencies.push(availableCurrencies[key].name);
    });
  }

  private loadCurrentAccountInformation(currentAccountResponse: CurrentUserAccount) {
    this.dashboardStateService.entityGroupName = currentAccountResponse.entity_group_name;
    this.dashboardStateService.entityId = currentAccountResponse.entity_id;
    this.dashboardStateService.classicId = currentAccountResponse.classic_entity_id;
    this.dashboardStateService.userId = currentAccountResponse.user_id;
    this.dashboardStateService.entityName = currentAccountResponse.entity_name;
    this.dashboardStateService.entityNetwork = currentAccountResponse.entity_network;
  }

  private loadUserInformation(
    currentUserResponse: User
  ) {
    this.dashboardStateService.userInfo = {
      emailAddress: currentUserResponse.email_address,
      userId: currentUserResponse.user_id,
      firstName: currentUserResponse.first_name,
      lastName: currentUserResponse.last_name
    };
  }
}
