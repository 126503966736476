import { Injectable } from '@angular/core';
import { LoginApiService } from './api/login-api.service';
import { DashboardHttpService } from '../../core-services/dashboard-http-service/dashboard-http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private loginApiService: LoginApiService, private dashboardHttpService: DashboardHttpService) {
  }

  /**
   * The route /logout is a convenience to logout by just hitting a url if the UI logout button is unavailable, otherwise
   * we can call the logout() method directly.
   */
  logout(displaySessionTimeout = false): void {
    this.loginApiService.logout().subscribe(
      {
        next: () => {
          this.dashboardHttpService.removeAuthAndFullNavLogout(displaySessionTimeout);
        },
        error: () => {
          this.dashboardHttpService.removeAuthAndFullNavLogout(displaySessionTimeout);
        }
      });
  }
}
