import { Component, Input } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-logo-link',
  templateUrl: './logo-link.component.html',
  styleUrls: ['./logo-link.component.scss']
})
export class LogoLinkComponent {

  constructor(
  ) { }

  environment = environment;

}
