<span matDialogTitle>
    Current Account
</span>
<div class="current-block-container">
  <app-account-tile [accountTileData]="activeAccount" [isActiveAccount]="true" *ngIf="activeAccount" (toggleClick)="handleAccountFavoritesToggle($event)" ></app-account-tile>
  <div *ngFor="let network of networkAccounts" (click)="changeNetwork(network)">
      <app-account-tile [accountTileData]="network"></app-account-tile>
  </div>
  <div>
    <button class="green small" type="button" mat-raised-button (click)="logout()">
      Log out of AvantLink
    </button>
  </div>
  <div class="available-accounts-title">
    Available Accounts
  </div>
  <div class="flex-row search-inputs">
    <mat-form-field class="account-input-form-field deprecated-mat-form-field">
      <mat-label>Search Accounts</mat-label>
      <input matInput type="text" #searchInput placeholder="Account Name, URL, Classic ID" class="account-switcher-input" (keyup)="getInput(searchInput.value)">
    </mat-form-field>
  </div>
</div>
<div class="search-block">
  <div class="available-accounts-block-container">
    <ng-container *ngIf="showFavorites; else searchResults">
      <div class="available-account-containers" *ngFor="let account of favorites">
        <app-account-tile [accountTileData]="account" (toggleClick)="handleAccountFavoritesToggle($event)" (switchAccountClick)="switchAccount(account)"></app-account-tile>
      </div>
    </ng-container>
    <ng-template #searchResults>
      <div class="available-account-containers" *ngFor="let account of filteredAccountResults">
        <app-account-tile [accountTileData]="account" (toggleClick)="handleAccountFavoritesToggle($event)" (switchAccountClick)="switchAccount(account)"></app-account-tile>
      </div>
    </ng-template>
  </div>
</div>
