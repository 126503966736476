import { Observable, forkJoin, of, throwError, BehaviorSubject } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import {
  MerchantBalance,
  MerchantWebsite,
  SearchMerchantWebsite,
  SearchMerchants,
  LoginAccounts,
  MerchantInfo,
  MerchantGroup,
  RelationshipStatusSearch,
  MerchantRelationshipAffiliate,
  Paged,
  Affiliate,
  MerchantContact,
  MerchantContactType,
  MerchantProgramManager,
  UpdateMerchantInfoRequest,
  UpdateMerchantProgramManagerRequest,
  MerchantAd,
  MerchantAdRequest,
  MerchantAdSchedule,
  ScheduleStatus,
  EditMultipleMerchantAdRequest,
  EditMultipleMerchantAdResponse
} from '../../dtos/api';
import { DashboardHttpService } from '../../../core-services/dashboard-http-service/dashboard-http.service';
import { AffiliateCount } from '../../dtos/api';
import { RequestTrackerService } from '../../../core-services/request-tracker-service/request-tracker.service';
import { range } from '../../pure-utils/array-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { deepMutateIsoToMtTime, mtTimeToUtc } from '../../pure-utils/string-utils';
import {
  CreateMerchantAdRequest,
  CreateMerchantAdScheduleRequest,
  EditMerchantAdRequest, EditMerchantAdScheduleRequest
} from '../../../tool/ad-management/ad-management';

@Injectable({
  providedIn: 'root',
})
export class MerchantApiService {
  private path = '/merchants';

  public merchantClassicId$ = new BehaviorSubject('');

  constructor(private http: DashboardHttpService, private requestTracker: RequestTrackerService) { }

  public getAccountBalance(merchantId: string): Observable<MerchantBalance> {
    return this.http.get<MerchantBalance>
      (environment.apiUrl + this.path + '/' + merchantId + '/account/balance');
  }

  public getMerchantWebsitesByEntityId(merchantId: string): Observable<MerchantWebsite[]> {
    return this.http.get<MerchantWebsite[]>
      (environment.apiUrl + this.path + '/' + merchantId + '/websites');
  }

  public getAssociatedAffiliateCountsByEntityIds(entityIds: string[]): Observable<AffiliateCount> {
    if (entityIds.length === 0) {
      return of({
        active: 0,
        deactivated: 0,
        denied: 0,
        offer: 0,
        pending: 0,
      });
    }

    let slug = `merchants/${entityIds.join(',')}/relationships/counts`;
    return this.http.get<AffiliateCount>(`${environment.apiUrl}/${slug}`);
  }

  public searchMerchantWebsites(searchTerm: string, isDefault?: boolean, merchantId?: string): Observable<SearchMerchantWebsite[]> {
    let requestUrl = `${environment.apiUrl}${this.path}/websites/search?merchant_website_url=${searchTerm}`;
    if (isDefault !== undefined) {
      requestUrl += `&is_default=${isDefault}`;
    }
    if (merchantId !== undefined) {
      requestUrl += `&merchant_id=${merchantId}`;
    }
    return this.http.get<SearchMerchantWebsite[]>(requestUrl, false);
  }

  public searchMerchantsByName(searchTerm: string): Observable<SearchMerchants[]> {
    return this.http.get<SearchMerchants[]>
      (`${environment.apiUrl}${this.path}/search?search=${searchTerm}`, false);
  }

  public getMerchantUsers(merchantId: string) {
    let requestUrl = `${environment.apiUrl}${this.path}/${merchantId}/account/users`;
    return this.http.get<LoginAccounts[]>(requestUrl, false);
  }

  public getMerchantById(merchantUuid: string): Observable<MerchantInfo> {
    return this.http.get<MerchantInfo>(`${environment.apiUrl}${this.path}/${merchantUuid}`);
  }

  /**
   * returns active merchants only
   */
  public getMerchants(page?: number, limit?: number): Observable<Paged<MerchantInfo>> {
    let params = {};
    if (!!page) {
      params['page'] = page;
    }
    if (!!limit) {
      params['limit'] = limit;
    }
    return this.http.getWithOptions<Paged<MerchantInfo>>(`${environment.apiUrl}${this.path}`, {params});
  }

  /**
   * returns active merchants only
   */
  public getAllMerchants(): Observable<MerchantInfo[]> {
    return this.getMerchants(1, 1000).pipe(
      switchMap(initialResponse => {
        let total_pages = Math.ceil(initialResponse.total_items / initialResponse.items_per_page);

        if (total_pages === 1) {
          return of(initialResponse.items);
        }

        let requests = range(2, total_pages + 1).map(x => this.getMerchants(x, 1000));

        return <Observable<MerchantInfo[]>> forkJoin(requests).pipe(
          map(x => x.reduce((acc: MerchantInfo[], current: Paged<MerchantInfo>) => acc.concat(current.items), [...initialResponse.items]))
        );
      })
    );
  }

  public getMerchantGroupsMerchantBelongsTo(merchantId: string) {
    return this.http.get<MerchantGroup[]>(`${environment.apiUrl}${this.path}/${merchantId}/merchantGroups`, false);
  }

  public getAssociatedAffiliatesByEntityIds(entityIds: string[],
                                            relationshipStatus?: RelationshipStatusSearch[] | RelationshipStatusSearch,
                                            page?: number,
                                            limit?: number,
                                            searchTerm?: string) {
    if (entityIds.length === 0) {
      return of<Paged<MerchantRelationshipAffiliate>>({
        items: [],
        items_per_page: limit,
        page: 1,
        total_items: 0
      });
    }

    let slug = `merchants/${entityIds.join(',')}/relationships`;
    let params = {};
    if (!!relationshipStatus) {
      if (typeof(relationshipStatus) === 'string') {
        params['relationship_status'] = relationshipStatus;
      } else {
        params['relationship_status'] = relationshipStatus.join(',');
      }
    }
    if (!!page) {
      params['page'] = page;
    }
    if (!!limit) {
      params['limit'] = limit;
    }
    if (!!searchTerm) {
      params['search_term'] = searchTerm;
    }
    let requestData = {
      request: `${environment.apiUrl}/${slug}`,
      options: {params}
    };
    return of(requestData).pipe(
      tap(() => {
        this.requestTracker.merchantRelationshipRequestTracker$.next(this.requestTracker.merchantRelationshipRequestTracker$.value + 1);
      }),
      mergeMap((localRequestData) =>
        this.http.getWithOptions<Paged<MerchantRelationshipAffiliate>>(localRequestData.request, localRequestData.options)
      ),
      finalize(() => {
        this.requestTracker.merchantRelationshipRequestTracker$.next(this.requestTracker.merchantRelationshipRequestTracker$.value - 1);
      })
    );
  }

  public getMerchantContacts(merchantId: string, contactType?: MerchantContactType) {
    let url = `${environment.apiUrl}/merchants/${merchantId}/contacts`;
    if (contactType != null) {
      url += `/${contactType}`;
    }

    return this.http.get<MerchantContact[]>(url);
  }

  public updateMerchantContact(merchantId: string, merchantContact: MerchantContact) {
    let url = `${environment.apiUrl}/merchants/${merchantId}/contacts/${merchantContact.merchant_contact_type}`;

    return this.http.put<MerchantContact, MerchantContact>(url, merchantContact);
  }

  public getMerchantProgramManager(merchantId: string) {
    return this.http.get<MerchantProgramManager>(`${environment.apiUrl}/merchants/${merchantId}/programs`);
  }

  public updateMerchantProgramManager(merchantProgramId: string, merchantProgramManager: UpdateMerchantProgramManagerRequest) {
    return this.http.put<UpdateMerchantProgramManagerRequest, []>(
      `${environment.apiUrl}/merchants/${merchantProgramManager.merchant_id}/programs/${merchantProgramId}`, merchantProgramManager);
  }

  public updateMerchant(merchantId: string, updateMerchantInfoRequest: UpdateMerchantInfoRequest) {
    return this.http.put<UpdateMerchantInfoRequest, MerchantInfo>(`${environment.apiUrl}/merchants/${merchantId}`, updateMerchantInfoRequest);
  }

  public getNewAffiliatesByEntityId(entityId: string): Observable<Affiliate[]> {
    let slug = `/${entityId}/new-affiliates`;
    return this.http.get<Affiliate[]>(`${environment.apiUrl}${this.path}${slug}`);
  }

  public getAllAssociatedAffiliatesByEntityIds(merchantIds: string[], relationshipStatus?: RelationshipStatusSearch[] | RelationshipStatusSearch):
    Observable<MerchantRelationshipAffiliate[]> {

    return this.getAssociatedAffiliatesByEntityIds(merchantIds, relationshipStatus, 1, 1000).pipe(
      switchMap(initialResponse => {
        let total_pages = Math.ceil(initialResponse.total_items / initialResponse.items_per_page);
        let requests: Observable<Paged<MerchantRelationshipAffiliate>>[] = [];
        for (let i = 2; i <= total_pages; i++) {
          requests.push(this.getAssociatedAffiliatesByEntityIds(merchantIds, relationshipStatus, i, 1000));
        }

        if (requests.length > 0) {
          return forkJoin(requests).pipe(
            map(forkResponse => initialResponse.items.concat(...forkResponse.map(x => x.items))));
        }
        return of(initialResponse.items);
      }));
  }

  public getAllPagesOfMerchantAds(merchantId: string, extraParams = {} as MerchantAdRequest):
    Observable<MerchantAd[]> {
    let initialParams: MerchantAdRequest = {
      limit: 1000,
      page: 0,
      ...extraParams
    };

    return this.getSinglePageOfMerchantAds(merchantId, initialParams).pipe(
      switchMap(initialResponse => {
        let total_pages = Math.ceil(initialResponse.total_items / initialResponse.items_per_page);
        let requests: Observable<Paged<MerchantAd>>[] = [];
        for (let i = 2; i <= total_pages; i++) {
          let nextParams = {
            limit: 1000,
            page: i,
            ...extraParams
          };
          requests.push(this.getSinglePageOfMerchantAds(merchantId, nextParams));
        }

        if (requests.length > 0) {
          return forkJoin(requests).pipe(
            map(forkResponse => initialResponse.items.concat(...forkResponse.map(x => x.items))));
        }
        return of(initialResponse.items);
      }));
  }

  public getSinglePageOfMerchantAds(merchantId: string, query?: MerchantAdRequest) {
    let params = { ...query,
      sort: query.sort && query.direction ? 'ma.' + query.sort : '',
      direction: query.direction?.toUpperCase() ?? ''
    };

    return this.http.getWithOptions<Paged<MerchantAd>>(`${environment.apiUrl}/merchants/${merchantId}/ads`, { params }).pipe(
      tap(x => deepMutateIsoToMtTime(x)),
      catchError((response: HttpErrorResponse) => {
        if (response.status === 404 && response?.error?.error_key === 'api.model.merchant_ad.not_found') {
          return of<Paged<MerchantAd>>({
            items: [],
            items_per_page: params.limit,
            page: 1,
            total_items: 0
          });
        } else {
          return throwError(response);
        }
      }));
  }

  public createMerchantAds(merchantId: string, merchantAdData: CreateMerchantAdRequest): Observable<MerchantAd> {

    let formData = new FormData();
    formData.append('ad_type', merchantAdData.adType);
    formData.append('ad_title', merchantAdData.adTitle);
    formData.append('destination_url', merchantAdData.destinationUrl);
    formData.append('start_date', mtTimeToUtc(merchantAdData.startDate).toISOString());
    formData.append('end_date', merchantAdData.endDate ? mtTimeToUtc(merchantAdData.endDate).toISOString() : '');

    if (merchantAdData.promotionDetails) {
      formData.append('promotion_details', merchantAdData.promotionDetails);
    }

    if (merchantAdData.couponCodeId) {
      formData.append('coupon_code_id', merchantAdData.couponCodeId);
    }

    if (merchantAdData.restrictedEntityGroupName) {
      formData.append('restricted_entity_group_name', merchantAdData.restrictedEntityGroupName);
    }

    if (merchantAdData.restrictedEntityId) {
      formData.append('restricted_entity_id', merchantAdData.restrictedEntityId);
    }

    if (merchantAdData.adType === 'text') {
      formData.append('link_text', merchantAdData.linkText);
    }

    if (merchantAdData.adType === 'banner') {
      formData.append('image_file', merchantAdData.imageFile);
    }

    return this.http.postWithOptions<MerchantAd>(`${environment.apiUrl}/merchants/${merchantId}/ads`, formData);
  }

  public editMerchantAd(merchantId: string, merchantAdId: string, merchantAdData: EditMerchantAdRequest) {
    let formData = new FormData();

    if (merchantAdData.adTitle != null) {
      formData.append('ad_title', merchantAdData.adTitle);
    }

    if (merchantAdData.destinationUrl != null) {
      formData.append('destination_url', merchantAdData.destinationUrl);
    }

    if (merchantAdData.startDate != null) {
      formData.append('start_date', mtTimeToUtc(merchantAdData.startDate).toISOString());
    }

    if (merchantAdData.endDate !== undefined) {
      formData.append('end_date', merchantAdData.endDate && mtTimeToUtc(merchantAdData.endDate).toISOString());
    }

    if (merchantAdData.promotionDetails != null) {
      formData.append('promotion_details', merchantAdData.promotionDetails);
    }

    if (merchantAdData.couponCodeId != null) {
      formData.append('coupon_code_id', merchantAdData.couponCodeId);
    }

    if (merchantAdData.restrictedEntityGroupName != null) {
      formData.append('restricted_entity_group_name', merchantAdData.restrictedEntityGroupName);
    }

    if (merchantAdData.restrictedEntityId != null) {
      formData.append('restricted_entity_id', merchantAdData.restrictedEntityId);
    }

    if (merchantAdData.linkText != null) {
      formData.append('link_text', merchantAdData.linkText);
    }

    if (merchantAdData.imageFile != null) {
      formData.append('image_file', merchantAdData.imageFile);
    }

    return this.http.postWithOptions<MerchantAd>(`${environment.apiUrl}/merchants/${merchantId}/ads/${merchantAdId}`, formData);
  }

  /**
   * This allows you to update multiple merchant ads at once. This allows for the update of everything but,
   * the image. Therefore, if you need to update an image you will need to use the editMerchantAd method, which only updates
   * merchant ads one at a time.
   *
   * @param multipleMerchantAdsData
   */
  public editMultipleMerchantAds(multipleMerchantAdsData: EditMultipleMerchantAdRequest[]) {
    let requestPayload = {
      merchant_ads: multipleMerchantAdsData
    };
    return this.http.putWithOptions<EditMultipleMerchantAdResponse[]>(`${environment.apiUrl}/merchants/ads/multiple`, requestPayload);
  }

  public createMerchantAdSchedule(merchantId: string, merchantAdId: string, merchantAdData: CreateMerchantAdScheduleRequest) {
    let formData = new FormData();

    formData.append('ad_title', merchantAdData.adTitle);
    formData.append('destination_url', merchantAdData.destinationUrl);
    formData.append('schedule_start_date', mtTimeToUtc(merchantAdData.scheduleStartDate).toISOString());

    if (merchantAdData.promotionDetails) {
      formData.append('promotion_details', merchantAdData.promotionDetails);
    }

    if (merchantAdData.couponCodeId) {
      formData.append('coupon_code_id', merchantAdData.couponCodeId);
    }

    if (merchantAdData.linkText) {
      formData.append('link_text', merchantAdData.linkText);
    }

    if (merchantAdData.imageFile) {
      formData.append('image_file', merchantAdData.imageFile);
    }

    return this.http.postWithOptions<MerchantAdSchedule>(`${environment.apiUrl}/merchants/${merchantId}/ads/${merchantAdId}/schedules`, formData);
  }

  public editMerchantAdSchedule(merchantId: string, merchantAdId: string, merchantAdScheduleId: string, merchantAdData: EditMerchantAdScheduleRequest) {
    let formData = new FormData();

    if (merchantAdData.adTitle != null) {
      formData.append('ad_title', merchantAdData.adTitle);
    }

    if (merchantAdData.destinationUrl != null) {
      formData.append('destination_url', merchantAdData.destinationUrl);
    }

    if (merchantAdData.scheduleStartDate != null) {
      formData.append('schedule_start_date', mtTimeToUtc(merchantAdData.scheduleStartDate).toISOString());
    }

    if (merchantAdData.promotionDetails != null) {
      formData.append('promotion_details', merchantAdData.promotionDetails);
    }

    if (merchantAdData.couponCodeId != null) {
      formData.append('coupon_code_id', merchantAdData.couponCodeId);
    }

    if (merchantAdData.linkText != null) {
      formData.append('link_text', merchantAdData.linkText);
    }

    if (merchantAdData.imageFile != null) {
      formData.append('image_file', merchantAdData.imageFile);
    }

    let url = `${environment.apiUrl}/merchants/${merchantId}/ads/${merchantAdId}/schedules/${merchantAdScheduleId}`;

    return this.http.postWithOptions<MerchantAdSchedule>(url, formData);
  }

  getMerchantAdSchedules(merchantId: string, merchantAdId: string, scheduleStatus: ScheduleStatus) {
    let url = `${environment.apiUrl}/merchants/${merchantId}/ads/${merchantAdId}/schedules`;

    return this.http.getWithOptions<MerchantAdSchedule[]>(url, {params: {schedule_status: scheduleStatus}}).pipe(
      tap(x => deepMutateIsoToMtTime(x)));
  }

  deleteMerchantAdSchedule(merchantId: string, merchantAdId: string, merchantAdScheduleId: string) {
    let url = `${environment.apiUrl}/merchants/${merchantId}/ads/${merchantAdId}/schedules/${merchantAdScheduleId}`;

    return this.http.delete<MerchantAdSchedule>(url).pipe(tap(x => deepMutateIsoToMtTime(x)));
  }
}
