import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { LoginStateService } from '../login-state-service/login-state.service';
import { ApiService } from '../../api/api.module';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { LoginApiService } from '../../core/services/api/login-api.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements AfterViewInit {
  public validationCode: number;
  public password: string;
  public verifyPassword: string;
  private resetPasswordSubscription: Subscription;

  constructor(
    private loginApi: LoginApiService,
    private loginStateService: LoginStateService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: SnackBarService,
  ) { }

  public get initialPasswordSetup() {
    return this.loginStateService.initialPasswordSetup;
  }

  resetPassword() {
    if (!this.resetPasswordSubscription) {
      this.resetPasswordSubscription = this.loginApi.resetPassword({
        email_address: this.loginStateService.emailAddress,
        verification_code: this.validationCode,
        password: this.password,
        password_confirmation: this.verifyPassword
      }).subscribe(
        () => {
          this.apiService.userAuthorization = null;
          this.snackBar.openSnackBar('Your password has successfully been updated.');
          this.router.navigate(['/login']);
        },
        (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error));
      this.resetPasswordSubscription?.add(() => this.resetPasswordSubscription = null);
    }
  }

  resendEmailCode() {
    this.loginApi.sendResetCode({
      email_address: this.loginStateService.emailAddress
    }).subscribe(
      resendEmailCode => this.snackBar.openSnackBar(resendEmailCode.message),
      (errorResponse: HttpErrorResponse) => this.snackBar.openErrorSnackBar(errorResponse.error.error)
      );
  }

  ngAfterViewInit() {
    if (this.route.snapshot.paramMap.get('emailAddress')) {
      this.loginStateService.emailAddress = this.route.snapshot.paramMap.get('emailAddress');
    } else if (this.loginStateService.emailAddress) {
      this.snackBar.openSnackBar('Validation code sent to ' + this.loginStateService.emailAddress + '.');
    } else {
      this.router.navigate(['/login/forgot-password']);
    }
  }
}
