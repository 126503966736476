<div class="form-container">
  <form class="verify-device-form" (ngSubmit)="verifyCode()" #verifyDeviceForm="ngForm">
  <app-logo-link></app-logo-link>
  <p class="message">
    Validate your login
  </p>
  <p>
    Enter the validation code we sent you.
  </p>
  <div class="container">
    <mat-form-field>
      <input matInput name="validationCode" type="text" required minlength="7" placeholder="Validation Code" [(ngModel)]="validationCode">
      <mat-icon matPrefix>verified_user</mat-icon>
    </mat-form-field>
    <div>
      <a (click)="resendCode()">
        Resend code
      </a>
    </div>
  </div>
  <button color="primary" type="submit" mat-raised-button [disabled]="!verifyDeviceForm.valid || verifyDeviceForm.pristine">
    Verify Code
  </button>
</form>
  <div>
    <p>
      <a [routerLink]="['/login']">Back to login</a>
    </p>
  </div>
</div>
