export const environment = {
	production: true,
	domain: '.avantlink.net',
	apiUrl: 'https://api-default.avantlink.net',
	classicUrl: 'https://classic-default.avantlink.net',
	wwwUrl: 'https://classic-default.avantlink.com',
	dashboardUrl: 'https://dashboard-default.avantlink.net',
	easyLinkUrl: 'https://dashboard-default.avantlink.net/easy-link',
	isClassic: false,
	enableAnalytics: false,
	featuresFlags: {"showEdw3Reports": false}
};
