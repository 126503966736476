import { NgModule } from '@angular/core';

import { AuthyApiService } from './authy-api.service';
import { CountryCodeService } from './country-code.service';

@NgModule({
  providers: [
    AuthyApiService,
    CountryCodeService
  ]
})
export class AuthyApiModule { }
export { AuthyApiService } from './authy-api.service';
export { CountryCodeService } from './country-code.service';
export { CountryCode } from './country-code';
