<h1 mat-dialog-title>{{ dialogTitle$ | async }}</h1>
<div mat-dialog-content>
  <a class="mat-body-2 balance" href="{{environment.classicUrl}}/affiliate/payout_history.php?strAction=balanceSummary">
    <span class="currency-value">{{accountBalance$ | async | currency: 'USD':'symbol-narrow'}}</span>
    <span><sub><small>{{currencyType$ | async}}</small></sub></span>
  </a>
  <button (click)="buttonAction$.next(undefined)" class="green material-default-size" type="button" mat-raised-button>
    {{buttonName$ | async}}
  </button>
</div>
