/**
 * Indicates whether the specified value is an object.
 * @param value
 */
export function isTypeObject(value: any): boolean {
    return value && typeof value === 'object';
}

/**
 * Indicates whether the specified object is null or empty ({}).
 *
 * @param obj object
 */
export function isNullOrEmpty(obj: unknown) {
  return obj == null || (Object.keys(obj).length === 0 && obj.constructor === Object);
}

/**
 * Deeply traverses an object.
 *
 * @param object Object to traverse
 * @param fn Function to execute on each object graph property.
 */
 export function traverse(object: any, fn: (currentObject: any, propName: string, value: any) => void) {
  let propNames = Object.getOwnPropertyNames(object);

  for (let propName of propNames) {
    let value = object[propName];

    if (value && typeof value === 'object') {
      traverse(value, fn);
    } else {
      fn(object, propName, value);
    }
  }
}
