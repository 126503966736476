import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewContainerRef
} from '@angular/core';
import {ICustomComponent, DataTableCustomComponentEvent} from '../data-table-display';

@Component({
  selector: 'app-data-table-cell',
  templateUrl: './data-table-cell.component.html',
  styleUrls: ['./data-table-cell.component.scss']
})

export class DataTableCellComponent  implements OnInit, OnChanges {
  @Input() isComponent: boolean;
  // This is typed as an any as to allow for all data types.
  @Input() associatedData: any;
  // This is the component that we want to render within the dataTable
  @Input() component: Type<ICustomComponent>;
  @Output() dynamicComponentEvent: EventEmitter<DataTableCustomComponentEvent> = new EventEmitter<DataTableCustomComponentEvent>();

  constructor(private viewContainerRef: ViewContainerRef) {}

  public ngOnInit(): void {
    if (this.isComponent && this.associatedData) {
      this.loadComponent();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.isComponent && this.associatedData && changes['associatedData'] && !changes['associatedData'].isFirstChange()) {
      this.loadComponent();
    }
  }

  private loadComponent() {
    this.viewContainerRef.clear();

    let componentRefInstance: ICustomComponent = (<ICustomComponent> this.viewContainerRef.createComponent(this.component).instance);
    componentRefInstance.data = this.associatedData;
    componentRefInstance.eventEmitter.subscribe((data) => {
      this.dynamicComponentEvent.next(data);
    });
  }
}
