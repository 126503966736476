import { Injectable } from '@angular/core';
import { Observable, concat, ReplaySubject, of, combineLatest, defer, BehaviorSubject } from 'rxjs';
import {switchMap, shareReplay, first, map} from 'rxjs/operators';
import {AffiliateExclusiveOption, CurrentUserAccount, MerchantRelationshipAffiliate} from '../../dtos/api';
import { AffiliateApiService } from './affiliate-api.service';
import { MerchantApiService } from './merchant-api.service';
import { MerchantGroupApiService } from './merchant-group-api.service';
import { UsersApiService } from './users-api.service';
import { LoginApiService } from './login-api.service';
import {outPlaceSort} from '../../pure-utils/array-utils';

@Injectable({
  providedIn: 'root',
})
export class DashboardApiService {
  readonly switchAccount$ = new ReplaySubject<CurrentUserAccount>(1);
  readonly currentAccount$ = concat(defer(() => this.loginApiService.currentAccount()), this.switchAccount$).pipe(
    shareReplay(1));

  readonly currentUserId$ = this.currentAccount$.pipe(
    first(),
    map(x => x.user_id),
    shareReplay(1));

  readonly refreshCurrentUserInfo$ = new BehaviorSubject<void>(null);
  readonly currentUserInfo$ = combineLatest([this.currentUserId$, this.refreshCurrentUserInfo$]).pipe(
    switchMap(([userId, ]) => this.usersApiService.getUser(userId)),
    shareReplay(1));

  readonly availableAccounts$ = this.currentUserId$.pipe(
    switchMap(userId => this.usersApiService.getAccounts(userId)),
    shareReplay(1));

  readonly currentAccountGroup$ = this.currentAccount$.pipe(
    map(x => x.entity_group_name),
    shareReplay(1));

  readonly isRootUser$ = this.availableAccounts$.pipe(
    map(x => x.some(availableAccount => availableAccount.entity_group_name === 'root')),
    shareReplay({bufferSize: 1, refCount: true}));

  readonly currentAccountBalance$ = this.currentAccount$.pipe(
    switchMap((currentAccount) => {
      let balance: Observable<number>;

      switch (currentAccount.entity_group_name) {
        case 'merchants':
          balance = this.merchantApiService.getAccountBalance(currentAccount.entity_id).pipe(map(x => x.outstanding_balance));
          break;
        case 'merchant_groups':
          balance = this.merchantGroupApiService.getAccountBalance(currentAccount.entity_id).pipe(map(x => x.outstanding_balance));
          break;
        case 'affiliates':
          balance = this.affiliateApiService.getAccountBalance(currentAccount.entity_id).pipe(map(x => x.available_balance));
          break;
        default:
          balance = of(0);
          break;
      }

      return balance;
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  readonly usersAffiliates$ = this.currentAccount$.pipe(
    switchMap(currentAccount => this.merchantApiService.getAllAssociatedAffiliatesByEntityIds([currentAccount.entity_id], ['active'])),
    map(allAffiliateWebsites => {
      let activeAffiliateWebsites = allAffiliateWebsites.filter(x => x.affiliate_status === 'active');
      // Remove duplicates.
      let uniqueAffiliateWebsitesMap = activeAffiliateWebsites.reduce((acc, affiliate) => (acc.set(affiliate.affiliate_website_id, affiliate), acc),
        new Map<string, MerchantRelationshipAffiliate>());
      return [...uniqueAffiliateWebsitesMap.values()];
    }),
    map<MerchantRelationshipAffiliate[], AffiliateExclusiveOption[]>(dedupeFilteredWebsites => {
      dedupeFilteredWebsites = dedupeFilteredWebsites.map(x => ({...x, filterType: 'affiliates'}));
      return <AffiliateExclusiveOption[]> outPlaceSort(dedupeFilteredWebsites, x => x.affiliate_name + '◬' + x.affiliate_website_url_protocol_stripped);
    }),
    shareReplay({bufferSize: 1, refCount: true})
  );

  constructor(
    private affiliateApiService: AffiliateApiService,
    private merchantApiService: MerchantApiService,
    private merchantGroupApiService: MerchantGroupApiService,
    private usersApiService: UsersApiService,
    private loginApiService: LoginApiService
  ) { }
}
