<span matDialogTitle>
  Current Account
</span>
<div matDialogContent>
  <div class="current-block-container">
    <app-account-tile [accountTileData]="loginAccount"></app-account-tile>
    <button class="green small" type="button" mat-raised-button (click)="logout()">
      Log out of AvantLink
    </button>
    <div class="available-accounts-title" *ngIf="availableAccounts?.length > 1">
      Available Accounts
    </div>
    <div class="flex-row search-inputs" *ngIf="availableAccounts?.length > 1">
      <mat-form-field class="account-input-form-field deprecated-mat-form-field" *ngIf="availableAccounts?.length > 6">
        <input matInput type="text" name="searchInput" placeholder="Search Accounts" [(ngModel)]="searchInput" (keyup)="filterAccounts()">
      </mat-form-field>
    </div>
  </div>
  <div class="search-block" *ngIf="availableAccounts?.length > 1" [ngClass]="{'six-or-less-accounts': availableAccounts?.length < 7}">
    <div class="available-accounts-block-container">
      <div class="available-account-containers" *ngFor="let account of filteredAccountResults" (click)="switchAccount(account)">
        <app-account-tile [accountTileData]="account"></app-account-tile>
      </div>
    </div>
  </div>
</div>
