import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { HeaderStateService } from '../header-state-service/header.state.service';
import { AccountSwitcherService } from '../account-switcher-service/account-switcher.service';
import { DashboardStateService } from '../../dashboard/dashboard-state-service/dashboard-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { RoutingService } from '../../core/services/routing.service';
import { SingleAccountTile } from '../header';
import { AuthService } from '../../core/services/auth.service';
import { environment } from '../../../environments/environment';
import {SwitchAccountRequest} from '../../core/dtos/api';

@Component({
  selector: 'app-account-switcher-non-root',
  templateUrl: './account-switcher-non-root.component.html',
  styleUrls: ['./account-switcher-non-root.component.scss'],
})
export class AccountSwitcherNonRootComponent implements OnInit {
  public availableAccounts: SingleAccountTile[];
  public filteredAccountResults: SingleAccountTile[];
  public loginAccount: SingleAccountTile;
  public searchInput: string;
  private sendSwitchAccountSubscription: Subscription;
  private readonly escapeString = '/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g';
  private readonly replaceString = '\\$&';

  constructor(
    private accountSwitcherService: AccountSwitcherService,
    public dashboardStateService: DashboardStateService,
    public dashboardRoutingService: RoutingService,
    public headerStateService: HeaderStateService,
    public modal: MatDialog,
    private snackBar: SnackBarService,
    private authService: AuthService
  ) {
    this.searchInput = '';
    this.availableAccounts = [];
    this.loginAccount = {
      classicId: this.dashboardStateService.classicId,
      entityId: this.dashboardStateService.entityId,
      entityName: this.dashboardStateService.entityName,
      entityNetwork: this.dashboardStateService.entityNetwork,
      entityGroupName: this.dashboardStateService.entityGroupName,
      isTileSelected: true,
      isTileSelectable: false
    };
  }

  public ngOnInit() {
    this.availableAccounts = this.headerStateService.nonRootAvailableAccounts;

    if (this.availableAccounts) {
      this.filteredAccountResults = this.availableAccounts;
    }
  }

  public logout() {
    this.modal.closeAll();
    this.authService.logout();
  }

  public switchAccount(theAccount: SingleAccountTile) {
    let account = {
      classicId: theAccount.classicId,
      entityName: theAccount.entityName,
      entityNetwork: theAccount.entityNetwork,
      entityGroupName: theAccount.entityGroupName,
      entityId: theAccount.entityId,
      isTileSelected: true,
      isTileSelectable: false
    };

    let switchRequest: SwitchAccountRequest = {
      entity_id: account.entityId,
      entity_group_name: account.entityGroupName,
      entity_network: account.entityNetwork
    };

    if (!this.sendSwitchAccountSubscription) {
      this.sendSwitchAccountSubscription = this.accountSwitcherService.switchCurrentAccount(switchRequest, window.location.pathname)
        .subscribe(accountSwitchResponse => {
          if (environment.isClassic) {
            this.dashboardRoutingService.directToDashboard();
          }
          this.dashboardStateService.classicId = accountSwitchResponse.classic_entity_id;
          this.dashboardStateService.entityId = accountSwitchResponse.entity_id;
          this.dashboardStateService.entityName = accountSwitchResponse.entity_name;
          this.dashboardStateService.entityNetwork = accountSwitchResponse.entity_network;
          this.dashboardStateService.entityGroupName = accountSwitchResponse.entity_group_name;
          if (!this.dashboardStateService.userDefaultCurrency) {
            this.dashboardStateService.userDefaultCurrency = accountSwitchResponse.currency.currency_name;
          }
          this.loginAccount = account;
          this.modal.closeAll();
        },
        (errorResponse: HttpErrorResponse) => {
          this.snackBar.openErrorSnackBar(errorResponse.error.error);
        });
      this.sendSwitchAccountSubscription?.add(() => this.sendSwitchAccountSubscription = null);
    }
  }

  public filterAccounts() {
    this.filteredAccountResults = [];
    if (this.searchInput.length > 2) {
      for (let availableAccount of this.availableAccounts) {
        if (availableAccount.entityName.toUpperCase().match(
          this.searchInput.toUpperCase().replace(this.escapeString, this.replaceString))) {
          this.filteredAccountResults.push(availableAccount);
        }
      }
    } else {
      this.filteredAccountResults = this.availableAccounts;
    }
  }
}
