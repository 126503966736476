import { Injectable } from '@angular/core';
import { DashboardApiService } from './api/dashboard-api.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ZendeskService {

  constructor(private dashboardApiService: DashboardApiService) {
    this.zE('webWidget', 'updateSettings', {
      webWidget: {
        helpCenter: {
          title: {
            '*': 'Support',
          }
        },
        contactForm: {
          title: {
            '*': 'Support'
          },
          subject: true
        },
        color: {
          theme: '#112e5c',
          button: '#34c37a'
        },
        position: {
          horizontal: 'right',
          vertical: 'top'
        },
        offset: {
          vertical: '50px'
        }
      }
    });

    this.zE('webWidget:on', 'close', () => {
      this.zE('webWidget', 'hide');
    });

    this.zE('webWidget:on', 'open', () => {
      this.dashboardApiService.currentUserInfo$.pipe(first()).subscribe(currentUserInfo => {
        this.zE('webWidget', 'prefill', {
          name: {
            value: `${currentUserInfo.first_name} ${currentUserInfo.last_name}`,
            readOnly: false
          },
          email: {
            value: currentUserInfo.email_address,
            readOnly: false
          }
        });

        this.zE('webWidget', 'show');
      });
    });
  }

  private get zE(): (...args) => void {
    return window['zE'];
  }

  close() {
    this.zE('webWidget', 'close');
  }

  open() {
    this.zE('webWidget', 'open');
  }

  toggle() {
    this.zE('webWidget', 'toggle');
  }
}
