<div class="form-container">
  <form class="forgot-password-form" (ngSubmit)="sendResetCode()" #forgotPasswordForm="ngForm">
    <app-logo-link></app-logo-link>
    <p class="message">
      Forgot your password?
    </p>
    <div class="container">
      <mat-form-field>
      <input matInput name="email" type="email" required placeholder="Email" [(ngModel)]="loginStateService.emailAddress" appValidateEmail>
        <mat-icon matPrefix>email</mat-icon>
      </mat-form-field>
    </div>
    <button color="primary" type="submit" mat-raised-button [disabled]="!forgotPasswordForm.valid">
      Send Email
    </button>
  </form>
  <div>
    <p>
      <a [routerLink]="['/login']">Back to login</a>
    </p>
  </div>
</div>
