import { NgModule } from '@angular/core';
import { DashboardModule } from '../dashboard/dashboard.module';
import { HeaderComponent } from './header/header.component';
import { HeaderService } from './header-service/header.service';
import { HeaderStateService } from './header-state-service/header.state.service';
import { AccountBalanceComponent } from './account-balance/account-balance.component';
import { AccountSwitcherNonRootComponent } from './account-switcher-non-root/account-switcher-non-root.component';
import { AccountSwitcherRootComponent } from './account-switcher-root/account-switcher-root.component';
import { SessionTimeoutService } from './session-timeout-service/session-timeout.service';
import { AccountSwitcherService } from './account-switcher-service/account-switcher.service';
import { AccountTileComponent } from './account-tile/account-tile.component';
import { PipeModule } from '../pipe/pipe.module';
import { routing } from './header.routing';
import { ConfirmationModule } from '../confirmation/confirmation.module';
import { HeaderSubNavComponent } from './header/header-sub-nav/header-sub-nav.component';
import { SharedModule } from '../shared/shared.module';
import { NotificationUserListComponent } from './notification-user-list/notification-user-list.component';

@NgModule({
  imports: [
    DashboardModule,
    PipeModule,
    routing,
    ConfirmationModule,
    SharedModule,
  ],
  exports: [
    HeaderComponent
  ],
  declarations: [
    HeaderComponent,
    HeaderSubNavComponent,
    AccountBalanceComponent,
    AccountSwitcherNonRootComponent,
    AccountSwitcherRootComponent,
    AccountTileComponent,
    NotificationUserListComponent,
  ],
  providers: [
    HeaderService,
    HeaderStateService,
    AccountSwitcherService,
    SessionTimeoutService
  ]
})
export class HeaderModule { }
export { HeaderComponent } from './header/header.component';
export { HeaderService } from './header-service/header.service';
export { HeaderStateService } from './header-state-service/header.state.service';
export { AccountSwitcherNonRootComponent } from './account-switcher-non-root/account-switcher-non-root.component';
export { AccountSwitcherRootComponent } from './account-switcher-root/account-switcher-root.component';
export { AccountBalanceComponent } from './account-balance/account-balance.component';
export { AccountSwitcherService } from './account-switcher-service/account-switcher.service';
export { SessionTimeoutService } from './session-timeout-service/session-timeout.service';
