import { Injectable } from '@angular/core';
import { DashboardHttpService } from '../../../core-services/dashboard-http-service/dashboard-http.service';
import {
  Paged,
  SearchedUser,
  User,
  CreateUserRequest,
  UsersAvailableAccount,
  EmailSubscription,
  UpdateEmailSubscriptionRequest, CreateEmailSubscriptionRequest
} from '../../dtos/api';
import { environment } from '../../../../environments/environment';
import {catchError} from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {of, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  private path = '/users';

  constructor(private http: DashboardHttpService) { }

  /**
   * The backend searches terms using a startsWith pattern.
   */
  public searchUsers(searchTerm: string) {
    searchTerm = encodeURIComponent(searchTerm);
    let requestUrl = `${environment.apiUrl}${this.path}/search?name=${searchTerm}&last_name=${searchTerm}&email_address=${searchTerm}`;
    return this.http.get<Paged<SearchedUser>>(requestUrl, false);
  }

  public getUser(userId: string) {
    let requestUrl = `${environment.apiUrl}${this.path}/${userId}`;
    return this.http.get<User>(requestUrl, false);
  }

  public getAccounts(userId: string, includeDisabledAccounts = false) {
    let requestUrl = `${environment.apiUrl}${this.path}/${userId}/accounts?include_disabled_accounts=${includeDisabledAccounts}`;
    return this.http.get<UsersAvailableAccount[]>(requestUrl, false);
  }

  public createUser(user: Partial<User>, affiliateId: string = null, merchantId: string = null, merchantGroupId: string = null) {
    let requestUrl = `${environment.apiUrl}${this.path}`;
    let data = {
      first_name: user.first_name,
      last_name: user.last_name,
      email_address: user.email_address,
      affiliate_id: affiliateId,
      merchant_id: merchantId,
      merchant_group_id: merchantGroupId,
      send_email: true,
      generate_password: true
    };
    return this.http.post<CreateUserRequest, User>(requestUrl, data, false);
  }

  public getUserEmailSubscriptions(userId: string) {
    let requestUrl = `${environment.apiUrl}${this.path}/${userId}/emails/subscriptions`;
    return this.http.getWithOptions<EmailSubscription[]>(requestUrl).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of([] as EmailSubscription[]);
          }
          return throwError(() => error);
        })
    );
  }

  createEmailSubscription(userId: string, createSubscriptionRequest: CreateEmailSubscriptionRequest) {
    return this.http.postWithOptions<EmailSubscription>(`${environment.apiUrl}${this.path}/${userId}/emails/subscriptions`, createSubscriptionRequest);
  }

  updateEmailSubscription(userId: string, subscriptionId: string, subscriptionUpdateRequest: UpdateEmailSubscriptionRequest) {
    return this.http.putWithOptions<EmailSubscription>(
        `${environment.apiUrl}${this.path}/${userId}/emails/subscriptions/${subscriptionId}`, subscriptionUpdateRequest);
  }
}
