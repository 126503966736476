import { Injectable } from '@angular/core';
import { MatSnackBar,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
  SimpleSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(
    private snackBar: MatSnackBar
  ) {}

  public openSnackBar(message: string, action?: string, duration = 2500, verticalPosition: MatSnackBarVerticalPosition = 'top'): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      duration,
      panelClass: 'success-snackbar',
      verticalPosition
    });
  }

  public openErrorSnackBar(
    message: string,
    action?: string,
    duration = 5000,
    verticalPosition: MatSnackBarVerticalPosition = 'top'
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      duration,
      panelClass: 'error-snackbar',
      verticalPosition
    });
  }

  public openInfoSnackBar(message: string, action?: string, duration = 2500, verticalPosition: MatSnackBarVerticalPosition = 'top'):
    MatSnackBarRef<SimpleSnackBar> {
    return this.snackBar.open(message, action, {
      duration,
      panelClass: 'info-snackbar',
      verticalPosition
    });
  }
}
