import { NgModule } from '@angular/core';
import { ConfirmationComponent } from './confirmation.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ConfirmationComponent
  ],
  exports: [
    ConfirmationComponent
  ]
})

export class ConfirmationModule {
}

export { ConfirmationComponent } from './confirmation.component';
