import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DashboardHttpService } from '../../core-services/dashboard-http-service/dashboard-http.service';
import { environment } from '../../../environments/environment';
import {
  AuthySetupRequest,
  AuthyVerificationRequest,
  AuthyVerifyRequest,
  AuthyVerifyResponse,
  MfaUser
} from '../../core/dtos/api';

@Injectable()
export class AuthyApiService {
  private path = '/authy';

  constructor(private http: DashboardHttpService) {
  }

  public registerDevice(registerDeviceRequest: AuthyVerificationRequest) {
    return this.http.post<AuthyVerificationRequest, MfaUser>
    (environment.apiUrl + this.path + '/verification', registerDeviceRequest, false);
  }

  public verifyDevice(verifyDeviceRequest: AuthyVerifyRequest) {
    return this.http.post<AuthyVerifyRequest, AuthyVerifyResponse>
    (environment.apiUrl + '/login' + this.path + '/verification/verify', verifyDeviceRequest, false);
  }

  public setup(setupRequest: AuthySetupRequest) {
    return this.http.post<AuthySetupRequest, MfaUser>
    (environment.apiUrl + this.path + '/setup', setupRequest, false);
  }

  public verify(verifyRequest: AuthyVerifyRequest) {
    return this.http.post<AuthyVerifyRequest, AuthyVerifyResponse>
    (environment.apiUrl + '/login' + this.path + '/setup/verify', verifyRequest, false);
  }

  public userInfo(): Observable<MfaUser> {
    return this.http.get<MfaUser>(environment.apiUrl + this.path, false);
  }

  public deleteUserMFA(userId: string){
    return this.http.delete<MfaUser>(`${environment.apiUrl}${this.path}/setup/${userId}`, false);
  }
}
