import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'accountType' })
export class AccountType implements PipeTransform {
  transform(input: string): string {
    if (!input) {
      return '';
    } else {
      input = input.replace('_', ' ');

      if (input.endsWith('s')) {
        input = input.slice(0, (input.length - 1));
      }

      return input === 'root' ? 'admin' : input;
    }
  }
}
