<div class="form-container">
  <form class="verify-phone-form" (ngSubmit)="verifyPhone()" #verifyPhoneForm="ngForm">
    <app-logo-link></app-logo-link>
    <p class="message">
      Validate your login
    </p>
    <p>
      Enter the validation code we sent you.
    </p>
    <div class="container">
      <mat-form-field>
       <input matInput name="verificationCode" type="text" required minlength="4" placeholder="Validation Code" [(ngModel)]="verificationCode">
        <mat-icon matPrefix>verified_user</mat-icon>
      </mat-form-field>
      <div>
        <a (click)="resendCode()">
          Resend code
        </a>
      </div>
    </div>
    <button color="primary" type="submit" mat-raised-button [disabled]="!verifyPhoneForm.valid || verifyPhoneForm.pristine">
      Verify Code
    </button>
  </form>
</div>
