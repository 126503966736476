<div class="content">
  <mat-card appearance="raised">
    <div class="title">Access Denied</div>
    <div class="image-container">
      <img [src]="'/assets/images/access-denied.svg' | absUrl">
    </div>
    <div class="body-container">
      <div class="primary-content">
        <p>Sorry, but you do not have the appropriate credentials to access this page.</p>
      </div>
      <div class="secondary-content">
        <p>Contact your account administrator to have permissions added.</p>
      </div>
    </div>
  </mat-card>
</div>
