import { NgModule } from '@angular/core';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { routing } from './access-denied-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    routing,
    SharedModule
  ],
  declarations: [
    AccessDeniedComponent
  ],
  providers: []
})
export class AccessDeniedModule { }
