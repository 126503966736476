<div class="single-account-block-container" [ngClass]="{ 'current-account-container-selected': isActiveAccount || accountTileData.isTileSelected,
'pointer-cursor': accountTileData.isTileSelectable }">
  <button *ngIf="!!accountTileData.toggleSwitch" mat-icon-button class="toggle-button" (click)="handleToggleClick(accountTileData)">
    <mat-icon *ngIf="accountTileData.toggleSwitch?.state" class="star-icon">star</mat-icon>
    <mat-icon *ngIf="!accountTileData.toggleSwitch?.state" class="star-border-icon">star_border</mat-icon>
  </button>
  <div class="single-account-container" (click)="handleAccountSwitchClick(accountTileData)">
    <div class="available-account-name">
      {{ accountTileData.entityName }}
    </div>
    <div class="available-account-info">
      <div class="available-account-network">
        <mat-icon class="network-entity-group-icon" [svgIcon]="accountTileData.entityNetwork"></mat-icon>
      </div>
      <div class="available-account-type">
        <mat-icon class="network-entity-group-icon" [svgIcon]="accountTileData.entityGroupName"></mat-icon> &nbsp; {{accountTileData.classicId}}
      </div>
    </div>
  </div>
  <div class="available-account-arrow" *ngIf="!(isActiveAccount || accountTileData.isTileSelected)">
    <mat-icon>arrow_forward</mat-icon>
  </div>
</div>