import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {CookieModule, CookieService} from 'ngx-cookie';
import { QuillModule } from 'ngx-quill';
import {MAT_RIPPLE_GLOBAL_OPTIONS} from '@angular/material/core';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './not-found.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { HeaderModule } from './header/header.module';
import { AccessDeniedModule } from './access-denied/access-denied.module';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { CookieUtility } from './cookie-utility';
import { globalRippleConfig } from './ripple-utility';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { UnsubscribeReportModule } from './unsubscribe-report/unsubscribe-report.module';
import { ReportApiModule } from './api-services/report-api/report-api.module';
import { CurrencyPipe, DatePipe, PercentPipe, TitleCasePipe } from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {LoginModule} from './login/login.module';

@NgModule({ declarations: [
        AppComponent,
        NotFoundComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        LoginModule,
        HeaderModule,
        AccessDeniedModule,
        ApiModule,
        DashboardModule,
        UnsubscribeReportModule,
        ReportApiModule,
        AppRoutingModule,
        QuillModule.forRoot({
            modules: {
                syntax: false
            }
        }),
        CookieModule.withOptions(CookieUtility.cookieOptionsProviderFactory()),
        SharedModule], providers: [
        CookieService,
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig
        },
        CurrencyPipe, DatePipe, TitleCasePipe, PercentPipe,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
