import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginContainerComponent } from './login-container/login-container.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetupPhoneComponent } from './setup-phone/setup-phone.component';
import { VerifyPhoneComponent } from './verify-phone/verify-phone.component';
import { RegisterDeviceComponent } from './register-device/register-device.component';
import { VerifyDeviceComponent } from './verify-device/verify-device.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginContainerComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'reset-password/:emailAddress', component: ResetPasswordComponent },
      { path: 'setup-phone', component: SetupPhoneComponent },
      { path: 'verify-phone', component: VerifyPhoneComponent },
      { path: 'register-device', component: RegisterDeviceComponent },
      { path: 'verify-device', component: VerifyDeviceComponent },
      // Previous Url Redirects.
      { path: 'forgotpassword', redirectTo: 'forgot-password' },
      { path: 'forgot_password', redirectTo: 'forgot-password' },
      { path: 'resetpassword', redirectTo: 'reset-password' },
      { path: 'reset_password', redirectTo: 'reset-password' },
      { path: 'setupphone', redirectTo: 'setup-phone' },
      { path: 'setup_phone', redirectTo: 'setup-phone' },
      { path: 'verifyphone', redirectTo: 'verify-phone' },
      { path: 'verify_phone', redirectTo: 'verify-phone' },
      { path: 'registerdevice', redirectTo: 'register-device' },
      { path: 'register_device', redirectTo: 'register-device' },
      { path: 'verifydevice', redirectTo: 'verify-device' },
      { path: 'verify_device', redirectTo: 'verify-device' },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule
  ]
})

export class LoginRoutingModule {}
