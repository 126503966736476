import {addYears} from 'date-fns';
import { CookieOptions } from 'ngx-cookie';
import { environment } from '../environments/environment';

export class CookieUtility {
  public static cookieOptionsProviderFactory() {
    let nextYear = addYears(new Date(), 1);
    let cookieDomainLevels = new URL(environment.dashboardUrl).hostname.split('.');
    let max2CookieDomainLevels = cookieDomainLevels.slice(Math.max(cookieDomainLevels.length - 2, 0));
    let cookieDomain = max2CookieDomainLevels.join('.');
    return {
      path: '/',
      domain: cookieDomain,
      expires: nextYear
    } as CookieOptions;
  }
}
