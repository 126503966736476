import {interval, first, skipWhile} from 'rxjs';
import {ApplicationRef, Injectable, NgZone} from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogState} from '@angular/material/dialog';
import {ApiService} from '../../api/api.service';
import {ConfirmationComponent} from '../../confirmation/confirmation.component';
import { HttpErrorResponse } from '@angular/common/http';
import {DashboardHttpService} from '../../core-services/dashboard-http-service/dashboard-http.service';
import {AuthService} from '../../core/services/auth.service';
import {addSeconds, isAfter, subSeconds} from 'date-fns';
import {LoginApiService} from '../../core/services/api/login-api.service';


@Injectable()
export class SessionTimeoutService {
  public sessionTimeoutLeft: number;
  public sessionTimeoutDate: Date;
  private dialogRef: MatDialogRef<ConfirmationComponent>;

  private sessionCountDownInterval: number = null;

  private readonly popupText = 'You have been inactive for some time and will be logged out in ';

  constructor(
    public dialog: MatDialog,
    private ngZone: NgZone,
    private apiService: ApiService,
    private dashboardHttpService: DashboardHttpService,
    private authService: AuthService,
    private applicationRef: ApplicationRef,
    private loginApiService: LoginApiService
  ) {
  }

  public startSessionTimeOut() {
    if (this.sessionCountDownInterval) {
      clearInterval(this.sessionCountDownInterval);
    }

    this.loginApiService.getTimeLeftInSession().subscribe({
      next: (initialTimeoutTimeResponse) => {
        this.setSessionExpirationTimeStamp(initialTimeoutTimeResponse.seconds_to_expiration);
        this.sessionTimeoutLeft = initialTimeoutTimeResponse.seconds_to_expiration;

        this.ngZone.runOutsideAngular(() => {
          this.sessionCountDownInterval = window.setInterval(() => {
            if (this.sessionTimeoutLeft <= 1) {
              clearInterval(this.sessionCountDownInterval);
              if (this.dialogRef?.getState() === MatDialogState.OPEN) {
                this.dialogRef.close(null);
                this.applicationRef.tick();
              }
              return;
            }

            this.sessionTimeoutLeft--;
            if (!this.isSessionTimeActive() && this.dialogRef && this.dialogRef.componentInstance) {
              this.dialogRef.componentInstance.content = this.popupText + this.sessionTimeoutLeft + ' seconds.';
              this.applicationRef.tick();
            }
          }, 1000);
        });

        let sessionDialogTrigger$ = interval(1000).pipe(
          skipWhile(val => this.isSessionTimeActive()),
          first());


        this.ngZone.runOutsideAngular(() => {
          sessionDialogTrigger$.subscribe({
            next: () => {
              this.loginApiService.getTimeLeftInSession()
                .subscribe({
                  next: (finalTimeoutTimeResponse) => {
                    this.sessionTimeoutLeft = finalTimeoutTimeResponse.seconds_to_expiration;
                    this.setSessionExpirationTimeStamp(finalTimeoutTimeResponse.seconds_to_expiration);
                    if (!this.isSessionTimeActive()) {
                      this.openSessionTimeoutDialog();
                      this.applicationRef.tick();
                    } else {
                      this.startSessionTimeOut();
                    }
                  },
                  error: (errorResponse: HttpErrorResponse) => {
                    if (errorResponse.status > 0) {
                      this.authService.logout(true);
                    }
                  }
                });
            }
          });
        });
      },
      error: (errorResponse: HttpErrorResponse) => {
        if (errorResponse.status > 0) {
          this.authService.logout(true);
        }
      }
    });
  }

  private openSessionTimeoutDialog() {
    if (this.dialogRef && this.dialogRef.getState() === MatDialogState.OPEN) {
      this.dialogRef.close();
    }

    this.dialogRef = this.dialog.open(ConfirmationComponent, {
      role: 'dialog',
      disableClose: true
    });

    this.dialogRef.componentInstance.title = 'Session Timeout';
    this.dialogRef.componentInstance.content = this.popupText + (this.sessionTimeoutLeft ?? '60') + ' seconds.';
    this.dialogRef.componentInstance.negative_action_text = 'Log out';
    this.dialogRef.componentInstance.positive_action_text = 'Stay logged in';

    this.dialogRef.afterClosed().subscribe({
      next: (result) => {
        clearInterval(this.sessionCountDownInterval);
        // null is passed when the session times out.
        if (result === null) {
          this.loginApiService.getTimeLeftInSession().subscribe({
            next: (token) => {
              if (token.seconds_to_expiration <= 0) {
                this.authService.logout(true);
              } else {
                this.sessionTimeoutLeft = token.seconds_to_expiration;
                this.setSessionExpirationTimeStamp(token.seconds_to_expiration);
                this.startSessionTimeOut();
              }
            },
            error: (errorResponse: HttpErrorResponse) => {
              if (errorResponse.status > 0) {
                this.authService.logout(true);
              }
            }
          });
        }
        // false is passed when user clicks logout button on dialog.
        if (result === false) {
          this.authService.logout(false);
        }
        // true is passed when user click stay logged in button on dialog.
        if (result === true) {
          this.dashboardHttpService.resetSessionTime().subscribe({
            next: (newTokenResponse) => {
              this.apiService.userAuthorization = newTokenResponse;
              this.startSessionTimeOut();
            },
            error: (errorResponse: HttpErrorResponse) => {
              if (errorResponse.status > 0) {
                this.authService.logout();
              }
            }
          });
        }
      }
    });

  }

  private setSessionExpirationTimeStamp(sessionExpirationInSeconds: number) {
    this.sessionTimeoutDate = addSeconds(new Date(), sessionExpirationInSeconds);
  }

  private isSessionTimeActive(): boolean {
    let sessionExpiration = subSeconds(this.sessionTimeoutDate, 60);
    return isAfter(sessionExpiration, new Date());
  }
}
