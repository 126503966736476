<div class="header-content">
  <div *ngIf="recordCount != null && passedReportDataManager != null" class="report-info">
    <div>{{ recordCount }} Record(s)</div>
    <div class="report-currency">
      {{passedReportDataManager.getReportCurrency()}}
    </div>
  </div>
  <div>
    <button mat-icon-button
            (click)="dialog.close()" matTooltip="Minimize">
      <mat-icon class="material-icons">close</mat-icon>
    </button>
  </div>
</div>
<app-data-table-display [reportDataManager]="passedReportDataManager"
                    [componentName]="'dialog'"
                    [sharesReportDataManager]="true"
                    (initialReportResponseReturned)="setReportViewFromTableLibrary()"
                    (reportCountReturned)="setReportCount($event)">
</app-data-table-display>
