/*
 * All functions within the pure-utils directiory should be pure.
 * Pure means the function only uses its parameters for input (no outside state) and has no side effects (no mutation of parameters or outside state).
 */

/**
 * Sort an array out of place by selector value
 */
export function outPlaceSort<T>(theArray: T[], selector: (value: T) => any, ascending = true): T[] {
  // map to selected value and index
  let selectorMapped = theArray.map((x, index) => {
    let value = selector(x);
    if (typeof value === 'string') {
      value = value.toLowerCase();
    }
    return { index, value };
  });


  // sort the mapped array containing the selected values
  selectorMapped.sort((a, b) => {
    if (a.value > b.value) {
      return ascending ? 1 : -1;
    }
    if (a.value < b.value) {
      return ascending ? -1 : 1;
    }
    return 0;
  });

  // return new array with resulting order
  return selectorMapped.map(x => theArray[x.index]);
}

/**
 * return a sequence of numbers in an array
 *
 * @param start inclusive
 * @param end exclusive
 */
export function range(start: number, end: number): number[] {
  let reverse = Math.sign(end - start);
  return Array.from({length: (end - start) * reverse}, (x, i) => i * reverse + start);
}

/**
 * typed dynamic property access, i.e. obj[key] bracket syntax.
 */
export function prop<T, K extends keyof T>(obj: T, key: K) {
  return obj[key];
}

/**
 * constructs a new object by picking the set of properties keys from obj.
 *
 * @param obj object to pick properties from
 * @param keys string array of properties to include in returned object
 */
export function pickProps<T, K extends keyof T>(obj: T, keys: K[]) {
  return keys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, <Pick<T, K>> {});
}
