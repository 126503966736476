import { Injectable } from '@angular/core';
import { Dictionary } from '../../dictionary';
import {RouteInformation} from '../dashboard';

@Injectable()
export class DashboardAllRoutesService extends Dictionary<RouteInformation> {
  constructor() {
    super();
    this.addAllRoutes();
  }

  private addAllRoutes() {
    this.Add('', { isRouteDashboard: false,
      routeUrl: '/'
    });
    this.Add('Affiliates', { isRouteDashboard: false,
      routeUrl: '/affiliates.php'
    });
    this.Add('Merchants', { isRouteDashboard: false,
      routeUrl: '/merchants.php'
    });
    this.Add('Tools', { isRouteDashboard: false,
      routeUrl: '/tools.php'
    });
    this.Add('Ad Tools', { isRouteDashboard: true,
      routeUrl: '/ad-tools'
    });
    this.Add('Account', { isRouteDashboard: false,
      routeUrl: '/account.php'
    });
    this.Add('Reports', { isRouteDashboard: false,
      routeUrl: '/reports.php'
    });
    this.Add('Performance', { isRouteDashboard: true,
      routeUrl: '/report/performance'
    });
    this.Add('AvantMetrics', { isRouteDashboard: true,
      routeUrl: '/report/avantmetrics'
    });
    this.Add('Settings', { isRouteDashboard: true,
      routeUrl: '/user-settings/profile'
    });
    this.Add('Login', { isRouteDashboard: true,
      routeUrl: '/login'
    });
    this.Add('Access Denied', { isRouteDashboard: true,
      routeUrl: '/access-denied'
    });
    this.Add('WithdrawNow', { isRouteDashboard: false,
      routeUrl: '/withdraw_now.php'
    });
    this.Add('ViewInvoiceList', { isRouteDashboard: false,
      routeUrl: '/view_invoice_list.php'
    });
    this.Add('EasyLink', { isRouteDashboard: true, routeUrl: '/ad-tools/easy-link'});
    this.Add('ProductLink', { isRouteDashboard: true, routeUrl: '/ad-tools/product-link'});
    this.Add('QRCode', { isRouteDashboard: true, routeUrl: '/ad-tools/qr-code'});
    this.Add('CustomLinkBuilderAffiliate', { isRouteDashboard: true, routeUrl: '/ad-tools/custom-link-builder/affiliate'});
    this.Add('CustomLinkBuilderMerchant', { isRouteDashboard: true, routeUrl: '/ad-tools/custom-link-builder/merchant'});
    this.Add('CustomLinkBuilder', { isRouteDashboard: true, routeUrl: '/ad-tools/custom-link-builder'});
    this.Add('MerchantAds', { isRouteDashboard: true, routeUrl: '/ad-tools/ad-management' });
    this.Add('SnapLinker', { isRouteDashboard: false, routeUrl: '/application.php?lngExtensionId=1019' });
    this.Add('AffiliateLinkEncoder', { isRouteDashboard: false, routeUrl: '/affiliate_link_encoder.php' });
    this.Add('AffiliateLinkEncoderEdit', { isRouteDashboard: false, routeUrl: '/affiliate_link_encoder_list.php' });
    this.Add('DatafeedManager', { isRouteDashboard: false, routeUrl: '/configure_datafeed.php?strIntegrationMethod=file&blnSignup=1' });
    this.Add('DatafeedAdministration', { isRouteDashboard: false, routeUrl: '/datafeed_list.php' });
    this.Add('DatafeedManagerEdit', { isRouteDashboard: false, routeUrl: '/edit_services.php?Method=file' });
    this.Add('PaidPlacement', { isRouteDashboard: false, routeUrl: '/ppb_configure.php' });
    this.Add('PaidPlacementMerchant', { isRouteDashboard: false, routeUrl: '/ppb.php' });
    this.Add('PaidPlacementEdit', { isRouteDashboard: false, routeUrl: '/ppb_list.php' });
    this.Add('DynamicDealFeed', { isRouteDashboard: false, routeUrl: '/coupon_feed_signup.php' });
    this.Add('DynamicDealFeedEdit', { isRouteDashboard: false, routeUrl: '/coupon_feed_edit.php' });
    this.Add('ImmerxonVideo', { isRouteDashboard: false, routeUrl: '/application.php?lngExtensionId=1037' });
    this.Add('DealOfTheDay', { isRouteDashboard: false, routeUrl: '/dotd_signup.php' });
    this.Add('ManageDealOfTheDay', { isRouteDashboard: false, routeUrl: '/dotd_list.php' });
    this.Add('ProductAdWidget', { isRouteDashboard: false, routeUrl: '/product_ad_widget.php' });
    this.Add('ProductAdWidgetEdit', { isRouteDashboard: false, routeUrl: '/paw_edit.php' });
    this.Add('APIRequestBuilder', { isRouteDashboard: false, routeUrl: '/api_request_builder.php' });
    this.Add('SnapVisi', { isRouteDashboard: false, routeUrl: '/application.php?lngExtensionId=1025' });
    this.Add('SnapSearcher', { isRouteDashboard: false, routeUrl: '/application.php?lngExtensionId=1021' });
    this.Add('SnapDealist', { isRouteDashboard: false, routeUrl: '/application.php?lngExtensionId=1033' });
    this.Add('ProductContentWidget', { isRouteDashboard: false, routeUrl: '/pcw.php' });
    this.Add('ProductContentWidgetEdit', { isRouteDashboard: false, routeUrl: '/pcw_list.php' });
    this.Add('ContextualAdTool', { isRouteDashboard: false, routeUrl: '/contextual_ad.php' });
    this.Add('AffiliateDashboard', { isRouteDashboard: true, routeUrl: '/dashboard/affiliate' });
    this.Add('MerchantDashboard', { isRouteDashboard: true, routeUrl: '/dashboard/merchant' });
    this.Add('KeyAccountsDashboard', { isRouteDashboard: true, routeUrl: '/dashboard/key-accounts' });
    this.Add('AdminDashboard', { isRouteDashboard: true, routeUrl: '/dashboard/admin'});
    this.Add('ClassicAffiliateDashboard', {isRouteDashboard: false, routeUrl: '/'});
    this.Add('ClassicMerchantDashboard', {isRouteDashboard: false, routeUrl: '/'});
    this.Add('ClassicAdminDashboard', {isRouteDashboard: false, routeUrl: '/'});
  }
}
