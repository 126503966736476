import {ActivatedRouteSnapshot, Router} from '@angular/router';
import {inject} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {first, map} from 'rxjs/operators';
import {DashboardApiService} from '../api/dashboard-api.service';
import {RoutingService} from '../routing.service';
import {
  DashboardPermissionsService
} from '../../../dashboard/dashboard-permissions-service/dashboard-permissions.service';
import {AuthGuardData, EntityGroupGuardData} from './guards';
import {LoginApiService} from '../api/login-api.service';
import {DashboardHttpService} from '../../../core-services/dashboard-http-service/dashboard-http.service';

export const featureFlagGuard = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  if (environment.featuresFlags[route.data?.featureFlag]) {
    return true;
  }
  return router.parseUrl('/access-denied');
};

export const entityGroupGuard = (route: ActivatedRouteSnapshot) => {
  const validEntityGroups = (<EntityGroupGuardData>route.data).validEntityGroups;
  const dashboardApiService = inject(DashboardApiService);
  const dashboardRoutingService = inject(RoutingService);
  return dashboardApiService.currentAccount$.pipe(
    first(),
    map(currentAccount => {
      if (validEntityGroups.some(x => x === currentAccount.entity_group_name)) {
        return true;
      } else {
        dashboardRoutingService.directToDashboard();
        return false;
      }
    }));
};

export const agencyGuard = () => {
  const dashboardApiService = inject(DashboardApiService);
  const routingService = inject(RoutingService);
  return dashboardApiService.availableAccounts$.pipe(
    first(),
    map((availableAccounts) => {
      if (availableAccounts.some(singleAccount => singleAccount.entity_group_name === 'merchant_groups' || singleAccount.entity_group_name === 'root') ||
        availableAccounts.filter(singleAccount => singleAccount.entity_group_name === 'merchants').length >= 2) {
        return true;
      } else {
        routingService.directToDashboard();
        return false;
      }
    })
  );
};

export const authGuard = (route: ActivatedRouteSnapshot) => {
  const dashboardPermissionsService = inject(DashboardPermissionsService);
  const router = inject(Router);
  const checkPermission = (<AuthGuardData>route.data)?.checkPermission ?? [];
  return dashboardPermissionsService.accountWithPermissions$.pipe(
    map(([currentAccount, permissions]) => {
      if (checkPermission instanceof Array) {
        let hasPermission;
        let multiplePermissionOperator = route.data?.multiplePermissionOperator ?? 'OR';
        if (multiplePermissionOperator === 'OR') {
          hasPermission = checkPermission
            .some((singleCheckPermission) => !!permissions.find((singlePermission) => singlePermission.permission_name === singleCheckPermission)?.has_permission);
        } else {
          hasPermission = checkPermission
            .every((singleCheckPermission) => !!permissions.find((singlePermission) => singlePermission.permission_name === singleCheckPermission)?.has_permission);
        }
        if (!hasPermission) {
          if (environment.isClassic) {
            window.location.href = `${environment.dashboardUrl}/access-denied`;
          } else {
            router.navigate(['/access-denied']);
          }
        }
        return hasPermission;
      } else {
        return checkPermission(permissions, currentAccount, router);
      }
    }),
    first()
  );
};

export const logoutGuard = () => {
  const loginApiService = inject(LoginApiService);
  const dashboardHttpService = inject(DashboardHttpService);
  loginApiService.logout().pipe(first()).subscribe({
    next: () => {
      dashboardHttpService.removeAuthAndFullNavLogout(false);
      return false;
    },
    error: () => {
      dashboardHttpService.removeAuthAndFullNavLogout(false);
      return false;
    }
  });
};
