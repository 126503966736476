function getSumOfObjectProps(object: { [key: number]: number }): number {
  let returnTotal = 0;
  for (let prop in object) {
    if (object.hasOwnProperty(prop)) {
      returnTotal += object[prop];
    }
  }

  return returnTotal;
}

function getAverageOfObjectProps(object: { [key: number]: number }) {
  let total = getSumOfObjectProps(object);
  let average = 0;
  if (object && isFinite(Math.floor(total / Object.keys(object).length))) {
    average = Math.floor(total / Object.keys(object).length);
  }

  return average;
}

export { getAverageOfObjectProps, getSumOfObjectProps };
