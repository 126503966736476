import { Injectable } from '@angular/core';
import { catchError, map, tap, switchMap, finalize, mergeMap, shareReplay, distinctUntilChanged } from 'rxjs/operators';
import { Observable, of, throwError, interval, pipe, OperatorFunction, defer } from 'rxjs';
import { ApiService } from '../../api/api.service';
import { DashboardHttpService } from '../../core-services/dashboard-http-service/dashboard-http.service';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ReportApiModule } from './report-api.module';
import { RequestTrackerService } from '../../core-services/request-tracker-service/request-tracker.service';
import { DashboardApiService } from '../../core/services/api/dashboard-api.service';
import {
  AnonymousReportRequest, CreateSubscriptionRequest, CreateSubscriptionResponse, DeleteSubscriptionResponse,
  DisplayGroup, ListFrequenciesResponse, ListSubscriptionResponse, ReportColumn, ReportDisplayGroup, ReportJSONDataResponse, ReportJSONResponse,
  ReportListColumnsResponse,
  ReportListResponse,
  ReportStatusFinished,
  ReportStatusNotFinished, SavedReportRequest, SavedReportResponse,
  SingleReportRequest, UnsubscribeReportResponse, UpdateSubscriptionRequest, UpdateSubscriptionResponse
} from '../../core/dtos/api';
import {RequestObjContainer} from 'picker-sdk';
import {NestedMenuItem} from '../../nested-menu/nested-menu';

@Injectable({
  providedIn: ReportApiModule,
})
export class ReportApiService {
  private path = '/picker';

  readonly availableColumns$ = this.dashboardApiService.currentAccount$.pipe(
    distinctUntilChanged((x, y) => x.entity_group_name === y.entity_group_name),
    switchMap(() => this.getAvailableColumns()),
    shareReplay(1),
    map(x => <typeof x> JSON.parse(JSON.stringify(x))), // clone here because the response object is being mutated somewhere
  );

  constructor(
    private http: DashboardHttpService,
    private apiService: ApiService,
    private dashboardApiService: DashboardApiService,
    public requestTracker: RequestTrackerService
  ) { }

  public preparedColumns(displayGroup: DisplayGroup) {
    return this.availableColumns$.pipe(
      map(availableColumns => ({
        dims: availableColumns.dims.filter(x => x.display_groups.includes(displayGroup)),
        facts: availableColumns.facts.filter(x => x.display_groups.includes(displayGroup))
      })),
      shareReplay({bufferSize: 1, refCount: true})
    );
  }

  public getReport<T>(reportRequest?: SingleReportRequest, currency = 'USD', trackRequest = true): Observable<T> {
    return of([reportRequest, currency]).pipe(
      tap(() => {
        if (trackRequest) {
          this.requestTracker.affiliateSnapshotRequestTracker$.next(this.requestTracker.affiliateSnapshotRequestTracker$.value + 1);
        }
      }),
      mergeMap(([localReportRequest, localCurrency]) => {
        let requestUrl = `${environment.apiUrl}${this.path}/report/custom/json?currency=${localCurrency}`;
        return this.http.post<SingleReportRequest | { report_query: string }, T>(
          requestUrl, (localReportRequest ? {report_query: JSON.stringify(localReportRequest)} : {})).pipe(
          this.getReportStatusPipe<T>(2000));
      }),
      finalize(() => {
        if (trackRequest) {
          this.requestTracker.affiliateSnapshotRequestTracker$.next(this.requestTracker.affiliateSnapshotRequestTracker$.value - 1);
        }
      })
    );
  }

  public getCustomReport<T>(reportRequest: SingleReportRequest | RequestObjContainer, requestUrl: string): Observable<T> {
    return this.http.post<SingleReportRequest | {report_query: string}, T>(
      requestUrl, {report_query: JSON.stringify(reportRequest)});
  }

  private getAvailableColumns(): Observable<ReportListColumnsResponse> {
    return this.http.get<ReportListColumnsResponse>(environment.apiUrl + this.path + '/columns/prepared');
  }

  public getAvailableReports(): Observable<ReportListResponse[]> {
    return this.http.get<ReportListResponse[]>(environment.apiUrl + this.path + '/report');
  }

  public getReportStatus(requestId: string):
    Observable<ReportStatusFinished | ReportStatusNotFinished> {
    return this.http.get<ReportStatusFinished | ReportStatusNotFinished>
    (`${environment.apiUrl}${this.path}/report/${requestId}/status`, false);
  }

  public getReportByUrl<T>(reportUrl: string): Observable<T> {
    return this.http.get<T>(reportUrl, true, new HttpHeaders());
  }

  public getReportRequestById(id: string): Observable<SingleReportRequest> {
    return this.http.get<SingleReportRequest>(`${environment.apiUrl}${this.path}/report/${id}/request`);
  }

  public saveReportRequest(
    reportQuery: AnonymousReportRequest,
    reportName: string,
    displayGroup: ReportDisplayGroup,
    reportDescription?: string,
    canOverride = true,
    visibleTo?: string[],
    availableTo?: string[],
    anonymous?: boolean,
  ): Observable<SavedReportResponse> {
    if (reportName.length > 500) {
      return throwError(() => ({error: {error: 'Report name cannot be longer than 500 characters.'}}));
    }
    let body = {
      report_query: JSON.stringify(reportQuery),
      report_name: reportName,
      report_description: reportDescription,
      can_override: canOverride,
      visible_to: visibleTo ? visibleTo.join() : null,
      available_to: availableTo ? availableTo.join() : null,
      anonymous,
      display_group: displayGroup,
    };
    return this.http.post<SavedReportRequest, SavedReportResponse>
    (`${environment.apiUrl}/picker/report`, body);
  }

  public updateReportRequest(
    reportId: string,
    reportQuery: SingleReportRequest,
    reportName?: string,
    reportDescription?: string,
    canOverride = true,
    visibleTo?: string[],
    availableTo?: string[]
  ): Observable<SavedReportResponse> {
    if (reportName.length > 500) {
      return throwError(() => ({error: {error: 'Report name cannot be longer than 500 characters.'}}));
    }
    let body = {
      report_query: JSON.stringify(reportQuery),
      report_name: reportName,
      report_description: reportDescription,
      can_override: canOverride,
      visible_to: visibleTo ? visibleTo.join() : null,
      available_to: availableTo ? availableTo.join() : null,
    };
    return this.http.put<SavedReportRequest, SavedReportResponse>
    (`${environment.apiUrl}/picker/report/${reportId}`, body);
  }

  public deleteReportRequest(reportId: string): Observable<SavedReportResponse> {
    return this.http.delete<SavedReportResponse>(`${environment.apiUrl}/picker/report/${reportId}`);
  }

  public getSubscriptionFrequencies(): Observable<ListFrequenciesResponse> {
    return this.http.get<ListFrequenciesResponse>(`${environment.apiUrl}/subscription/frequencies`);
  }

  public getUserReportSubscriptions(userId: string, reportId: string): Observable<ListSubscriptionResponse> {
    return this.http.get<ListSubscriptionResponse>(
      `${environment.apiUrl}/subscription/${userId}/reports/${reportId}`)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response.status === 404) {
            return of([]);
          } else {
            return throwError(() => response);
          }
        })
      );
  }

  public createReportSubscription(
    userId: string,
    reportId: string,
    body: CreateSubscriptionRequest
  ): Observable<CreateSubscriptionResponse> {
    return this.http.post<CreateSubscriptionRequest, CreateSubscriptionResponse>(
      `${environment.apiUrl}/subscription/${userId}/reports/${reportId}`, body);
  }

  public updateReportSubscription(
    userId: string,
    reportId: string,
    reportSubscriptionId: string,
    body: UpdateSubscriptionRequest
  ): Observable<UpdateSubscriptionResponse> {
    return this.http.put<UpdateSubscriptionRequest, UpdateSubscriptionResponse>(
      `${environment.apiUrl}/subscription/${userId}/reports/${reportId}/${reportSubscriptionId}`, body);
  }

  public deleteReportSubscription(userId: string, reportId: string, reportSubscriptionId: string): Observable<DeleteSubscriptionResponse> {
    return this.http.delete<DeleteSubscriptionResponse>(
      `${environment.apiUrl}/subscription/${userId}/reports/${reportId}/${reportSubscriptionId}`);
  }

  public unsubscribeReport(userId: string, reportId: string, reportSubscriptionId: string, authorizationToken: string):
    Observable<UnsubscribeReportResponse> {
    let authorizationTitle = 'authorization';
    this.apiService.removeHeader(authorizationTitle);
    this.apiService.appendHeader(authorizationTitle, authorizationToken);
    return this.http.delete<UnsubscribeReportResponse>(environment.apiUrl + `/subscription/${userId}/reports/${reportId}/${reportSubscriptionId}`)
      .pipe(
        tap(() => {
          this.apiService.refreshHeaders();
        })
      );
  }

  private getReportStatusPipe<T>(intervalSeconds: number): OperatorFunction<any, any> {
    return pipe(
      switchMap((response) => {
        let reportName = Object.keys(response['_request'])[0];
        if (response[reportName].request_id) {
          return new Observable<ReportJSONResponse>((observer) => {
            let int = interval(intervalSeconds).subscribe((tick) => {
              this.getReportStatus(response[reportName].request_id).subscribe((status) => {
                if (!!status['file']) {
                  int.unsubscribe();
                  this.getReportByUrl<ReportJSONDataResponse>(status['file']).subscribe((res) => {
                    let returnVal = { _request: response['_request'], [reportName]: res };
                    observer.next(returnVal);
                    observer.complete();
                  });
                }
              }, (error: HttpErrorResponse) => {
                observer.error(error);
              });
            });
            return () => {
              int.unsubscribe();
            };
          });
        } else {
          return of(response);
        }
      })
    );
  }

  public dimensionSelectOptions(preparedColumns: { dims: ReportColumn[]; facts: ReportColumn[] }) {
    let grouped = this.groupedAvailableColumns(preparedColumns.facts);
    Object.values(grouped).forEach(val => val.sort((a, b) => a.name.localeCompare(b.name)));
    return this.toNestedMenuItems(grouped);
  }

  private groupedAvailableColumns(columns: ReportColumn[]): {[key: string]: ReportColumn[]} {
    return columns.reduce((acc, current) => {
      if (acc[current.group] == null) {
        acc[current.group] = [current];
      } else {
        acc[current.group].push(current);
      }

      return acc;
    }, <{[key: string]: ReportColumn[]}> {});
  }

  private toNestedMenuItems(groupedCols: {[key: string]: ReportColumn[]}): NestedMenuItem[] {
    let menu = Object.keys(groupedCols).map(key => <NestedMenuItem> {
        label: key,
        children: groupedCols[key].map(x => <NestedMenuItem> {
            label: x.name,
            value: x.prepared_column_id
          })
      });

    return menu;
  }
}
