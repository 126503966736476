import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import {HttpRequestOptions} from '../dashboard/dashboard';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class ApiService {
  // eslint-disable-next-line max-len
  public dashboardAuthorization = 'ecbd33850ec41b6f41700db1575f5adb75db3a48;403f2c5c7b754d94b95752f23b4e380d4b994e55;1478209357;0067300c-5389-4158-abb7-5e701888b098;P100Y';
  public versionHeaderName = 'X-API-Version';
  public userLocaleHeaderName = 'X-API-Locale';
  public userLocale = 'en_US';
  private userAuthorizationBacking: string;
  private requestOptionsBacking: HttpRequestOptions;

  public readonly authorizationHeaderName = 'authorization';
  public readonly authorizationRoleName = 'authorization_role';
  public readonly deviceHeaderName = 'userDeviceId';
  public readonly version = '2.0';

  constructor(private cookieService: CookieService) {
    this.initializeRequestOptions();
    this.initializeExistingAuthorization();
  }

  public set userAuthorization(authorization: string) {
    this.userAuthorizationBacking = authorization;
    this.setAuthorizationCookie(authorization);
    this.refreshHeaders();
  }

  public get authorization() {
    return this.cookieService.get(this.authorizationHeaderName) || this.dashboardAuthorization;
  }

  public get requestOptions() {
    return this.requestOptionsBacking;
  }

  public get hasUserAuthorization() {
    return !!this.userAuthorizationBacking;
  }

  public refreshHeaders() {
    this.requestOptionsBacking.headers = this.requestOptionsBacking.headers.set(
      this.authorizationHeaderName,
      this.authorization
    );

    this.requestOptionsBacking.headers = this.requestOptionsBacking.headers.set(
      this.versionHeaderName,
      this.version
    );
  }

  public appendHeader(headerName: string, headerValue: string) {
    if (this.requestOptionsBacking.headers) {
      this.requestOptionsBacking.headers = this.requestOptionsBacking.headers.append(headerName, headerValue);
    }
  }

  public removeHeader(headerName: string) {
    if (this.requestOptionsBacking.headers.has(headerName)) {
      this.requestOptionsBacking.headers = this.requestOptionsBacking.headers.delete(headerName);
    }
  }

  private initializeExistingAuthorization() {
    let authorization = this.cookieService.get(this.authorizationHeaderName);

    if (authorization) {
      this.userAuthorization = authorization;
    }
  }

  private initializeRequestOptions() {
    let headers = {};
    headers[this.authorizationHeaderName] = this.authorization;
    headers[this.versionHeaderName] = this.version;
    headers[this.userLocaleHeaderName] = this.userLocale;

    this.requestOptionsBacking = {
      headers: new HttpHeaders(headers),
    };
  }

  private setAuthorizationCookie(authorization: string) {
    this.cookieService.put(this.authorizationHeaderName, authorization);
  }

  public setAuthorizationRole(status: string) {
    this.cookieService.put(this.authorizationRoleName, status);
  }

  public getAuthorizationRole() {
    return this.cookieService.get(this.authorizationRoleName);
  }
}
