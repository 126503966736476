import {DashboardCurrency} from '../dashboard';
import {PermissionsRequest} from '../../core/dtos/api';

export class DashboardFixtures {
  public static getPermissionsQueryObjects(): PermissionsRequest[] {
    // 'user_id', 'entity_id', and 'dynamic_entity_type' are dynamically populated when permissions are requested.
    let permissionRequestObjects: PermissionsRequest[] =
      [
        {
          entity_id: 'user_id',
          entity_type: 'users',
          permission_name: 'picker'
        },
        {
          entity_id: 'user_id',
          entity_type: 'users',
          permission_name: 'entity_account_settings'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'create_users'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'root',
          permission_name: 'root'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'entity_reports'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'configure_tools'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'view_reports'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'manage_merchant_relationships'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'manage_payout_methods'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'merchant_read'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'affiliate_read'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'merchant_access'
        },
        {
          entity_id: 'entity_id',
          entity_type: 'dynamic_entity_type',
          permission_name: 'merchant_access'
        }
      ];
    return permissionRequestObjects;
  }

  public static getAvailableCurrencies(): {[key: string]: DashboardCurrency} {
    return {
      'be73e502-85fd-11e8-ba63-0edee6d0e1d0': {
        id: 'be73e502-85fd-11e8-ba63-0edee6d0e1d0',
        name: 'AUD'
      },
      'be9f255a-85fd-11e8-ba64-0edee6d0e1d0': {
        id: 'be9f255a-85fd-11e8-ba64-0edee6d0e1d0',
        name: 'CAD'
      },
      'be487b38-85fd-11e8-ba62-0edee6d0e1d0': {
        id: 'be487b38-85fd-11e8-ba62-0edee6d0e1d0',
        name: 'USD'
      },
    };
  }
}
