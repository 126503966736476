export class Greeting {
  start: Date;
  end: Date;
  message: string;

  constructor(startHour: number, endHour: number, message: string) {
    let startDate = new Date();
    startDate.setHours(startHour, 0, 0,0);
    this.start = startDate;

    let endDate = new Date();
    endDate.setHours(endHour, 59, 59, 999);
    this.end = endDate;

    this.message = message;
  }
}
