import {
  Paged, Affiliate, SearchAffiliateWebsite, UpdateAffiliateRequest, AffiliateContact, LoginAccounts, AssociatedMerchantCounts,
  RelationshipStatus, AffiliateRelationshipMerchant, AffiliateWebsite, AffiliateRelationshipSearchMerchant, MerchantAd,
  AffiliateAdRequest, AffiliateClassification
} from '../../dtos/api';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Network, CreateAffiliateLinkRequest, CreateAffiliateLinkResponse } from '../../dtos/api';
import { environment } from '../../../../environments/environment';
import { DashboardHttpService } from '../../../core-services/dashboard-http-service/dashboard-http.service';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { GetOptions } from '../../../core-services/models';
import { deepMutateIsoToMtTime } from '../../pure-utils/string-utils';

type AffiliateRelationshipSearchMerchantUnion = AffiliateRelationshipSearchMerchant[] | AffiliateRelationshipSearchMerchant;

@Injectable({
  providedIn: 'root',
})
export class AffiliateApiService {
  private path = '/affiliates';

  constructor(private http: DashboardHttpService) { }

  public getAccountBalance(affiliateId: string) {
    return this.http.get<{available_balance: number}>(environment.apiUrl + this.path + '/' + affiliateId + '/account/balance');
  }

  public getAffiliates(params: {
    sort: string;
    direction: 'ASC' | 'DESC';
    limit: string;
    status: 'active' | 'denied';
    network: Network;
  }) {
    return this.http.getWithOptions<Paged<Affiliate>>(environment.apiUrl + this.path, { params });
  }

  public getAffiliateWebsites(affiliateId: string, isActive: boolean) {
    return this.http.get<AffiliateWebsite[]>(environment.apiUrl + this.path + '/' + affiliateId + '/websites?is_active=' + isActive);
  }

  public fetchAffiliateWebsites(affiliateId: string, options = { params: { is_active: 'true' } }) {
    return this.http.getWithOptions<AffiliateWebsite[]>(`${environment.apiUrl}/affiliates/${affiliateId}/websites`, options);
  }

  /**
   * @param affiliateWebsiteUrl The affiliate website url to search for.
   * @param isDefault Only search through affiliate default websites.
   */
  public searchAffiliateWebsites(affiliateWebsiteUrl?: string, isDefault = true) {
    let encodedAffiliateWebsiteUrl = encodeURIComponent(affiliateWebsiteUrl);
    return this.http.get<SearchAffiliateWebsite[]>
      (`${environment.apiUrl}/affiliates/websites/search?affiliate_website_url=${encodedAffiliateWebsiteUrl}&is_default=${isDefault}`);
  }

  public getAssociatedMerchantUrlSearch(affiliateId: string, merchantWebsiteUrl: string, relationshipStatus: string) {
    return this.http.get<AffiliateRelationshipSearchMerchantUnion>
      (environment.apiUrl + this.path + '/' + affiliateId + '/relationships/search?merchant_website_url=' +
        merchantWebsiteUrl + '&relationship_status=' + relationshipStatus);
  }

  public getAffiliateUsers(affiliateId: string) {
    let requestUrl = `${environment.apiUrl}${this.path}/${affiliateId}/account/users`;
    return this.http.get<LoginAccounts[]>(requestUrl, false);
  }

  public getAffiliate(affiliateId: string) {
    return this.http.get<Affiliate>(`${environment.apiUrl}${this.path}/${affiliateId}`);
  }

  public updateAffiliate(affiliateId: string, updateAffiliateRequest: UpdateAffiliateRequest) {
    return this.http.put<UpdateAffiliateRequest, Affiliate>(`${environment.apiUrl}${this.path}/${affiliateId}`, updateAffiliateRequest);
  }

  public updateAffiliateContact(affiliateId: string, affiliateContact: AffiliateContact) {
    return this.http.put<AffiliateContact, AffiliateContact>(`${environment.apiUrl}${this.path}/${affiliateId}/contacts`, affiliateContact);
  }

  public getAssociatedMerchantsByEntityIds(entityIds: string[], relationshipStatus?: RelationshipStatus,
    isDefaultMerchantWebsite?: boolean, page?: number, limit?: number) {
    if (entityIds.length === 0) {
      return of<Paged<AffiliateRelationshipMerchant>>({
        items: [],
        items_per_page: limit,
        page: 1,
        total_items: 0
      });
    }

    let slug = `affiliates/${entityIds.join(',')}/relationships?`;
    let urlParams: string[] = [];
    if (!!relationshipStatus) {
      urlParams.push(`relationship_status=${relationshipStatus}`);
    }
    if (isDefaultMerchantWebsite !== undefined) {
      urlParams.push(`is_default_merchant_website=${isDefaultMerchantWebsite}`);
    }
    if (!!page) {
      urlParams.push(`page=${page}`);
    }
    if (!!limit) {
      urlParams.push(`limit=${limit}`);
    }
    return this.http.get<Paged<AffiliateRelationshipMerchant>>(`${environment.apiUrl}/${slug}${urlParams.join('&')}`);
  }

  public getAssociatedMerchantCountsByEntityIds(entityIds: string[]) {
    if (entityIds.length === 0) {
      return of<AssociatedMerchantCounts> ({
        active: 0,
        deactivated: 0,
        denied: 0,
        offer: 0,
        pending: 0
      });
    }

    let slug = `affiliates/${entityIds.join(',')}/relationships/counts`;
    return this.http.get<AssociatedMerchantCounts>(`${environment.apiUrl}/${slug}`);
  }

  public getAllAssociatedMerchantWebsitesOfAffiliate(affiliateIds: string[], relationshipStatus?: RelationshipStatus,
    isDefaultMerchantWebsite?: boolean): Observable<AffiliateRelationshipMerchant[]> {
    return this.getAssociatedMerchantsByEntityIds(affiliateIds, relationshipStatus, isDefaultMerchantWebsite, 1, 1000).pipe(
      switchMap(initialResponse => {
        let total_pages = Math.ceil(initialResponse.total_items / initialResponse.items_per_page);
        let requests: Observable<Paged<AffiliateRelationshipMerchant>>[] = [];
        for (let i = 2; i <= total_pages; i++) {
          requests.push(this.getAssociatedMerchantsByEntityIds(affiliateIds, relationshipStatus, isDefaultMerchantWebsite, i, 1000));
        }

        if (requests.length > 0) {
          return forkJoin(requests).pipe(
            map(forkResponse =>  initialResponse.items.concat(...forkResponse.map(x => x.items))));
        }

        return of(initialResponse.items);
      }));
  }

  public createAffiliateLink(affiliateId: string, userId: string, createAffiliateLinkRequest: CreateAffiliateLinkRequest) {
    let options: GetOptions  = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        Authorization: '07ca437966771f8f14f8200bd5fd21c9616764a2;3ab6feb6ae82403553572268e0c61fff0befc2f0;1585862902;6e3ae443-4e20-4b16-8bd0-98e6bb9006a9;P100Y',
        'X-API-Version': '2'
      }),
      params: {
        application: '6e3ae443-4e20-4b16-8bd0-98e6bb9006a9'
      },
      excludeDefaultHeaders: true
    };

    return this.http.postWithOptions<CreateAffiliateLinkResponse>(`${environment.apiUrl}/utilities/easylink/${affiliateId}/user/${userId}`,
      createAffiliateLinkRequest, options);
  }

  public getAffiliateMerchantAds(affiliateId: string, query: AffiliateAdRequest) {
    let params = { ...query,
      sort: query.sort && query.direction ? 'ma.' + query.sort : '',
      direction: query.direction?.toUpperCase() ?? ''
    };

    return this.http.getWithOptions<Paged<MerchantAd>>(`${environment.apiUrl}/affiliates/${affiliateId}/ads`, { params }).pipe(
      tap(x => deepMutateIsoToMtTime(x)),
      catchError((response: HttpErrorResponse) => {
        if (response.status === 404 && response?.error?.error_key === 'api.model.merchant_ad.not_found') {
          return of<Paged<MerchantAd>>({
            items: [],
            items_per_page: params.limit,
            page: 1,
            total_items: 0
          });
        } else {
          return throwError(response);
        }
      }));
  }

  public getAffiliateMerchantAdById(affiliateId: string, merchantAdId: string) {
    return this.http.get<MerchantAd>(`${environment.apiUrl}/affiliates/${affiliateId}/ads/${merchantAdId}`);
  }

  public getAffiliateClassifications() {
    return this.http.get<AffiliateClassification[]>(`${environment.apiUrl}/affiliate_classifications`);
  }
}
