import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appHasUppercase][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HasUppercaseValidatorDirective),
      multi: true
    }
  ]
})

export class HasUppercaseValidatorDirective implements Validator {

  private regularExpression = new RegExp('[A-Z]');

  constructor( @Attribute('appHasUppercase') public hasUppercase: string) {
  }

  validate(controlElement: AbstractControl): { [key: string]: any } {
    if (!controlElement.value || controlElement.value === '') {
      return null;
    }

    return !this.regularExpression.test(controlElement.value) ? { hasUppercase: true } : null;
  }
}
