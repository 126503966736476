import { RouterModule } from '@angular/router';

import { AccessDeniedComponent } from './access-denied/access-denied.component';

export const routing = RouterModule.forChild([
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  { path: 'access_denied', redirectTo: 'access-denied'},
]);
