import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IReportDataManager} from '../data-table-display';

@Component({
  selector: 'app-data-table-display-wrapper',
  templateUrl: './data-table-display-wrapper.component.html',
  styleUrls: ['./data-table-display-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DataTableDisplayWrapperComponent implements AfterViewInit {
  public passedReportDataManager: IReportDataManager;
  public recordCount: number;
  constructor(public dialog: MatDialogRef<DataTableDisplayWrapperComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {reportDataManager: IReportDataManager},
              private changeDetectionRef: ChangeDetectorRef) {
    this.passedReportDataManager = data.reportDataManager;
  }

  public ngAfterViewInit(): void {
    this.changeDetectionRef.detectChanges();
  }

  public setReportViewFromTableLibrary(): void {
  }

  public setReportCount(recordCount: number): void {
    if (!isNaN(recordCount)) {
      this.recordCount = recordCount;
    }
  }
}
