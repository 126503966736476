import { NgModule, Optional, SkipSelf } from '@angular/core';

@NgModule({})
export class ReportApiModule {
  constructor(@Optional() @SkipSelf() parentModule: ReportApiModule) {
    if (parentModule) {
      throw new Error(
        // see https://angular.io/guide/singleton-services for more info
        'ReportApiModule is already loaded. Import it in the AppModule only');
    }
  }
}
