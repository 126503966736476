import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {IReportDataManagerEdw3} from '../data-table-display';

@Component({
  selector: 'app-data-table-display-wrapper-edw3',
  templateUrl: './data-table-display-wrapper-edw3.component.html',
  styleUrls: ['./data-table-display-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DataTableDisplayWrapperEdw3Component implements AfterViewInit {
  public passedReportDataManager: IReportDataManagerEdw3;
  constructor(public dialog: MatDialogRef<DataTableDisplayWrapperEdw3Component>,
              @Inject(MAT_DIALOG_DATA) public data: {reportDataManager: IReportDataManagerEdw3},
              private changeDetectionRef: ChangeDetectorRef) {
    this.passedReportDataManager = data.reportDataManager;
  }

  public ngAfterViewInit(): void {
    this.changeDetectionRef.detectChanges();
  }

  public setReportViewFromTableLibrary(): void {
  }
}
