<div class="form-container">
  <form class="reset-password-form" (ngSubmit)="resetPassword()" #resetPasswordForm="ngForm">
    <app-logo-link></app-logo-link>
    <p *ngIf="initialPasswordSetup" class="message">
      Stronger security for your account
    </p>
    <p *ngIf="initialPasswordSetup">
      Enter the validation code we emailed and set a new password.
    </p>
    <p *ngIf="!initialPasswordSetup" class="message">
      Reset your password
    </p>
    <p *ngIf="!initialPasswordSetup">
      Enter the validation code we emailed you.
    </p>
    <div class="container">
      <mat-form-field>
        <mat-label>Validation Code</mat-label>
        <input matInput name="validationCode" type="text" required minlength="4" [(ngModel)]="validationCode">
        <mat-icon matPrefix>verified_user</mat-icon>
      </mat-form-field>
      <div>
        <a (click)="resendEmailCode()">
          Resend code
        </a>
      </div>
    </div>
    <div class="container">
      <mat-card appearance="raised" *ngIf="passwordModel.errors || passwordModel?.value" class="flex-column requirements">
        <div class="requirements-title">Password Requirements</div>
        <div class="flex-row">
          <div class="flex-1 flex-column">
            <div class="flex-1 validation">
              <mat-icon title="Password must be at least 8 characters." name="validate-length" [ngClass]="{'validate-failure': ((passwordModel?.errors && passwordModel?.errors['minlength']) || passwordModel?.pristine || passwordModel?.value === ''),
                    'validate-success': ((!passwordModel?.errors || !passwordModel?.errors['minlength']) && !passwordModel?.pristine && passwordModel?.value) }">
                {{((passwordModel?.errors && passwordModel?.errors['minlength']) || passwordModel?.pristine || passwordModel?.value === '') ? 'clear' : 'done'}}
              </mat-icon>
              <span>8+ characters</span>
            </div>
            <div class="flex-1 validation">
              <mat-icon title="Password must have a number." name="validate-number" [ngClass]="{'validate-failure': ((passwordModel?.errors && passwordModel.errors['hasNumber']) || passwordModel?.pristine || passwordModel?.value === ''),
                    'validate-success': ((!passwordModel?.errors || !passwordModel.errors['hasNumber']) && !passwordModel?.pristine && passwordModel?.value) }">
                {{((passwordModel?.errors && passwordModel.errors['hasNumber']) || passwordModel?.pristine || passwordModel?.value === '') ? 'clear' : 'done'}}
              </mat-icon>
              <span>1+ number</span>
            </div>
          </div>
          <div class="flex-1 flex-column">
            <div class="flex-1 validation">
              <mat-icon title="Password must have an uppercase character." name="validate-upper" [ngClass]="{'validate-failure': ((passwordModel?.errors && passwordModel.errors['hasUppercase']) || passwordModel?.pristine || passwordModel?.value === ''),
                    'validate-success': ((!passwordModel?.errors || !passwordModel.errors['hasUppercase']) && !passwordModel?.pristine && passwordModel?.value) }">
                {{((passwordModel?.errors && passwordModel.errors['hasUppercase']) || passwordModel?.pristine || passwordModel?.value === '') ? 'clear' : 'done'}}
              </mat-icon>
              <span>1+ upper case</span>
            </div>
            <div class="flex-1 validation">
              <mat-icon title="Password must have a symbol." name="validate-symbol" [ngClass]="{'validate-failure': ((passwordModel?.errors && passwordModel.errors['hasSymbol']) || passwordModel?.pristine || passwordModel?.value === ''),
                    'validate-success': ((!passwordModel?.errors || !passwordModel.errors['hasSymbol']) && !passwordModel?.pristine && passwordModel?.value) }">
                {{((passwordModel?.errors && passwordModel.errors['hasSymbol']) || passwordModel?.pristine || passwordModel?.value === '') ? 'clear' : 'done'}}
              </mat-icon>
              <span>1+ special character</span>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="container">
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput name="password" type="password" #passwordModel="ngModel" required appHasUppercase appHasNumber appHasSymbol minlength="8"
        appValidateEqual="verifyPassword" isMaster=true [(ngModel)]="password">
        <mat-icon matPrefix>lock</mat-icon>
      </mat-form-field>
    </div>
    <div class="container">
      <mat-form-field>
        <mat-label>Verify Password</mat-label>
        <input matInput name="verifyPassword" type="password" required appValidateEqual="password" [(ngModel)]="verifyPassword">
        <mat-icon matPrefix>lock</mat-icon>
      </mat-form-field>
    </div>
    <button color="primary" type="submit" mat-raised-button [disabled]="!resetPasswordForm.valid || resetPasswordForm.pristine">
      Reset password
    </button>
  </form>
  <div>
    <p>
      <a [routerLink]="['/login']">Back to login</a>
    </p>
  </div>
</div>
