import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse } from '@angular/common/http';
import { DashboardHttpService } from '../../core-services/dashboard-http-service/dashboard-http.service';
import { environment } from '../../../environments/environment';
import {
  PermissionGrantMultipleRequest,
  PermissionGrantRequest,
  PermissionRevokePresetRequest,
  PermissionRevokeRequest, PermissionsRequest,
  PermissionsResponse
} from '../../core/dtos/api';

@Injectable()
export class UserPermissionsApiService {
  private path = '/permission/user';

  constructor(
    private http: DashboardHttpService
  ) { }

  public grantPermission(userId: string, entityGroupName: string, entityId: string, permissionGroupName: string):
    Observable<PermissionsResponse[]> {
    let requestUrl = `${environment.apiUrl}${this.path}/${userId}`;
    let data: PermissionGrantRequest = {
      entity_group_name: entityGroupName,
      entity_id: entityId,
      permission_group_name: permissionGroupName
    };
    return this.http.put<PermissionGrantRequest, PermissionsResponse[]>(requestUrl, data, false);
  }

  public grantMultiplePermissions(userId: string, permissions: PermissionGrantRequest[]):
    Observable<PermissionsResponse[]> {
    let requestUrl = `${environment.apiUrl}${this.path}/${userId}/multiple`;
    let data: PermissionGrantMultipleRequest = {
      permissions_query: permissions
    };
    return this.http.put<PermissionGrantMultipleRequest, PermissionsResponse[]>(requestUrl, data, false);
  }

  public revokePermission(userId: string, entityGroupName: string, entityId: string, permissionGroupName: string):
  Observable<PermissionsResponse[]> {
    let requestUrl = `${environment.apiUrl}${this.path}/${userId}`;
    let data: PermissionRevokeRequest = {
      entity_group_name: entityGroupName,
      entity_id: entityId,
      permission_group_name: permissionGroupName
    };
    return this.http.deleteWithBody<PermissionRevokeRequest, PermissionsResponse[]>(requestUrl, data, false);
  }

  public revokePermissionPreset(userId: string, entityId: string, permissionPresetId: string):
    Observable<PermissionsResponse[]> {
    let requestUrl = `${environment.apiUrl}${this.path}/${userId}/preset`;
    let data: PermissionRevokePresetRequest = {
      entity_id: entityId,
      permission_preset_id: permissionPresetId
    };
    return this.http.deleteWithBody<PermissionRevokePresetRequest, PermissionsResponse[]>(requestUrl, data, false);
  }

  public getPermissionsCheck(
    userId: string,
    permissionQuery: PermissionsRequest[]
  ): Observable<PermissionsResponse[]> {
    return this.http.post<{ permissions_query: string }, PermissionsResponse[]>
    (`${environment.apiUrl}/permission/user/${userId}/check`,
      {permissions_query: JSON.stringify(permissionQuery)});
  }
}
