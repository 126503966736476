import { AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild } from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {debounceTime, filter, throttleTime} from 'rxjs/operators';

import { windowToken } from '../../../dashboard/dashboard-helpers/dashboard-helper';
import { getAverageOfObjectProps, getSumOfObjectProps } from '../../../utilities/object-math-utility/object-math-utility';
import { isDummyKey } from '../data-table-display-utilities/data-table-display-utility';
import { SnackBarService } from '../../../core-services/snack-bar/snack-bar.service';
import {ColType, DataIndex, IReportDataManagerEdw3, PageValues, WindowDisplay} from '../data-table-display';
import {environment} from '../../../../environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {RequestObjContainer} from 'picker-sdk';
import {DataTableDisplayWrapperEdw3Component} from '../data-table-display-wrapper/data-table-display-wrapper-edw3.component';

@Component({
  selector: 'app-data-table-display-edw3',
  templateUrl: './data-table-display-edw3.component.html',
  styleUrls: ['./data-table-display-edw3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class DataTableDisplayEdw3Component implements OnChanges, AfterViewInit, OnDestroy {
  @Input() reportDataManager: IReportDataManagerEdw3;
  @Input() componentName: string;
  @Input() sharesReportDataManager = false;
  @Input() showReportHeaderInfo = false;
  @Input() showExpandButton = false;
  @Input() set triggerResize(val) { if (val != null) {this.onResizeWindow(); }}
  @Output() initialReportResponseReturned: EventEmitter<RequestObjContainer> = new EventEmitter<RequestObjContainer>();
  @Output() reportCountReturned: EventEmitter<number> = new EventEmitter<number>();
  @Output() customComponentEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('tableWrapper', { static: true }) tableWrapperElement: ElementRef;
  @ViewChild('verticalScroll') verticalScrollElement: ElementRef;
  @ViewChild('horizontalScroll') factHorizontalScrollElement: ElementRef;

  public readonly TOTAL_LABEL = 'Totals';
  public readonly totalDimWidthPadding = 11;
  private readonly NO_FACTS_MESSAGE = 'You must select at least one metric to run a report.';
  private readonly VERTICAL_SCROLL_BAR_WIDTH: number = 17;
  private readonly HORIZONTAL_SCROLL_BAR_HEIGHT: number = 20;
  readonly environment = environment;

  readonly onMouseMoveEvent$: Subject<Event> = new Subject<Event>();
  private readonly onMouseMoveEvent$Sub = this.onMouseMoveEvent$.pipe(
    throttleTime(40)
  ).subscribe(($event) => this.onMouseMove($event));

  // These are the variables that are used in the html template in building the table.
  public windowDisplay: WindowDisplay;
  public dimsScrolledToEnd: boolean;

  private window: Window;
  private mouseClickDown: boolean;
  private beginningYPositionForParent: number;
  private beginningXPositionForFact: number;
  private beginningXPositionForDim: number;
  private windowDynamicHeight: number;
  private windowInitialHeight: number;
  private windowHeightInRows: number;
  private horizontalScrollDimWrapperLeft: number;
  private windowInitialWidth: number;
  private windowWidthInColumns: number;
  private lastPosPartitionTile: number;
  private lastPosRowTile: number;
  private rowHeight: number;
  private totalFactsWidth: number;
  private averageFactWidth: number;
  private defaultFactColWidth: number;
  private totalDimsWidthWithoutPadding: number;
  private verticalScrollPositionTop: number;
  private tileHeightPx: number;
  private horizontalScrollFactWrapperLeft: number;
  private horizontalScrollWrapperTop: number;
  private verticalScrollWrapperLeft: number;
  private verticalScrollWrapperTop: number;
  private selection: Selection;
  private displayedWarnings: {[key: string]: boolean} = {};

  private windowHeightWidthCaptured: BehaviorSubject<boolean>;
  showSpinner$ = new BehaviorSubject(true);
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private snackBar: SnackBarService,
    public matDialog: MatDialog,
    // We inject the WindowToken so we can mock the window for tests.
    @Inject(windowToken) window: Window,
  ) {
    this.window = window;
    this.setWindowDisplayDefaults();
    this.resetProperties();
  }

  private _subs = new Subscription();
  public set subs(sub: Subscription) { this._subs.add(sub); }
  public get subs() { return this._subs; }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.reportDataManager && changes['reportDataManager']) {
      this.showSpinner$.next(true);
      this.subscribeToDataManagerSubjects();
      if (this.reportDataManager.isReady) {
        // We need to ensure that template has been rendered and that we have the window width and height before initiating request.
        this.setWindowDisplayDefaults();
        this.windowHeightWidthCaptured
          .subscribe((isCaptured) => {
            if (isCaptured) {
              // Partitions in tile need to be static once set initially.
              if (!this.sharesReportDataManager) {
                let tableToBrowserWidthRatio = (this.windowDisplay.windowDynamicWidth / this.window.innerWidth).toFixed(2);
                let defaultWidth = Math.round(this.window.screen.availWidth * parseFloat(tableToBrowserWidthRatio));
                this.reportDataManager.determinePartitionsInTile(defaultWidth, this.defaultFactColWidth);
              }
              this.reportDataManager.initiateInitialRequest();
            }
          });
      }
    }
  }

  public ngAfterViewInit(): void {
    let initialTableWrapper = this.tableWrapperElement.nativeElement;
    this.windowDisplay.windowDynamicWidth = this.windowInitialWidth = initialTableWrapper.offsetWidth;
    this.windowDynamicHeight = this.windowInitialHeight =  initialTableWrapper.offsetHeight;
    this.windowHeightWidthCaptured.next(true);
    // We use detectChanges here as it forces change detection to occur immediately, while markForCheck marks it to be checked during the next cycle.
    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
      this.subs.unsubscribe();
      this.windowHeightWidthCaptured.unsubscribe();
      this.onMouseMoveEvent$Sub.unsubscribe();

      this.changeDetectorRef.detach();
  }

  private resetProperties(): void {
      this.mouseClickDown = false;
      this.beginningYPositionForParent = 0;
      this.beginningXPositionForFact = 0;
      this.beginningXPositionForDim = 0;
      this.selection = document.getSelection();
      this.windowDynamicHeight = 400;
      this.windowHeightInRows = 22;
      this.horizontalScrollDimWrapperLeft = 0;
      this.windowInitialWidth = 800;
      this.windowWidthInColumns = 11;
      this.lastPosPartitionTile = 0;
      this.lastPosRowTile = 0;
      this.rowHeight = 31;
      this.totalFactsWidth = 0;
      this.averageFactWidth = 0;
      this.defaultFactColWidth = 91;
      this.totalDimsWidthWithoutPadding = 0;
      this.verticalScrollPositionTop = 0;
      this.tileHeightPx = 0;
      this.horizontalScrollFactWrapperLeft = 0;
      this.horizontalScrollWrapperTop = 0;
      this.verticalScrollWrapperLeft = 0;
      this.verticalScrollWrapperTop = 0;
      this.windowHeightWidthCaptured = new BehaviorSubject<boolean>(false);
  }

  private subscribeToDataManagerSubjects(): void {
    this.subs = this.reportDataManager.errorSubject
      .subscribe((error) => this.handleError(error));
    this.subs = this.reportDataManager.warningSubject
      .subscribe((warnings) => this.handleWarnings(warnings));
    this.subs = this.reportDataManager.progressSpinnerSubject
      .subscribe((displaySpinner) => this.showSpinner$.next(displaySpinner));
    this.subs = this.reportDataManager.dataCompleteSubject
      .subscribe(() => this.handleFinalNewReportRequirements());
    this.subs = this.reportDataManager.noDataReturnedSubject
      .subscribe((returnedRequest) => this.handleNoDataReturned(returnedRequest));
    this.subs = this.reportDataManager.triggerChangeDetectionSubject
      .subscribe((triggerChange) => this.changeDetectorRef.markForCheck());
    this.subs = this.reportDataManager.sortedRequestSubject
      .subscribe(() => this.handleSort());
    // We filter out changes that occurred for this instance, as those changes have already been accounted for.
    // We than update the scrolls to reflect the change that what emitted here.
    this.subs = this.reportDataManager.scrollHasChanged
      .pipe(
        debounceTime(500),
        filter((componentName) => componentName !== this.componentName)
      ).subscribe(() => this.setScrollBarPositions());
  }

  private setWindowDisplayDefaults(): void {
    this.windowDisplay = {
      windowDynamicWidth: 800,
      totalRowsForNgFor: [],
      totalPartitionsForNgFor: [],
      totalDimsWidth: 0,
      bodyHeightExcludingFooter: 0,
      footerHeight: 0,
      verticalScrollBarHeight: 0,
      verticalFactScrollBarWidth: 0,
      horizontalScrollBarHeight: 0,
      allDataHeight: 0,
      allDataWidth: 0,
      dimensionColSpan: 0,
      currentDisplayPositionLeft: 0,
      tileWidthPx: 0,
      extraWidthForShortReports: 0,
      selectStyleKey: {},
      numberPartitionTilesToDisplay: 0,
      numberRowTilesToDisplay: 0,
      horizontalScrollReady: false,
      isDummyKey, // We need to add this function to this class so we can use it in the template.
    };
  }

  private setDynamicWindowValues(isOnResize = false): void {
    const PADDING_WIDTH = 11;
    let headerDepth
      = this.reportDataManager.lookUpTableData[this.reportDataManager.partitionKeyList[this.reportDataManager.partitionOffsetOfInitialRequest]].headers[0].length;
    this.rowHeight = this.reportDataManager.dataTableRowHeight + PADDING_WIDTH;
    let factHeaderHeight = this.rowHeight * (this.reportDataManager.partitionTitles.length + headerDepth);

    // Each time this is ran we need to reset the width and height to the initial window width and height.
    this.windowDisplay.windowDynamicWidth = this.windowInitialWidth;
    this.windowDynamicHeight = this.windowInitialHeight;
    this.reportDataManager.factColSpan
      = this.reportDataManager.lookUpTableData[this.reportDataManager.partitionKeyList[this.reportDataManager.partitionOffsetOfInitialRequest]].facts.length;
    this.windowDisplay.dimensionColSpan
      = this.reportDataManager.lookUpTableData[this.reportDataManager.partitionKeyList[this.reportDataManager.partitionOffsetOfInitialRequest]].dims.length;

    this.totalDimsWidthWithoutPadding = getSumOfObjectProps(this.reportDataManager.dimColWidths);
    this.windowDisplay.totalDimsWidth = this.totalDimsWidthWithoutPadding + (this.windowDisplay.dimensionColSpan * PADDING_WIDTH);
    this.totalFactsWidth = getSumOfObjectProps(this.reportDataManager.factColWidths) + (this.reportDataManager.factColSpan * PADDING_WIDTH);
    this.averageFactWidth = getAverageOfObjectProps(this.reportDataManager.factColWidths) + PADDING_WIDTH;

    this.windowHeightInRows = Math.floor((this.windowDynamicHeight - factHeaderHeight) / this.rowHeight);

    if (this.reportDataManager.totalRowsInReport > this.windowHeightInRows) {
      // Even if there are no facts we still set the verticalFactScrollBarWidth, this is because this is used for future calculations.
      this.windowDisplay.verticalFactScrollBarWidth = this.VERTICAL_SCROLL_BAR_WIDTH;
    } else {
      this.windowDisplay.verticalFactScrollBarWidth = 0;
    }

    if ((this.reportDataManager.partitionKeyList.length * this.totalFactsWidth + this.windowDisplay.totalDimsWidth)
      > this.windowDisplay.windowDynamicWidth) {
      this.windowDisplay.horizontalScrollBarHeight = this.HORIZONTAL_SCROLL_BAR_HEIGHT;
      this.windowDisplay.extraWidthForShortReports = 0;
    } else {
      this.windowDisplay.horizontalScrollBarHeight = 0;
      // If there is no horizontalScrollBarHeight the data positions should always be 0.
      this.reportDataManager.currentFactDisplayPositionLeft = 0;
      this.windowDisplay.windowDynamicWidth
        = (this.reportDataManager.partitionKeyList.length * this.totalFactsWidth) + this.windowDisplay.totalDimsWidth + 1;
      this.windowDisplay.extraWidthForShortReports = this.windowInitialWidth - this.windowDisplay.windowDynamicWidth;
      this.windowDisplay.currentDisplayPositionLeft = 0;
    }

    this.windowWidthInColumns = Math.floor(this.windowInitialWidth / this.averageFactWidth);
    this.windowDisplay.tileWidthPx = this.reportDataManager.partitionsInTile * this.totalFactsWidth;
    this.tileHeightPx = this.reportDataManager.rowsInTile * this.rowHeight;

    if (this.reportDataManager.lookUpTableData[this.reportDataManager.partitionKeyList[this.reportDataManager.partitionOffsetOfInitialRequest]].totals) {
      this.windowDisplay.footerHeight = this.rowHeight;
    } else {
      this.windowDisplay.footerHeight = 0;
    }
    this.windowDisplay.allDataHeight = this.reportDataManager.totalRowsInReport * this.rowHeight;
    this.calculateColumnWidths();

    let dataTableCardHeaderHeight = (this.showReportHeaderInfo || this.showExpandButton) ? 40 : 0;

    // 2 has to be added due to the border.
    if (this.windowDisplay.allDataHeight > this.windowDynamicHeight) {
      // subtract 7 pixels to prevent the horizontal scroll bar from overflowing the window and causing a vertical scroll bar to appear.
      this.windowDynamicHeight = this.windowInitialHeight - (dataTableCardHeaderHeight + 7);
    } else {
      this.windowDynamicHeight = this.windowDisplay.allDataHeight + factHeaderHeight + this.windowDisplay.horizontalScrollBarHeight
        + this.windowDisplay.footerHeight + 2;
      // We want to ensure when shared reportDataManagers are used larger dataTables with no scrolling don't display blank rows.
      this.reportDataManager.currentDisplayPositionTop = 0;
      this.reportDataManager.verticalScrollTop = 0;
      this.reportDataManager.scrollHasUpdated(this.componentName);
    }
    this.verticalScrollPositionTop = factHeaderHeight;
    this.windowDisplay.bodyHeightExcludingFooter = this.windowDynamicHeight - (this.windowDisplay.horizontalScrollBarHeight + this.windowDisplay.footerHeight);
    this.windowDisplay.verticalScrollBarHeight = this.windowDisplay.bodyHeightExcludingFooter - factHeaderHeight;
    this.windowDisplay.horizontalScrollReady = true;
    if (!isOnResize) {
      this.initialReportResponseReturned.emit(this.reportDataManager.getInitialRequestObject());
    }
    // Ensure view is re-rendered if required.
    this.changeDetectorRef.markForCheck();
  }

  private calculateColumnWidths(): void {
    this.windowDisplay.allDataWidth = (this.reportDataManager.totalPartitionsInReport * this.totalFactsWidth)
      + this.windowDisplay.verticalFactScrollBarWidth + this.windowDisplay.totalDimsWidth;

    if ((((this.reportDataManager.partitionKeyList.length * this.totalFactsWidth) + this.windowDisplay.totalDimsWidth)
      > (this.windowDisplay.windowDynamicWidth + this.windowDisplay.extraWidthForShortReports))) {
      this.windowDisplay.horizontalScrollBarHeight = this.HORIZONTAL_SCROLL_BAR_HEIGHT;
    }
  }

  public onScrollForVerticalScrollBar(scrollEventTarget: EventTarget): void {
    let targetHtmlElement = <HTMLElement> scrollEventTarget;
    if (this.reportDataManager.verticalScrollTop !== targetHtmlElement.scrollTop) {
      this.reportDataManager.verticalScrollTop = targetHtmlElement.scrollTop;
      this.reportDataManager.posRowTile = Math.floor(this.reportDataManager.verticalScrollTop / this.tileHeightPx);
      this.reportDataManager.posRowCalculated = this.reportDataManager.posRowTile * this.reportDataManager.rowsInTile;

      this.reportDataManager.currentDisplayPositionTop = (this.reportDataManager.posRowTile * this.tileHeightPx)
        - targetHtmlElement.scrollTop;
      if (this.reportDataManager.posRowTile !== this.reportDataManager.lastPosRowTile) {
        this.changeDetectorRef.markForCheck();
        this.reportDataManager.checkTilesToDetermineRequiredRequests(
          false,
          this.windowDisplay.numberPartitionTilesToDisplay,
          this.windowDisplay.numberRowTilesToDisplay);
      }
      this.reportDataManager.lastPosRowTile = this.reportDataManager.posRowTile;
      this.reportDataManager.scrollHasUpdated(this.componentName);
    }
  }

  public onWheelEvent(wheelEvent: WheelEvent): void {
    let verticalScrollWrapper = this.verticalScrollElement ? this.verticalScrollElement.nativeElement : null;
    let horizontalScrollFactWrapper = this.factHorizontalScrollElement ? this.factHorizontalScrollElement.nativeElement : null;

    if (verticalScrollWrapper) {
      verticalScrollWrapper.scrollTo(verticalScrollWrapper.scrollLeft, verticalScrollWrapper.scrollTop + wheelEvent.deltaY);
    }

    if (horizontalScrollFactWrapper) {
      // We have to include this because the default behavior for a two-finger swipe on chrome is page stack navigation.
      wheelEvent.preventDefault();
      horizontalScrollFactWrapper.scrollTo(horizontalScrollFactWrapper.scrollLeft + wheelEvent.deltaX, horizontalScrollFactWrapper.scrollTop);
    }
  }

  public onScrollForHorizontalScrollBar(scrollEventTarget: EventTarget): void {
    let eventTargetHtmlElement = <HTMLElement> scrollEventTarget;
    if (this.reportDataManager.factHorizontalScrollLeft !== eventTargetHtmlElement.scrollLeft) {
      this.reportDataManager.factHorizontalScrollLeft = eventTargetHtmlElement.scrollLeft;

      let firstTileWidth = this.windowDisplay.tileWidthPx + this.windowDisplay.totalDimsWidth;
      let isInFirstTile: boolean = this.reportDataManager.factHorizontalScrollLeft < firstTileWidth;

      if (isInFirstTile) {
        this.reportDataManager.posPartitionTile = 0;
      } else {
        this.reportDataManager.posPartitionTile
          = Math.floor((this.reportDataManager.factHorizontalScrollLeft - this.windowDisplay.totalDimsWidth) / this.windowDisplay.tileWidthPx);
      }

      this.reportDataManager.posPartitionCalculated = this.reportDataManager.posPartitionTile * this.reportDataManager.partitionsInTile;
      if (isInFirstTile) {
        this.windowDisplay.currentDisplayPositionLeft = (this.reportDataManager.posPartitionTile * firstTileWidth)
          - eventTargetHtmlElement.scrollLeft;
        this.reportDataManager.currentFactDisplayPositionLeft = this.windowDisplay.currentDisplayPositionLeft;
      } else {
        this.windowDisplay.currentDisplayPositionLeft = (this.reportDataManager.posPartitionTile * this.windowDisplay.tileWidthPx)
          - (eventTargetHtmlElement.scrollLeft - this.windowDisplay.totalDimsWidth);
        this.reportDataManager.currentFactDisplayPositionLeft = this.windowDisplay.currentDisplayPositionLeft;
      }

      if (this.reportDataManager.posPartitionTile !== this.reportDataManager.lastPosPartitionTile) {
        this.reportDataManager.checkTilesToDetermineRequiredRequests(
          false,
          this.windowDisplay.numberPartitionTilesToDisplay,
          this.windowDisplay.numberRowTilesToDisplay);

        // Only check first displayed partition.
        this.reportDataManager.checkToDetermineDynamicColSpans(this.reportDataManager.posPartitionTile * this.reportDataManager.partitionsInTile);
      }

      this.reportDataManager.lastPosPartitionTile = this.reportDataManager.posPartitionTile;
      this.reportDataManager.scrollHasUpdated(this.componentName);
    }
  }

  public onMouseMove(moveEvent: Event): void {
    let pageValues: PageValues = this.getPageValues(moveEvent, 'touchmove');

    if (this.mouseClickDown) {
      if (this.verticalScrollElement) {
        this.verticalScrollElement.nativeElement.scrollTop = this.verticalScrollWrapperTop + (this.beginningYPositionForParent - pageValues.pageY);
      }
      if (this.factHorizontalScrollElement) {
        this.factHorizontalScrollElement.nativeElement.scrollLeft = this.horizontalScrollFactWrapperLeft + (this.beginningXPositionForFact
          - pageValues.pageX);
      }
    }
  }

  public onMouseDown(downEvent: Event): void {
    let pageValues: PageValues = this.getPageValues(downEvent, 'touchstart');

    if (this.verticalScrollElement) {
      this.verticalScrollWrapperTop = this.verticalScrollElement.nativeElement.scrollTop;
      this.verticalScrollWrapperLeft = this.verticalScrollElement.nativeElement.scrollLeft;
    }

    if (this.factHorizontalScrollElement) {
      this.horizontalScrollWrapperTop = this.factHorizontalScrollElement.nativeElement.scrollTop;
      this.horizontalScrollFactWrapperLeft = this.factHorizontalScrollElement.nativeElement.scrollLeft;
    }

    this.beginningYPositionForParent = pageValues.pageY;
    this.beginningXPositionForFact = pageValues.pageX;
    this.mouseClickDown = true;
  }

  public onMouseUp(upEvent: Event): void {
    this.mouseClickDown = false;
  }

  public onMouseLeave(mouseOutEvent: MouseEvent): void {
    this.mouseClickDown = false;
  }

  @HostListener('window:resize', ['$event'])
  public onResizeWindow(): void {
    // Unfortunately, there are some instances where the window is not done resizing on firefox when the resize event fires. Therefore, we have
    // to check to ensure the table elements width is the same after 300 milliseconds, if it is different we set the dynamic window values again.
    let resizedElement = this.tableWrapperElement ? this.tableWrapperElement.nativeElement : null;
    if (resizedElement) {
      this.windowInitialWidth = resizedElement.offsetWidth;
      this.windowInitialHeight = resizedElement.offsetHeight;
    }
    setTimeout(() => {
      if (resizedElement && (this.windowInitialWidth !== resizedElement.offsetWidth)) {
        this.windowInitialWidth = resizedElement.offsetWidth;
        this.windowInitialHeight = resizedElement.offsetHeight;
        this.setDynamicWindowValues();
      }
    }, 300);
    this.setDynamicWindowValues();
    this.buildngForArrays();
    this.reportDataManager.checkTilesToDetermineRequiredRequests(
      false,
      this.windowDisplay.numberPartitionTilesToDisplay,
      this.windowDisplay.numberRowTilesToDisplay);
  }

  public onCellDoubleClick($event: MouseEvent, partition: string, key: number, row?: number): void {
    let cellSelectStyle = this.createSelectStyle(partition, key, row);
    this.windowDisplay.selectStyleKey = {};
    this.windowDisplay.selectStyleKey[cellSelectStyle] = true;
    let range = document.createRange();
    range.setStart(<Node> $event.currentTarget, 0);
    range.setEndAfter(<Node> $event.currentTarget);

    this.selectCells(range);
  }

  public onDynamicComponentEvent(data): void {
    this.customComponentEvent.next(data);
  }

  public onRowClick($event: MouseEvent, partition: string, key: number, rowType: ColType, row?: number): void {
    if ($event.detail === 3) {
      let rowSelectStyle = this.createSelectStyle(partition, undefined, row);
      let range = this.createRowRange(<Element> $event.currentTarget, key, rowType);
      this.windowDisplay.selectStyleKey = {};
      this.windowDisplay.selectStyleKey[rowSelectStyle] = true;
      this.selectCells(range);
      $event.stopPropagation();
    }
  }

  private getPageValues(event: Event, type: string): PageValues {
    let pageXValue: number;
    let pageYValue: number;
    if (event.type === type) {
      pageXValue = (<TouchEvent> event).touches[0].pageX;
      pageYValue = (<TouchEvent> event).touches[0].pageY;
    } else {
      pageXValue = (<MouseEvent> event).pageX;
      pageYValue = (<MouseEvent> event).pageY;
    }

    if (event.type === 'touchstart') {
      // This is so mouse events are not triggered.
      event.preventDefault();
    }

    return {
      pageX: pageXValue,
      pageY: pageYValue
    };
  }

  private createSelectStyle(partition: string, key?: number, row?: number): string {
    let selectStyle: string;
    // No key will be provided for row selections, and no row will be provided for total line selections.
    if (key !== undefined) {
      if (row !== undefined) {
        selectStyle = `${row}--${partition}--${key}`;
      } else {
        selectStyle = `${partition}--${key}`;
      }
    } else {
      if (row !== undefined) {
        selectStyle = `${row}--${partition}`;
      } else {
        selectStyle = partition;
      }
    }
    return selectStyle;
  }

  private createRowRange(targetElement: Element, key: number, rowType: ColType): Range {
    let range = document.createRange();
    let colIndexes: DataIndex[] = rowType === 'dim'
      ? this.reportDataManager.lookUpTableData[this.reportDataManager.partitionKeyList[this.reportDataManager.partitionOffsetOfInitialRequest]].dims
      : this.reportDataManager.lookUpTableData[this.reportDataManager.partitionKeyList[this.reportDataManager.partitionOffsetOfInitialRequest]].facts;
    let totalCols = colIndexes.length;
    let nodesToLeft = 0;
    for (let index = 0; index < totalCols; index++) {
      if (colIndexes[index].index === key) {
        nodesToLeft = index;
        break;
      }
    }

    // We have to add one to account for totalCols not being zero based.
    let nodesToRight =  totalCols - (nodesToLeft + 1);
    let leftNode =  targetElement;
    let rightNode = targetElement;

    for (let i = 0; i < nodesToLeft; i++) {
      leftNode = leftNode.previousElementSibling;
    }

    for (let i = 0; i < nodesToRight; i++) {
      rightNode = rightNode.nextElementSibling;
    }

    range.setStart(<Node> leftNode, 0);
    range.setEndAfter(<Node> rightNode);

    return range;
  }

  public removeSelection($event: MouseEvent): void {
    this.windowDisplay.selectStyleKey = {};
    if (this.selection.rangeCount > 0) {
      this.selection.removeAllRanges();
    }
  }

  private selectCells(targetedRange: Range): void {
    this.selection.removeAllRanges();
    this.selection.addRange(targetedRange);
  }

  private handleError(error: string): void {
    this.snackBar.openErrorSnackBar(error);
  }

  private handleWarnings(warnings: string[]): void {
    warnings.forEach((warning) => {
      if (!this.displayedWarnings[warning]) {
        this.snackBar.openInfoSnackBar(warning);
        this.displayedWarnings[warning] = true;
      }
    });
  }

  private handleFinalNewReportRequirements(): void {
    let tableWrapper = this.tableWrapperElement.nativeElement;

    // Unfortunately, there are some instances where the window is not done resizing on firefox when the table width is checked. Therefore, we have
    // to check to ensure the table elements width is the same after 100 milliseconds, if it is different we set the dynamic window values again.
    setTimeout(() => {
      if (this.windowInitialWidth !== tableWrapper.offsetWidth || this.windowInitialHeight !== tableWrapper.offsetHeight) {
        this.windowInitialHeight = tableWrapper.offsetHeight;
        this.windowInitialWidth = tableWrapper.offsetWidth;
        this.setDynamicWindowValues();
      }
    }, 100);

    this.reportCountReturned.emit(this.reportDataManager.totalRowsInReport);
    this.setDynamicWindowValues();
    this.buildngForArrays();
    this.reportDataManager.checkTilesToDetermineRequiredRequests(
      false,
      this.windowDisplay.numberPartitionTilesToDisplay,
      this.windowDisplay.numberRowTilesToDisplay);
    this.setScrollBarPositions();

    if ((!this.reportDataManager.hasFacts) && (!this.reportDataManager.allowNoFacts) ) {
      this.snackBar.openErrorSnackBar(this.NO_FACTS_MESSAGE);
    }
    this.showSpinner$.next(false);
  }

  private handleNoDataReturned(returnedRequest: RequestObjContainer): void {
    this.initialReportResponseReturned.emit(returnedRequest);
    this.showSpinner$.next(false);
  }

  private buildngForArrays(): void {
    // These are for the ngfor, so we can repeat over data.
    let facts = this.reportDataManager.factColSpan > 0 ? this.reportDataManager.factColSpan : 1;

    if (Math.ceil((this.windowHeightInRows * 2) / this.reportDataManager.rowsInTile) >= 2) {
      this.windowDisplay.numberRowTilesToDisplay = Math.ceil((this.windowHeightInRows * 2) / this.reportDataManager.rowsInTile);
    } else {
      this.windowDisplay.numberRowTilesToDisplay = 2;
    }

    if (Math.ceil((this.windowWidthInColumns * 2) / (this.reportDataManager.partitionsInTile * facts)) >= 2) {
      this.windowDisplay.numberPartitionTilesToDisplay = Math.ceil((this.windowWidthInColumns * 2) / (this.reportDataManager.partitionsInTile * facts));
    } else {
      this.windowDisplay.numberPartitionTilesToDisplay = 2;
    }

    this.windowDisplay.totalRowsForNgFor = Array(this.reportDataManager.rowsInTile * this.windowDisplay.numberRowTilesToDisplay).fill(0);
    this.windowDisplay.totalPartitionsForNgFor = Array(this.reportDataManager.partitionsInTile * this.windowDisplay.numberPartitionTilesToDisplay).fill(0);
    this.reportDataManager.totalHeaderLookupsForNgFor
      = Array(this.reportDataManager.lookUpTableData[this.reportDataManager.partitionKeyList[0]].headerLookups.length).fill(0);
  }

  /*
    In order to synchronize scroll position across multiple instances we have to manually modify the scroll position
    of the scroll bar elements.
   */
  private setScrollBarPositions(): void {
    // Unfortunately, the vertical scroll element is not rendered yet when this is called. Therefore, we have to add a timeout.
    let verticalScrollBarElement = this.verticalScrollElement ? this.verticalScrollElement.nativeElement : null;
    let horizontalScrollBarElement = this.factHorizontalScrollElement ? this.factHorizontalScrollElement.nativeElement : null;
    if (verticalScrollBarElement || horizontalScrollBarElement) {
      if (verticalScrollBarElement && verticalScrollBarElement.scrollTop !== this.reportDataManager.verticalScrollTop) {
        verticalScrollBarElement.scrollTop = this.reportDataManager.verticalScrollTop;
      }
      if (horizontalScrollBarElement && horizontalScrollBarElement.scrollLeft !== this.reportDataManager.factHorizontalScrollLeft) {
        horizontalScrollBarElement.scrollLeft = this.reportDataManager.factHorizontalScrollLeft;
      }
    } else {
      setTimeout(() => {
        verticalScrollBarElement = this.verticalScrollElement ? this.verticalScrollElement.nativeElement : null;
        horizontalScrollBarElement = this.factHorizontalScrollElement ? this.factHorizontalScrollElement.nativeElement : null;
        if (verticalScrollBarElement && verticalScrollBarElement.scrollTop !== this.reportDataManager.verticalScrollTop) {
          verticalScrollBarElement.scrollTop = this.reportDataManager.verticalScrollTop;
        }
        if (horizontalScrollBarElement && horizontalScrollBarElement.scrollLeft !== this.reportDataManager.factHorizontalScrollLeft) {
          horizontalScrollBarElement.scrollLeft = this.reportDataManager.factHorizontalScrollLeft;
        }
      }, 100);
    }
  }

  private handleSort() {
    this.initialReportResponseReturned.emit(this.reportDataManager.getInitialRequestObject());
    this.showSpinner$.next(true);
    this.reportDataManager.checkTilesToDetermineRequiredRequests(
      true, this.windowDisplay.numberPartitionTilesToDisplay, this.windowDisplay.numberRowTilesToDisplay);
  }

  onExpandIconClick() {
    this.matDialog.open(DataTableDisplayWrapperEdw3Component, {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      panelClass: 'full-screen-modal-overlay',
      data: { reportDataManager: this.reportDataManager }
    });
  }
}
