<div class="header-content">
  <div>
    <button mat-icon-button
            (click)="dialog.close()" matTooltip="Minimize">
      <mat-icon class="material-icons">close</mat-icon>
    </button>
  </div>
</div>
<app-data-table-display-edw3 [reportDataManager]="passedReportDataManager"
                    [componentName]="'dialog'"
                    [sharesReportDataManager]="true"
                    [showReportHeaderInfo]="true"
                    (initialReportResponseReturned)="setReportViewFromTableLibrary()">
</app-data-table-display-edw3>
