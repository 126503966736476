
export interface IKeyedCollection<T> {
  /* eslint-disable @typescript-eslint/naming-convention */
  Add(key: string, value: T);
  ContainsKey(key: string): boolean;
  Count(): number;
  Value(key: string): T;
  Keys(): string[];
  Remove(key: string): T;
  Clear(): void;
  Values(): T[];
  ToArray(): { key: string; value: T }[];
  /* eslint-enable */
}

export class Dictionary<T> implements IKeyedCollection<T> {
  /* eslint-disable @typescript-eslint/naming-convention */
  private items: { [index: string]: T } = {};
  private count = 0;

  public ContainsKey(key: string): boolean {
    return this.items.hasOwnProperty(key);
  }

  public Count(): number {
    return this.count;
  }

  public Add(key: string, value: T) {
    this.items[key] = value;
    this.count++;
  }

  public Remove(key: string): T {
    let val = this.items[key];
    delete this.items[key];
    this.count--;
    return val;
  }

  public Value(key: string): T {
    return this.items[key];
  }

  public Keys(): string[] {
    let keySet: string[] = [];

    for (let prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        keySet.push(prop);
      }
    }

    return keySet;
  }

  public Clear() {
    this.items = {};
    this.count = 0;
  }

  public Values(): T[] {
    let values: T[] = [];

    for (let prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        values.push(this.items[prop]);
      }
    }

    return values;
  }

  public ToArray() {
    let array: { key: string; value: T }[] = [];

    for (let prop in this.items) {
      if (this.items.hasOwnProperty(prop)) {
        array.push({ key: prop, value: this.items[prop] });
      }
    }

    return array;
  }
  /* eslint-enable */
}
