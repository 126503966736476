import { Injectable } from '@angular/core';
import { DashboardHttpService } from '../../../core-services/dashboard-http-service/dashboard-http.service';
import { environment } from '../../../../environments/environment';
import { LoginAccounts, MerchantGroupInfo, UpdateMerchantGroupRequest } from '../../dtos/api';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MerchantGroupApiService {
  private path = '/merchantGroups';

  constructor(private http: DashboardHttpService) { }

  public getAccountBalance(merchantGroupId: string) {
    return this.http.get<{outstanding_balance: number}>(environment.apiUrl + this.path + '/' + merchantGroupId + '/account/balance');
  }

  public getMerchantGroupUsers(merchantGroupId: string) {
    let requestUrl = `${environment.apiUrl}${this.path}/${merchantGroupId}/account/users`;
    return this.http.get<LoginAccounts[]>(requestUrl, false);
  }

  public getMerchantGroupInfo(merchantGroupEntityIds: string[]) {
    if (merchantGroupEntityIds.length === 0) {
      return of<MerchantGroupInfo[]>([]);
    }

    let commaDelimitedIds = merchantGroupEntityIds.join(',');
    return this.http.get<MerchantGroupInfo[]>(environment.apiUrl + '/merchantGroups/' + commaDelimitedIds);
  }

  updateMerchantGroup(merchantGroupId, updateMerchantGroupRequest: UpdateMerchantGroupRequest) {
    return this.http.put<UpdateMerchantGroupRequest, MerchantGroupInfo>(`${environment.apiUrl}/merchantGroups/${merchantGroupId}`, updateMerchantGroupRequest);
  }
}
