import { CreateNotificationEntity } from './dtos/api';

export type SelectedEntity = CreateNotificationEntity & { description: string; tooltip?: string};
export type FixedEntity = Omit<SelectedEntity, 'include_entity'>;

export const uuidZero = '00000000-0000-4000-a000-000000000000';
// the order of the array also represents the display order
export const availableFixedEntities: ReadonlyArray<FixedEntity> = [
  {entity_group_name: 'merchants', entity_id: uuidZero, relationship_type: 'users', entity_network: 'US', description: 'US Merchants'},
  {entity_group_name: 'affiliates', entity_id: uuidZero, relationship_type: 'users', entity_network: 'US', description: 'US Affiliates'},
  {entity_group_name: 'merchants', entity_id: uuidZero, relationship_type: 'users', entity_network: 'CA', description: 'CA Merchants'},
  {entity_group_name: 'affiliates', entity_id: uuidZero, relationship_type: 'users', entity_network: 'CA', description: 'CA Affiliates'},
  {entity_group_name: 'merchants', entity_id: uuidZero, relationship_type: 'users', entity_network: 'AU', description: 'AU Merchants'},
  {entity_group_name: 'affiliates', entity_id: uuidZero, relationship_type: 'users', entity_network: 'AU', description: 'AU Affiliates'},
  {entity_group_name: 'users', entity_id: uuidZero, relationship_type: 'users', entity_network: null, description: 'All Users'},
  {entity_group_name: 'users', entity_id: uuidZero, relationship_type: 'root_users', entity_network: null, description: 'Root Users'},
];

export const notificationTypeTags = Object.freeze({
  Announcement: '724591ce-a883-11e7-9830-12007a804e92',
  Terms: '44c5b155-4f3b-41e0-a83c-f4677f343315'
});
