import { NgModule } from '@angular/core';

import { IsExtraWidthRequired } from './is-extra-width-required/is-extra-width-required.pipe';
import { ArePartitionOffsetsTheSame } from './are-partition-offsets-the-same/are-partition-offsets-the-same.pipe';
import { IsHeaderNameSame } from './is-header-name-same/is-header-name-same.pipe';

@NgModule({
  exports: [
    IsExtraWidthRequired,
    ArePartitionOffsetsTheSame,
    IsHeaderNameSame
  ],
  declarations: [
    IsExtraWidthRequired,
    ArePartitionOffsetsTheSame,
    IsHeaderNameSame
  ]
})
export class DataTableDisplayPipeModule { }
export { IsExtraWidthRequired } from './is-extra-width-required/is-extra-width-required.pipe';
export { ArePartitionOffsetsTheSame } from './are-partition-offsets-the-same/are-partition-offsets-the-same.pipe';
export { IsHeaderNameSame } from './is-header-name-same/is-header-name-same.pipe';
