import { Injectable } from '@angular/core';

@Injectable()
export class FileDownloadService {

  public downloadFileFromUrl(url: string, name: string): boolean {
    let isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    let isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
    if (isChrome || isSafari) {
      let link = this.createDownloadLinkFromUrl(url, name);
      let e = this.createClickEvent();
      link.dispatchEvent(e);
      return true;
    }
    window.open(url);
    return undefined;
  }

  public createDownloadLinkFromUrl(url: string, fileName?: string): HTMLAnchorElement {
    let link = document.createElement('a');
    link.href = url;
    if (fileName) {
      link.download = fileName;
    }
    return link;
  }

  public createClickEvent(): Event {
    if (document.createEvent) {
      let e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      return e;
    }
    return undefined;
  }
}
