import { Injectable } from '@angular/core';
import { HeaderStateService } from '../header-state-service/header.state.service';
import { DashboardStateService } from '../../dashboard/dashboard.module';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '../../core-services/snack-bar/snack-bar.service';
import { DashboardApiService } from '../../core/services/api/dashboard-api.service';
import {Network} from '../../core/dtos/api';

@Injectable()
export class HeaderService {
  constructor(
    private headerStateService: HeaderStateService,
    private dashboardStateService: DashboardStateService,
    private dashboardApi: DashboardApiService,
    private snackBar: SnackBarService,
  ) { }

  public populateAccountSwitcherRootData() {
    this.populateAccountSwitcherRootNetworkAccounts();
    this.populateAccountSwitcherRootActiveAccount();
  }

  public populateAccountSwitcherNonRootAccounts() {
      this.headerStateService.nonRootAvailableAccounts = [];
      this.dashboardApi.availableAccounts$
        .subscribe(availableAccountResponse => {
          for (let availableAccountResponseItem of availableAccountResponse) {
            this.headerStateService.nonRootAvailableAccounts.push(
              {
                classicId: availableAccountResponseItem.classic_entity_id,
                entityName: availableAccountResponseItem.entity_name,
                entityNetwork: availableAccountResponseItem.entity_network,
                entityGroupName: availableAccountResponseItem.entity_group_name,
                entityId: availableAccountResponseItem.entity_id
              });
          }

          this.headerStateService.nonRootAvailableAccounts.sort(
            (a, b) => (
                a.entityName > b.entityName) ? 1 :
                ((b.entityName > a.entityName) ? -1 : 0)
          );
        },
      (errorResponse: HttpErrorResponse) => {
        this.snackBar.openErrorSnackBar(errorResponse.error.error);
      });
  }

  private populateAccountSwitcherRootNetworkAccounts() {
    let networksAvailable: Network[] = ['US', 'CA', 'AU'];
    this.headerStateService.rootNetworkAccounts = [];
    networksAvailable.forEach(network => {
      let labelSelected = false;
      let labelSelectable = true;
      if (this.dashboardStateService.entityId === this.dashboardStateService.rootPermissions &&
        network === this.dashboardStateService.entityNetwork) {
        labelSelected = !labelSelected;
        labelSelectable = !labelSelectable;
      }
      this.headerStateService.rootNetworkAccounts.push({
          entityName: `${this.dashboardStateService.userInfo.firstName} ${this.dashboardStateService.userInfo.lastName}`,
          entityNetwork: network,
          entityGroupName: 'root',
          entityId: this.dashboardStateService.rootPermissions,
          isTileSelected: labelSelected,
          isTileSelectable: labelSelectable
        });
    });
  }

  private populateAccountSwitcherRootActiveAccount() {
    if (this.dashboardStateService.entityId !== this.dashboardStateService.rootPermissions) {
      this.headerStateService.rootActiveAccount = {
        classicId: this.dashboardStateService.classicId,
        entityName: this.dashboardStateService.entityName,
        entityNetwork: this.dashboardStateService.entityNetwork,
        entityGroupName: this.dashboardStateService.entityGroupName,
        entityId: this.dashboardStateService.entityId,
        isTileSelected: true,
        isTileSelectable: false,
      };
    }
  }
}
