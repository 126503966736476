<div class="datatable-wrapper" #tableWrapper>
    <mat-spinner *ngIf="showSpinner$ | async" [diameter]="100" [color]="'accent'"></mat-spinner>
    <div *ngIf="reportDataManager && reportDataManager.lookUpTableData !== null && (showSpinner$ | async) === false">
      <div *ngIf="environment.featuresFlags.showEdw3Reports
      && (reportDataManager.hasFacts || windowDisplay.dimensionColSpan > 0)
      && (showReportHeaderInfo || showExpandButton)" class="datatable-card-header">
        <div *ngIf="showReportHeaderInfo" class="report-info-container">
          <div class="report-info">
            <span>{{reportDataManager.totalRowsInReport}} Record(s)</span><br><span>{{reportDataManager.getReportCurrency ? reportDataManager.getReportCurrency() : ''}}</span>
          </div>
        </div>
        <div *ngIf="showExpandButton" class="expand-button">
          <button mat-icon-button (click)="onExpandIconClick()" matTooltip="Fullscreen">
            <mat-icon class="material-icons">zoom_out_map</mat-icon>
          </button>
        </div>
      </div>
        <ng-container *ngIf="!reportDataManager.reportHasData; else reportDisplay">
            <div class="empty-data-set">No records found.</div>
        </ng-container>
        <ng-template #reportDisplay>
            <div class="datatable-with-data" (mousemove)="onMouseMoveEvent$.next($event)"
                 (touchmove)="onMouseMoveEvent$.next($event)"
                 (mouseleave)="onMouseLeave($event)"
                 (mouseup)="onMouseUp($event)"
                 (touchend)="onMouseUp($event)"
                 (wheel)="onWheelEvent($event)"
                 (click)="removeSelection($event)">
                <div class="table-wrapper">
                    <div *ngIf="reportDataManager.hasFacts || windowDisplay.dimensionColSpan > 0" class="content-wrapper"
                         [ngStyle]="{'height.px': windowDisplay.bodyHeightExcludingFooter}"
                         (mousedown)="onMouseDown($event)"
                         (touchstart)="onMouseDown($event)">
                        <div class="header-table" [ngStyle]="{'left.px': windowDisplay.currentDisplayPositionLeft}">
                            <table>
                                <thead>
                                <ng-template ngFor [ngForOf]="reportDataManager.totalHeaderLookupsForNgFor"
                                             let-lookupIndex="index">
                                    <tr *ngIf="(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]].headerLookups.length > 0
                                    && reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]].headerLookups[0].name !== 'master'
                                    && reportDataManager.partitionTitles.length > 0)" [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                      <th *ngIf="reportDataManager.posPartitionCalculated === 0" class="partition-header" [attr.colspan]="windowDisplay.dimensionColSpan">{{reportDataManager.partitionTitles[lookupIndex]}}</th>
                                        <ng-template ngFor [ngForOf]="windowDisplay.totalPartitionsForNgFor"
                                                     let-partitionIndex="index" let-isFirst="first">
                                          <ng-container
                                                  *ngIf="!windowDisplay.isDummyKey(reportDataManager.partitionKeyList[partitionIndex + reportDataManager.posPartitionCalculated]); else isDummyPartition">
                                              <ng-container *ngIf="reportDataManager.partitionKeyList[partitionIndex + reportDataManager.posPartitionCalculated]
                                          && !(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + reportDataManager.posPartitionCalculated]].headerLookups[lookupIndex].offset
                                          | arePartitionOffsetsTheSame: (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated) - 1]] ?
                                          reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated) - 1]].headerLookups[lookupIndex].offset : 0): isFirst)">
                                                <ng-container
                                                          *ngIf="reportDataManager.colspanWidths.length > 0 && isFirst; else useDefaultColSpans">
                                                      <th class="partition-header"
                                                          [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}"
                                                          [attr.colspan]="reportDataManager.colspanWidths[lookupIndex] | getMinimumNumber: (reportDataManager.partitionsInTile * reportDataManager.factColSpan * windowDisplay.numberPartitionTilesToDisplay)">
                                                          {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex
                                                          + (reportDataManager.posPartitionCalculated)]].headerLookups[lookupIndex].name}}
                                                      </th>
                                                  </ng-container>
                                                  <ng-template #useDefaultColSpans>
                                                      <th class="partition-header"
                                                          [attr.colspan]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]].headerLookups[lookupIndex].colspan
                                                  | getMinimumNumber: (reportDataManager.partitionsInTile * reportDataManager.factColSpan * windowDisplay.numberPartitionTilesToDisplay)"
                                                          [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                                          {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex
                                                          + reportDataManager.posPartitionCalculated]].headerLookups[lookupIndex].name}}
                                                      </th>
                                                  </ng-template>
                                              </ng-container>
                                          </ng-container>
                                            <ng-template #isDummyPartition>
                                                <th [attr.colspan]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]].headerLookups[lookupIndex].colspan
                                            | getMinimumNumber: (reportDataManager.partitionsInTile * reportDataManager.factColSpan * windowDisplay.numberPartitionTilesToDisplay)"
                                                    class="partition-header no-data"
                                                    [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                                    &nbsp;
                                                </th>
                                            </ng-template>
                                        </ng-template>
                                        <ng-container *ngIf="windowDisplay.extraWidthForShortReports > 0">
                                            <th class="extra-space-col" [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                                &nbsp;
                                            </th>
                                        </ng-container>
                                    </tr>
                                </ng-template>

                                <!--If there is only one header row we do not want to run extra logic, which can hurt performance.-->
                                <ng-container
                                        *ngIf="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[0].length === 1; else factHeadersHasSubHeader">
                                    <tr class="header-row">
                                      <ng-template *ngIf="reportDataManager.posPartitionCalculated === 0"
                                                    ngFor let-dim [ngForOf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].dims">
                                        <th class="column-headers"
                                            (click)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest], dim.index, true)"
                                            (touchend)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest], dim.index, true)"
                                            [ngClass]="{'selected-sort': ((reportDataManager.sortedHeader && reportDataManager.sortedHeader[dim.index])
                                                    && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]])),
                                                    'non-clickable': (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    && !(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[dim.index].isSortable))}"
                                            [ngStyle]="{'width.px': reportDataManager.dimColWidths[dim.index],
                                                    'min-width.px': reportDataManager.dimColWidths[dim.index],
                                                    'max-width.px': reportDataManager.dimColWidths[dim.index],
                                                    'height.px': reportDataManager.dataTableRowHeight}">
                                          {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[dim.index][0].name}}
                                          <ng-container *ngIf="(!reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[dim.index].isSortable))">
                                            <ng-container
                                              *ngIf="((reportDataManager.sortedHeader && reportDataManager.sortedHeader[dim.index])
                                                            && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]])); else elseDimIcon">
                                                        <span><mat-icon
                                                          svgIcon="{{reportDataManager.sortIcon}}"></mat-icon></span>
                                            </ng-container>
                                            <ng-template #elseDimIcon>
                                              <span><mat-icon svgIcon="sort-icon"></mat-icon></span>
                                            </ng-template>
                                          </ng-container>
                                        </th>
                                      </ng-template>
                                        <ng-template ngFor let-partitionKey
                                                     [ngForOf]="windowDisplay.totalPartitionsForNgFor"
                                                     let-partitionIndex="index">
                                            <ng-template
                                                    [ngIf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]]">
                                                <ng-container
                                                        *ngFor="let factKey of reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts; last as isLast; index as factKeyIndex">
                                                    <ng-container
                                                            *ngIf="(((partitionIndex + (reportDataManager.posPartitionCalculated))
                                                    | isExtraWidthRequired: isLast: (reportDataManager.totalPartitionsInReport - 1): !!windowDisplay.horizontalScrollBarHeight: !!windowDisplay.verticalFactScrollBarWidth) === false);
                                                    else extraWidthToAccountForScrollBarForHeaders">
                                                        <!--For dynamic report data all headers are sortable, otherwise we check to see if they are sortable.-->
                                                        <th [ngClass]="{'selected-sort': (reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition
                                                && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]]),
                                                'non-clickable': (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    && !(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable))}"
                                                            class="column-headers"
                                                            (click)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)], factKey.index, false)"
                                                            (touchend)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)], factKey.index, false)"
                                                            [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                        'height.px': reportDataManager.dataTableRowHeight}"
                                                            [attr.colspan]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][0].colspan">
                                                            {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][0].name}}
                                                            <ng-container *ngIf="(!reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable))">
                                                                <ng-container
                                                                        *ngIf="((reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]])); else elseFactIcon">
                                                                    <span><mat-icon
                                                                            svgIcon="{{reportDataManager.sortIcon}}"></mat-icon></span>
                                                                </ng-container>
                                                                <ng-template #elseFactIcon>
                                                                    <span><mat-icon
                                                                            svgIcon="sort-icon"></mat-icon></span>
                                                                </ng-template>
                                                            </ng-container>
                                                        </th>
                                                    </ng-container>
                                                    <ng-template #extraWidthToAccountForScrollBarForHeaders>
                                                        <!--For dynamic report data all headers are sortable, otherwise we check to see if they are sortable.-->
                                                       <th *ngIf="!(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers | isHeaderNameSame
                                                   :factKeyIndex :reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts :0)"
                                                           [ngClass]="{'selected-sort': (reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]]),
                                                       'non-clickable': (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                   && !(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable))}"
                                                           class="column-headers"
                                                           (click)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionTile * reportDataManager.partitionsInTile)], factKey.index, false)"
                                                           (touchend)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionTile * reportDataManager.partitionsInTile)], factKey.index, false)"
                                                           [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'min-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'max-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth,
                                                       'height.px': reportDataManager.dataTableRowHeight}"
                                                           [attr.colspan]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][0].colspan">
                                                           {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][0].name}}
                                                           <ng-container *ngIf="(!reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                   || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable))">
                                                               <ng-container
                                                                       *ngIf="((reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]])); else elseFactIconWithExtraWidth">
                                                               <span><mat-icon
                                                                       svgIcon="{{reportDataManager.sortIcon}}"></mat-icon></span>
                                                               </ng-container>
                                                               <ng-template #elseFactIconWithExtraWidth>
                                                                   <span><mat-icon
                                                                           svgIcon="sort-icon"></mat-icon></span>
                                                               </ng-template>
                                                           </ng-container>
                                                       </th>
                                                   </ng-template>
                                               </ng-container>
                                           </ng-template>
                                       </ng-template>
                                       <ng-container *ngIf="windowDisplay.extraWidthForShortReports > 0">
                                           <th class="extra-space-col" [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                               &nbsp;
                                           </th>
                                       </ng-container>
                                   </tr>
                               </ng-container>
                               <!--We need to loop through all headers and sub-headers.-->
                                <ng-template #factHeadersHasSubHeader>
                                    <ng-container *ngFor="let headerArray of reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[0];
                                    index as headerArrayIndex; last as isLastHeader">
                                        <tr class="header-row">
                                          <ng-container
                                            *ngFor="let dimKey of reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].dims; first as isFirstDimHeader; index as dimKeyIndex">
                                            <!--We do not display headers where the previous header is the same. We also only allow sorting for the bottom non-component headers-->
                                            <!--The logic to determine whether a header is sortable (non-clickable) will need to be changed when we start allowing for dynamicReports to have components.-->
                                            <th *ngIf="!(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers | isHeaderNameSame
                                                :dimKeyIndex :reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].dims :headerArrayIndex)"
                                                class="column-headers"
                                                (click)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest], dimKey.index, true)"
                                                (touchend)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest], dimKey.index, true)"
                                                [ngClass]="{'selected-sort': ((reportDataManager.sortedHeader && isLastHeader && reportDataManager.sortedHeader[dimKey.index])
                                                    && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]])),
                                                    'non-clickable': (!isLastHeader || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    && !(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[dimKey.index].isSortable)))}"
                                                [ngStyle]="{'width.px': reportDataManager.dimColWidths[dimKey.index], 'min-width.px': reportDataManager.dimColWidths[dimKey.index], 'max-width.px': reportDataManager.dimColWidths[dimKey.index],
                                                    'height.px': reportDataManager.dataTableRowHeight}"
                                                [attr.colspan]="this.reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[dimKey.index][headerArrayIndex].colspan">
                                              {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[dimKey.index][headerArrayIndex].name}}
                                              <ng-container *ngIf="(isLastHeader && (!reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[dimKey.index].isSortable)))">
                                                <ng-container
                                                  *ngIf="((reportDataManager.sortedHeader && reportDataManager.sortedHeader[dimKey.index])
                                                            && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]])); else elseDimIconSubHeader">
                                                        <span><mat-icon
                                                          svgIcon="{{reportDataManager.sortIcon}}"></mat-icon></span>
                                                </ng-container>
                                                <ng-template #elseDimIconSubHeader>
                                                  <span><mat-icon svgIcon="sort-icon"></mat-icon></span>
                                                </ng-template>
                                              </ng-container>
                                            </th>
                                          </ng-container>
                                            <ng-template ngFor let-partitionKey
                                                         [ngForOf]="windowDisplay.totalPartitionsForNgFor"
                                                         let-partitionIndex="index">
                                                <ng-template
                                                        [ngIf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]]">
                                                    <ng-container
                                                            *ngFor="let factKey of reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts; last as isLast; index as factKeyIndex">
                                                        <ng-container
                                                                *ngIf="(((partitionIndex + (reportDataManager.posPartitionCalculated))
                                                    | isExtraWidthRequired: isLast: (reportDataManager.totalPartitionsInReport - 1): !!windowDisplay.horizontalScrollBarHeight: !!windowDisplay.verticalFactScrollBarWidth) === false);
                                                    else extraWidthToAccountForScrollBarForHeaders">
                                                            <!--For dynamic report data all headers are sortable, otherwise we check to see if they are sortable.-->
                                                            <th *ngIf="!(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers | isHeaderNameSame
                                                    :factKeyIndex :reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts :headerArrayIndex)"
                                                                [ngClass]="{'selected-sort': (isLastHeader && (reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition
                                                && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]])),
                                                'non-clickable': (!isLastHeader || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    && !(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable)))}"
                                                                class="column-headers"
                                                                (click)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)], factKey.index, false)"
                                                                (touchend)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)], factKey.index, false)"
                                                                [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                        'height.px': reportDataManager.dataTableRowHeight}"
                                                                [attr.colspan]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][headerArrayIndex].colspan">
                                                                {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][headerArrayIndex].name}}
                                                                <ng-container
                                                                        *ngIf="(isLastHeader && (!reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                    || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable)))">
                                                                    <ng-container
                                                                            *ngIf="((reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]])); else elseFactIcon">
                                                                    <span><mat-icon
                                                                            svgIcon="{{reportDataManager.sortIcon}}"></mat-icon></span>
                                                                    </ng-container>
                                                                    <ng-template #elseFactIcon>
                                                                    <span><mat-icon
                                                                            svgIcon="sort-icon"></mat-icon></span>
                                                                    </ng-template>
                                                                </ng-container>
                                                            </th>
                                                        </ng-container>
                                                        <ng-template #extraWidthToAccountForScrollBarForHeaders>
                                                            <!--For dynamic report data all headers are sortable, otherwise we check to see if they are sortable.-->
                                                           <th *ngIf="!(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers | isHeaderNameSame
                                                   :factKeyIndex :reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts :headerArrayIndex)"
                                                               [ngClass]="{'selected-sort': (isLastHeader && (reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]])),
                                                       'non-clickable': (!isLastHeader || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                   && !(reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable)))}"
                                                               class="column-headers"
                                                               (click)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionTile * reportDataManager.partitionsInTile)], factKey.index, false)"
                                                               (touchend)="reportDataManager.triggerSort(reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionTile * reportDataManager.partitionsInTile)], factKey.index, false)"
                                                               [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'min-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'max-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth,
                                                       'height.px': reportDataManager.dataTableRowHeight}"
                                                               [attr.colspan]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][headerArrayIndex].colspan">
                                                               {{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].headers[factKey.index][headerArrayIndex].name}}
                                                               <ng-container
                                                                       *ngIf="(isLastHeader && (!reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo
                                                   || (reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].sortInfo[factKey.index].isSortable)))">
                                                                   <ng-container
                                                                           *ngIf="((reportDataManager.sortedHeader && reportDataManager.sortedHeader[factKey.index]) && (reportDataManager.sortedPartition && reportDataManager.sortedPartition[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]])); else elseFactIconWithExtraWidth">
                                                               <span><mat-icon
                                                                       svgIcon="{{reportDataManager.sortIcon}}"></mat-icon></span>
                                                                   </ng-container>
                                                                   <ng-template #elseFactIconWithExtraWidth>
                                                                   <span><mat-icon
                                                                           svgIcon="sort-icon"></mat-icon></span>
                                                                   </ng-template>
                                                               </ng-container>
                                                           </th>
                                                       </ng-template>
                                                   </ng-container>
                                               </ng-template>
                                           </ng-template>
                                           <ng-container *ngIf="windowDisplay.extraWidthForShortReports > 0">
                                               <th class="extra-space-col" [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                                   &nbsp;
                                               </th>
                                           </ng-container>
                                       </tr>
                                   </ng-container>
                               </ng-template>
                               </thead>
                           </table>
                       </div>
                           <div class="data-and-vertical-scroll"
                                [ngStyle]="{'height.px': windowDisplay.verticalScrollBarHeight}">
                               <!--We don't render <app-data-table-cell> components when we don't have custom components.-->
                                <ng-container *ngIf="!reportDataManager.hasCustomComponents; else hasCustomComponents">
                                        <div class="data-container" [ngStyle]="{'top.px': reportDataManager.currentDisplayPositionTop, 'left.px': windowDisplay.currentDisplayPositionLeft}">
                                            <table>
                                                <tbody>
                                                <ng-template ngFor let-rowNumber [ngForOf]="windowDisplay.totalRowsForNgFor"
                                                             let-rowIndex="index">
                                                    <tr>
                                                      <ng-template *ngIf="reportDataManager.posPartitionCalculated === 0" ngFor let-dimKey
                                                                   [ngForOf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].dims">
                                                        <ng-template
                                                          [ngIf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]].data[rowIndex + reportDataManager.posRowCalculated]"
                                                          [ngIfElse]="noDimData">
                                                          <td #dimData class="dim-data" [ngStyle]="{'width.px': reportDataManager.dimColWidths[dimKey.index], 'min-width.px': reportDataManager.dimColWidths[dimKey.index], 'max-width.px': reportDataManager.dimColWidths[dimKey.index],
                                                    'height.px': reportDataManager.dataTableRowHeight, 'text-align': dimKey.textAlign}"
                                                              [ngClass]="{'allow-select': windowDisplay.selectStyleKey[rowIndex + '--' + reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated] + '--' + dimKey.index]
                                                        || windowDisplay.selectStyleKey[rowIndex + '--' + reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]], 'height.px': reportDataManager.dataTableRowHeight}"
                                                              (dblclick)="onCellDoubleClick($event, reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated], dimKey.index, rowIndex)"
                                                              (click)="onRowClick($event, reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated], dimKey.index, 'dim', rowIndex)">{{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]].data[rowIndex
                                                          + reportDataManager.posRowCalculated][dimKey.index]}}</td>
                                                        </ng-template>
                                                        <ng-template #noDimData>
                                                          <td class="no-data"
                                                              [ngStyle]="{'width.px': reportDataManager.dimColWidths[dimKey.index], 'min-width.px': reportDataManager.dimColWidths[dimKey.index], 'max-width.px': reportDataManager.dimColWidths[dimKey.index],
                                                        'height.px': reportDataManager.dataTableRowHeight}">
                                                            &nbsp;
                                                          </td>
                                                        </ng-template>
                                                      </ng-template>
                                                        <ng-template ngFor let-partitionKey
                                                                     [ngForOf]="windowDisplay.totalPartitionsForNgFor"
                                                                     let-partitionIndex="index">
                                                            <ng-template
                                                                    [ngIf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]]">
                                                                <ng-template ngFor let-factKey
                                                                             [ngForOf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts">
                                                                    <ng-container *ngIf="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]].data[rowIndex + (reportDataManager.posRowCalculated)];
                                                            else noFactData">
                                                                        <td [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                                        'height.px': reportDataManager.dataTableRowHeight, 'text-align': factKey.textAlign}"
                                                                            [ngClass]="{'allow-select': windowDisplay.selectStyleKey[rowIndex + '--' + (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]) + '--' + factKey.index]
                                                                            || windowDisplay.selectStyleKey[rowIndex + '--' + (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated])]}"
                                                                            (dblclick)="onCellDoubleClick($event, (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]), factKey.index, rowIndex)"
                                                                            (click)="onRowClick($event, (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]), factKey.index, 'fact', rowIndex)">{{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex
                                                                                + (reportDataManager.posPartitionCalculated)]].data[rowIndex
                                                                                + (reportDataManager.posRowCalculated)][factKey.index]}}</td>
                                                                    </ng-container>
                                                                    <ng-template #noFactData>
                                                                        <td class="no-data"
                                                                            [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                                            'height.px': reportDataManager.dataTableRowHeight}">
                                                                            &nbsp;
                                                                        </td>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </ng-template>
                                                        </ng-template>
                                                        <ng-container *ngIf="windowDisplay.extraWidthForShortReports > 0">
                                                            <td class="extra-space-col" [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                                                &nbsp;
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </ng-template>
                                                </tbody>
                                            </table>
                                        </div>
                                </ng-container>
                                <ng-template #hasCustomComponents>
                                        <div class="data-container" [ngStyle]="{'top.px': reportDataManager.currentDisplayPositionTop, 'left.px': windowDisplay.currentDisplayPositionLeft}">
                                            <table>
                                                <tbody>
                                                <ng-template ngFor let-rowNumber [ngForOf]="windowDisplay.totalRowsForNgFor"
                                                             let-rowIndex="index">
                                                    <tr>
                                                      <ng-template ngFor let-dimKey
                                                                   [ngForOf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].dims">
                                                        <ng-template
                                                          [ngIf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]].data[rowIndex + reportDataManager.posRowCalculated]"
                                                          [ngIfElse]="noDimData">
                                                          <td #dimData class="dim-data" [ngStyle]="{'width.px': reportDataManager.dimColWidths[dimKey.index], 'min-width.px': reportDataManager.dimColWidths[dimKey.index], 'max-width.px': reportDataManager.dimColWidths[dimKey.index],
                                                    'height.px': reportDataManager.dataTableRowHeight, 'text-align': dimKey.textAlign}"
                                                              [ngClass]="{'allow-select': windowDisplay.selectStyleKey[rowIndex + '--' + reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated] + '--' + dimKey.index]
                                                        || windowDisplay.selectStyleKey[rowIndex + '--' + reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]]}"

                                                              (dblclick)="onCellDoubleClick($event, reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated], dimKey.index, rowIndex)"
                                                              (click)="onRowClick($event, reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated], dimKey.index, 'dim', rowIndex)">
                                                            <app-data-table-cell [isComponent]="dimKey.isComponent"
                                                                                 [associatedData]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]].data[rowIndex +
                                                        reportDataManager.posRowCalculated][dimKey.index]"
                                                                                 [component]="dimKey.component"
                                                                                 (dynamicComponentEvent)="onDynamicComponentEvent($event)">
                                                            </app-data-table-cell>
                                                          </td>
                                                        </ng-template>
                                                        <ng-template #noDimData>
                                                          <td class="no-data"
                                                              [ngStyle]="{'width.px': reportDataManager.dimColWidths[dimKey.index], 'min-width.px': reportDataManager.dimColWidths[dimKey.index], 'max-width.px': reportDataManager.dimColWidths[dimKey.index],
                                                        'height.px': reportDataManager.dataTableRowHeight}">
                                                            <app-data-table-cell [isComponent]="dimKey.isComponent"
                                                                                 [associatedData]="&nbsp;"
                                                                                 [component]="dimKey.component"
                                                                                 (dynamicComponentEvent)="onDynamicComponentEvent($event)">
                                                            </app-data-table-cell>
                                                          </td>
                                                        </ng-template>
                                                      </ng-template>
                                                        <ng-template ngFor let-partitionKey
                                                                     [ngForOf]="windowDisplay.totalPartitionsForNgFor"
                                                                     let-partitionIndex="index">
                                                            <ng-template
                                                                    [ngIf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]]">
                                                                <ng-template ngFor let-factKey
                                                                             [ngForOf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts">
                                                                    <ng-container *ngIf="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]].data[rowIndex + (reportDataManager.posRowCalculated)];
                                                            else noFactData">
                                                                        <td [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                                        'height.px': reportDataManager.dataTableRowHeight, 'text-align': factKey.textAlign}"
                                                                            [ngClass]="{'allow-select': windowDisplay.selectStyleKey[rowIndex + '--' + (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]) + '--' + factKey.index]
                                                                            || windowDisplay.selectStyleKey[rowIndex + '--' + (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated])]}"
                                                                            (dblclick)="onCellDoubleClick($event, (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]), factKey.index, rowIndex)"
                                                                            (click)="onRowClick($event, (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]), factKey.index, 'fact', rowIndex)">
                                                                            <app-data-table-cell [isComponent]="factKey.isComponent"
                                                                                             [associatedData]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex
                                                                                + (reportDataManager.posPartitionCalculated)]].data[rowIndex
                                                                                + (reportDataManager.posRowCalculated)][factKey.index]"
                                                                                             [component]="factKey.component"
                                                                                             (dynamicComponentEvent)="onDynamicComponentEvent($event)">
                                                                            </app-data-table-cell>
                                                                        </td>
                                                                    </ng-container>
                                                                    <ng-template #noFactData>
                                                                        <td class="no-data"
                                                                            [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                                            'height.px': reportDataManager.dataTableRowHeight}">
                                                                            <app-data-table-cell [isComponent]="factKey.isComponent"
                                                                                             [associatedData]="&nbsp;"
                                                                                             [component]="factKey.component"
                                                                                             (dynamicComponentEvent)="onDynamicComponentEvent($event)"></app-data-table-cell>
                                                                        </td>
                                                                    </ng-template>
                                                                </ng-template>
                                                            </ng-template>
                                                        </ng-template>
                                                        <ng-container *ngIf="windowDisplay.extraWidthForShortReports > 0">
                                                            <td class="extra-space-col" [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                                                &nbsp;
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                </ng-template>
                                                </tbody>
                                            </table>
                                        </div>
                                </ng-template>
                                <div class="vertical-scrolling" #verticalScroll
                                     [ngStyle]="{'height.px': windowDisplay.verticalScrollBarHeight, 'width.px': windowDisplay.verticalFactScrollBarWidth}"
                                     (scroll)="onScrollForVerticalScrollBar($event.target)"
                                     *ngIf="windowDisplay.verticalFactScrollBarWidth > 0">
                                    <div [ngStyle]="{'height.px': windowDisplay.allDataHeight, 'width.px': windowDisplay.verticalFactScrollBarWidth}"></div>
                                </div>
                            </div>
                    </div>
                </div>
                <div class="footer-wrapper"
                     [ngStyle]="{'height.px': windowDisplay.footerHeight}"
                     *ngIf="windowDisplay.footerHeight > 0 && reportDataManager.hasFacts">
                    <!-- If there are no horizontal scroll bar then we will place the border-radius around the bottom of the fact footer -->
                    <div class="footer-content"
                         [ngClass]="{'bottom-radius': !windowDisplay.horizontalScrollBarHeight}"
                         (mousedown)="onMouseDown($event)"
                         (touchstart)="onMouseDown($event)">
                        <div class="footer-data"
                             *ngIf="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].totals"
                             [ngStyle]="{'left.px': windowDisplay.currentDisplayPositionLeft}">
                            <table>
                                <tfoot>
                                <tr>
                                  <ng-container *ngIf="windowDisplay.totalDimsWidth > 0 && reportDataManager.posPartitionCalculated === 0">
                                    <td class="totals-title"
                                        [ngStyle]="{'width.px': windowDisplay.totalDimsWidth - totalDimWidthPadding, 'min-width.px': windowDisplay.totalDimsWidth - totalDimWidthPadding, 'max-width.px': windowDisplay.totalDimsWidth - totalDimWidthPadding, 'height.px': reportDataManager.dataTableRowHeight}">
                                      {{TOTAL_LABEL}}
                                    </td>
                                  </ng-container>
                                    <ng-template ngFor let-partitionKey
                                                 [ngForOf]="windowDisplay.totalPartitionsForNgFor"
                                                 let-partitionIndex="index">
                                        <ng-template
                                                [ngIf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]]">
                                            <ng-template ngFor let-factKey
                                                         [ngForOf]="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[reportDataManager.partitionOffsetOfInitialRequest]].facts"
                                                         let-isLast="last">
                                                <ng-container
                                                        *ngIf="(((partitionIndex + (reportDataManager.posPartitionCalculated))
                                                    | isExtraWidthRequired: isLast: (reportDataManager.totalPartitionsInReport - 1): !!windowDisplay.horizontalScrollBarHeight: !!windowDisplay.verticalFactScrollBarWidth) === false);
                                                    else extraWidthToAccountForScrollBarForTotals">
                                                    <ng-container
                                                            *ngIf="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]].totals; else noFooterWithoutExtraWidth">
                                                        <td [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                        'height.px': reportDataManager.dataTableRowHeight}"
                                                            [ngClass]="{'allow-select': windowDisplay.selectStyleKey[(reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]) + '--' + factKey.index]
                                                                    || windowDisplay.selectStyleKey[(reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated])]}"
                                                            (dblclick)="onCellDoubleClick($event, (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]), factKey.index)"
                                                            (click)="onRowClick($event, (reportDataManager.partitionKeyList[reportDataManager.posPartitionCalculated]), factKey.index, 'fact')">{{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex
                                                            + (reportDataManager.posPartitionCalculated)]].totals[factKey.index]}}</td>
                                                    </ng-container>
                                                    <ng-template #noFooterWithoutExtraWidth>
                                                        <td class="no-data"
                                                            [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index], 'min-width.px': reportDataManager.factColWidths[factKey.index], 'max-width.px': reportDataManager.factColWidths[factKey.index],
                                                            'height.px': reportDataManager.dataTableRowHeight}">
                                                            &nbsp;
                                                        </td>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-template #extraWidthToAccountForScrollBarForTotals>
                                                    <ng-container
                                                            *ngIf="reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex + (reportDataManager.posPartitionCalculated)]].totals; else noFooterWithExtraWidth">
                                                        <td [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'min-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'max-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth,
                                                        'height.px': reportDataManager.dataTableRowHeight}">{{reportDataManager.lookUpTableData[reportDataManager.partitionKeyList[partitionIndex
                                                            + (reportDataManager.posPartitionCalculated)]].totals[factKey.index]}}</td>
                                                    </ng-container>
                                                    <ng-template #noFooterWithExtraWidth>
                                                        <td class="no-data"
                                                            [ngStyle]="{'width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'min-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth, 'max-width.px': reportDataManager.factColWidths[factKey.index] + windowDisplay.verticalFactScrollBarWidth,
                                                        'height.px': reportDataManager.dataTableRowHeight}">
                                                            &nbsp;
                                                        </td>
                                                    </ng-template>
                                                </ng-template>
                                            </ng-template>
                                        </ng-template>
                                    </ng-template>
                                    <ng-container *ngIf="windowDisplay.extraWidthForShortReports > 0">
                                        <td class="extra-space-col" [ngStyle]="{'height.px': reportDataManager.dataTableRowHeight}">
                                            &nbsp;
                                        </td>
                                    </ng-container>
                                </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
                <!--We have to check horizontalScrollReady as it takes some time for angular to render table, so we do not want-->
                <!--the horizontal scroll bars to display until after the table.-->
                <div class="horizontal-scroll-container" *ngIf="windowDisplay.horizontalScrollReady">
                    <div class="horizontal-scrolling" #horizontalScroll
                         [ngStyle]="{'height.px': windowDisplay.horizontalScrollBarHeight}"
                         (scroll)="onScrollForHorizontalScrollBar($event.target)"
                         *ngIf="windowDisplay.horizontalScrollBarHeight > 0">
                        <div class="horizontal-scrolling-content"
                             [ngStyle]="{'width.px': windowDisplay.allDataWidth}"></div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
</div>
