<div class="form-container">
  <form class="setup-phone-form" (ngSubmit)="setupPhone()" #setupPhoneForm="ngForm" novalidate>
    <app-logo-link></app-logo-link>
    <p class="message">
      Add a phone for two-factor authentication
    </p>
    <p>
      We'll use this phone to verify logins from new devices and browsers.
    </p>
    <div class="container">
      <div matPrefix class="flex-row">
      <mat-form-field class="country-code-input">
        <mat-select name="countryCode" [(ngModel)]="loginStateService.countryCode" [panelClass]="'dashboard-select-panel'" [panelWidth]=null>
          <mat-select-trigger>
            {{ loginStateService.countryCode.selected_label }}
          </mat-select-trigger>
          <mat-option *ngFor="let option of countryCodeService.countryCodes" [value]="option">
            {{ option.option_label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="phone-number-input">
        <input matInput name="phoneNumber" type="text" required placeholder="Phone Number" [(ngModel)]="loginStateService.phoneNumber" appValidatePhoneNumber>
      </mat-form-field>
      </div>
      <div>
        <mat-radio-group name="sendMethod" [(ngModel)]="loginStateService.sendMethod">
          <mat-radio-button value="sms">
            Text Me
          </mat-radio-button>
          <mat-radio-button value="call">
            Call Me
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <button color="primary" type="submit" mat-raised-button [disabled]="!setupPhoneForm.valid || setupPhoneForm.pristine">
      Send Code
    </button>
  </form>
</div>
