import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SingleAccountTile } from '../header';

@Component({
  selector: 'app-account-tile',
  templateUrl: 'account-tile.component.html',
  styleUrls: ['account-tile.component.scss']
})
export class AccountTileComponent  {
  @Input() accountTileData: SingleAccountTile;
  @Input() isActiveAccount: boolean;
  @Output() toggleClick: EventEmitter<SingleAccountTile> = new EventEmitter<SingleAccountTile>();
  @Output() switchAccountClick: EventEmitter<SingleAccountTile> = new EventEmitter<SingleAccountTile>();

  constructor() { }

  public handleToggleClick(toggleClickEvent: SingleAccountTile): void {
    this.toggleClick.emit(toggleClickEvent);
  }

  public handleAccountSwitchClick(accountSwitchClickEvent: SingleAccountTile): void {
    this.switchAccountClick.emit(accountSwitchClickEvent);
  }
}
